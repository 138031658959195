// import _ from 'lodash';
import once from 'lodash/once';
import forOwn from 'lodash/forOwn';
import set from 'lodash/set';
import last from 'lodash/last';
import toArray from 'lodash/toArray';
import checkbox from './checkbox';
import checkboxDropdown from './checkboxDropdown';
import checkboxes from './checkboxes';
import colorCheckboxes from './colorCheckboxes';
import colorTag from './colorTag';
import crmListRadios from './crmListRadios';
import currency from './currency';
import date from './date';
import dropdown from './dropdown';
import email from './email';
import fieldGroup from './fieldGroup';
import file from './file';
import fileUpload from './fileUpload';
import filter from './filter';
import ftpMap from './ftpMap';
import hidden from './hidden';
import inputList from './inputList';
import longText from './longText';
import markup from './markup';
import number from './number';
import password from './password';
import phone from './phone';
import plaintext from './plaintext';
import radios from './radios';
import tagList from './tagList';
import text from './text';

const allModules = {
  checkbox,
  checkboxDropdown,
  checkboxes,
  colorCheckboxes,
  colorTag,
  crmListRadios,
  currency,
  date,
  dropdown,
  email,
  fieldGroup,
  file,
  fileUpload,
  filter,
  ftpMap,
  hidden,
  inputList,
  longText,
  markup,
  number,
  password,
  phone,
  plaintext,
  radios,
  tagList,
  text
};

function completeAssign(target) {
  for (
    var _len = arguments.length, sources = Array(_len > 1 ? _len - 1 : 0), _key = 1;
    _key < _len;
    _key++
  ) {
    sources[_key - 1] = arguments[_key];
  }

  sources.forEach(function(source) {
    Object.defineProperties(
      target,
      Object.keys(source).reduce(function(descriptors, key) {
        descriptors[key] = Object.getOwnPropertyDescriptor(source, key);
        return descriptors;
      }, {})
    );
  });
  return target;
}

const FormFieldGroupConstructor = once(function(FormFieldGroupConstructor) {
  var i = {};

  forOwn(allModules, function(fn, path) {
    // The wrapper function around fn
    // is so we can have the object name look like "FormFieldGroup_{name}"
    // instead of showing "currency" or "plaintext", etc.
    //
    // First param is the first argument of the function.  So we pass in the function,
    // then call that same function in the context of the wrapper function with all of the
    // same arguments

    // fn.prototype = FormFieldGroupConstructor.prototype;

    (function(_fn) {
      set(
        i,
        last(path.split('/')),

        function() {
          var args = toArray(arguments);

          var parentProto = FormFieldGroupConstructor.prototype;
          var childProto = _fn.prototype;

          var base = Object.create(parentProto);

          _fn.prototype = completeAssign(base, childProto);
          return new (Function.prototype.bind.apply(_fn, [_fn].concat(args)))();
        }
      );
    })(fn);
  });

  return i;
});

export default FormFieldGroupConstructor;
