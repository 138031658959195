import isArray from 'lodash/isArray';
import find from 'lodash/find';
import FormField from '../FormField';

var f = function FormFieldGroup_Dropdown(data) {
  this.update(data);
};

f.prototype.update = function(data) {
  var that = this;
  this.parentUpdate(data);

  // The first entry is the empty prompt value
  if (this.options[0].value === '') {
    this.prompt = this.options.shift();
    this.prompt.group = null;
  } else {
    this.prompt = {
      value: '',
      label: '- Choose Option -'
    };
  }

  // Flatten the recursive structure
  var setOptGroup = function(options, groupName) {
    return options.reduce(function(acc, option) {
      if (groupName) option.optgroup = groupName;

      if (isArray(option.value)) {
        var options = setOptGroup(option.value, option.label);

        // Turn each sub option (that will be grouped by
        // this options's label) into a FormField
        options = options.map(function(option) {
          Object.assign(option, that.formFieldGetter());
          return new FormField(option);
        });

        Array.prototype.push.apply(acc, options);
      } else {
        acc.push(option);
      }

      return acc;
    }, []);
  };

  this.options = setOptGroup(this.options);
  if (typeof this.defaultValues[0] === 'object' && this.defaultValues[0] !== null) {
    this.selected = this.defaultValues[0].valueOf();
  } else {
    this.selected = this.defaultValues[0] || '';
  }
};

f.prototype.enableOption = function(value) {
  var option = find(this.options, { value: value });
  if (option) {
    option.disabled = false;
  }
};

f.prototype.disableOption = function(value) {
  var option = find(this.options, { value: value });
  if (option) {
    option.disabled = true;
  }
};

f.prototype.isSet = function() {
  return this.selected != null && this.selected != '';
};

f.prototype.payloadValue = function() {
  if (this.selected === 0) {
    this.selected = new Number(0);
  }
  return this.selected || '';
};

export default f;
