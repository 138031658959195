/* eslint-disable react/jsx-props-no-spreading */
import ErrorPage from '@components/errors/ErrorPage';
import GlobalWrapper from '@components/layout/GlobalWrapper';
import { AuthProps, useM360Auth } from '@madwire/m360-navigation-bar';
import { captureException } from '@sentry/nextjs';
import { CONFIG } from '@utils/environment';
import { getRedirectUrl } from '@utils/signup/resume';
import { PropsWithChildren, useEffect, useMemo, useState } from 'react';

const AUTH_CONFIG = {
  keycloak: {
    url: CONFIG.authUrl,
    realm: 'marketing360',
    clientId: 'mm360v3-front'
  },
  cookie: { domain: CONFIG.cookieDomain }
};

interface Props {
  hideLoader: () => void;
}

const AuthWrapper: React.FC<PropsWithChildren<Props>> = ({ children, hideLoader }) => {
  const { data, error, isLoading, session } = useM360Auth(CONFIG, AUTH_CONFIG);
  const [tryingSignupRedirect, setTryingSignupRedirect] = useState(false);

  const authProps = useMemo(
    () => ({
      session,
      ...data
    }),
    [session, data]
  );

  useEffect(() => {
    if (tryingSignupRedirect && session) {
      const tryRedirect = async () => {
        try {
          const token = await session.getAccessToken();
          const redirectUrl = await getRedirectUrl(token);
          if (window.top) {
            window.top.location.href = redirectUrl;
          } else {
            window.location.href = redirectUrl;
          }
        } catch (e: unknown) {
          console.error(e);
          setTryingSignupRedirect(false);
        }
      };
      tryRedirect();
    }
  }, [tryingSignupRedirect, session]);

  useEffect(() => {
    if (error) {
      // this needs to be done in a useEffect
      // because it sets state in a parent
      hideLoader();
    }
  }, [error, hideLoader]);

  if (isLoading || tryingSignupRedirect) {
    return null;
  }
  if (error && error.code === 'INDETERMINATE_PERMISSIONS' && session) {
    // This error code is thrown when the app couldn't load any account or agency permissions for the given userId:
    // that should (almost) always mean that we've caught a case where someone created a user but not a business account
    // in signup, so kick into a useEffect where we can run some async calls before redirecting them back to signup.
    setTryingSignupRedirect(true);
    captureException(error);
    return null;
  }
  if (error) {
    captureException(error);
    return <ErrorPage />;
  }
  return (
    <GlobalWrapper
      hideLoader={hideLoader}
      // safe cast because if loading is complete and we haven't returned early for an error,
      // session cannot be undefined at this point
      authProps={authProps as AuthProps}
    >
      {children}
    </GlobalWrapper>
  );
};

export default AuthWrapper;
