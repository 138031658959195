// import _ from 'lodash';
import keyBy from 'lodash/keyBy';
import reduce from 'lodash/reduce';
import isArray from 'lodash/isArray';
import toArray from 'lodash/toArray';
import isUndefined from 'lodash/isUndefined';
import FormField from './FormField';
import FormFieldGroupConstructor from './formFieldGroups/allFormFieldGroups';

function FormFieldGroup(data) {
  this.update(data);
}

const ffgProto = {};

ffgProto.formFieldGetter = function() {
  // This object should be merged into the data
  // passed into FormField.  This is the so the FormField
  // knows about it's parent FormFieldGroup, but doesn't
  // create a recursive data structure
  var that = this;
  return {
    getGroup: function() {
      return that;
    }
  };
};

ffgProto.update = function(data) {
  var that = this;
  this.rawData = Object.assign({}, data);
  Object.assign(this, data);

  if ('defaultValues' in this) {
    var defaultValues = this.defaultValues;
  } else if ('defaultValue' in this) {
    var defaultValues = this.defaultValue;
  } else {
    var defaultValues = [];
  }

  this.defaultValues = isUndefined(defaultValues) ? [] : defaultValues;

  this.options = this.options || [];

  this.options = this.options.map(function(option) {
    if (option.value === 0 && that.isDropdown()) {
      // If the value is 0, then angular will consider that
      // value falsy, and think the form element has not
      // been filled out.  To get around this, we'll cast
      // the value to a number object.
      option.value = new Number(0);
    }

    return option;
  });

  this.options = this.options.map(function(option) {
    Object.assign(option, that.formFieldGetter());
    return new FormField(option);
  });

  if (this.defaultValues[0] === 0) {
    // If the value is 0, then angular will consider that
    // value falsy, and think the form element has not
    // been filled out.  To get around this, we'll cast
    // the value to a number object.
    this.defaultValues[0] = new Number(0);
  }

  if ('csvColumn' in this && this.csvColumn != null) {
    this.csvColumn = FormFieldGroup.createNew(this.csvColumn);
  }
  if ('primary' in this && this.primary != null) {
    this.primary = FormFieldGroup.createNew(this.primary);
  }
  if ('unique' in this && this.unique != null) {
    this.unique = FormFieldGroup.createNew(this.unique);
  }
};

// If the child overrides update, we can still
// access the old function if needed.
ffgProto.parentUpdate = ffgProto.update;

ffgProto.removePrompt = function() {
  if (this.prompt) delete this.prompt;
};

ffgProto.setValue = function(value) {
  this.value = value;
};

ffgProto.getType = function() {
  return this.type;
};

ffgProto.getTemplateUrl = function() {
  return 'views/crm/formFields/' + this.getType() + '.html';
};

// The following code will add is[Type] predicate
// functions to the object, which return true or false
// depending on the type.  e.g. isRadio(), isMarkup(),
// isDropdown()... The typeCheckMap maps the server's name
// for the formFieldType to the front end's name for the
// form field type.
var typeCheckMap = {
  // isRadio
  radios: 'Radio',
  // isPlaintext
  plaintext: 'Plaintext',
  // isMarkup
  markup: 'Markup',
  // isCheckbox
  checkboxes: 'Checkbox',
  // isColorCheckbox
  colorCheckboxes: 'ColorCheckbox',
  // isDropdown
  checkboxDropdown: 'CheckboxDropdown',
  // isFieldGroup
  fieldGroup: 'FieldGroup',
  // isLongText
  longText: 'LongText',
  // isText
  text: 'Text',
  // isDropdown
  dropdown: 'Dropdown',
  // isColorTag
  colorTag: 'ColorTag',
  // isInputList
  inputList: 'InputList',
  // isDate
  date: 'Date',
  // isTagList
  tagList: 'TagList',
  // isHidden
  hidden: 'Hidden',
  // isNumber
  number: 'Number',
  // isEmail
  email: 'Email',
  // isPhone
  phone: 'Phone',
  // isFile
  file: 'File',
  // isFileUpload
  fileUpload: 'FileUpload',
  // isPassword
  password: 'Password',
  // isCurrency
  currency: 'Currency',
  // isFilter
  filter: 'Filter',
  // isListRadio
  crmListRadios: 'ListRadio',
  // isListCheckbox
  crmListCheckboxes: 'ListCheckbox',
  // isFtpMap
  ftpMap: 'FtpMap'
};

ffgProto._isType = function(serverType) {
  return this.type == serverType;
};

ffgProto.isAny = function() {
  var that = this;
  var args = toArray(arguments);
  return args.some(function(type) {
    if (type == 'checkbox') type = 'checkboxes';
    if (type == 'radio') type = 'radios';
    return that._isType(type);
  });
};

for (var type in typeCheckMap) {
  var frontEndPredicateFn = 'is' + typeCheckMap[type];
  ffgProto[frontEndPredicateFn] = (function(type) {
    // Catch the type in the closure
    return function() {
      return ffgProto._isType.call(this, type);
    };
  })(type);
}

ffgProto.log = function() {
  var copy = Object.assign({}, this);
  copy.options = copy.options.map(function(option) {
    option.group = '(Recursive Group)';
    return option;
  });
};

/**
 * Will accept an array of FormFieldGroups and assemble them into
 * a single payload object.
 *
 * @param {FormFieldGroups[]} fields
 * @return {obj}
 */
FormFieldGroup.multiPayloadStructure = function(fields) {
  var hasFile = false;

  return reduce(
    fields,
    function(acc, field) {
      if (field.isFileUpload() || field.isFile()) {
        // If this is a file field, then put it inside
        // the _files property.  The consumer of the
        // payload object must then manage how these
        // get passed to the server
        var payload = {
          _file: field.payloadValue()[0],
          _formDataName: field.name
        };

        if (!hasFile) {
          // We've found a file, change the overall
          // structure of our payload.
          hasFile = true;
          payload._payload = Object.assign({}, acc);
          payload._payload = keyBy(payload._payload, function(value, key) {
            return 'request[' + key + ']';
          });
          acc = {};
        }
      } else {
        if (hasFile) {
          var payload = {
            _payload: keyBy(field.payloadStructure(), function(value, key) {
              return 'request[' + key + ']';
            })
          };
        } else {
          var payload = field.payloadStructure();
        }
      }

      Object.assign(acc, payload);

      return acc;
    },
    {}
  );
};

ffgProto.payloadStructure = function() {
  var obj = {};

  if (this.isFieldGroup()) {
    // We don't want the fieldGroup id included with the
    // request.  It's just a grouping mechanism to
    // contain the child fields
    Object.assign(obj, this.payloadValue());
  } else if (!this.isPlaintext()) {
    // Plain fields should not be added to the payload
    obj[this.name] = this.payloadValue();
  }

  return obj;
};

// We'll use this instead of checking that
// the object is an instanceof FormFieldGroup, since we won't
// be doing deep prototypical inheritance anymore
ffgProto.isFormFieldGroup = function() {
  return true;
};

FormFieldGroup.prototype = ffgProto;

var FormFieldGroupTypes = FormFieldGroupConstructor(FormFieldGroup);

FormFieldGroup.createNew = function(data) {
  if (data.type in FormFieldGroupTypes) {
    return FormFieldGroupTypes[data.type](data);
  } else {
    function DefaultFormFieldGroup(data) {
      if (!(this instanceof DefaultFormFieldGroup)) {
        return new DefaultFormFieldGroup(data);
      }
      this.update(data);
    }
    DefaultFormFieldGroup.prototype = ffgProto;
    DefaultFormFieldGroup.payloadValue = function() {
      return this.value;
    };

    return DefaultFormFieldGroup(data);
  }
};

FormFieldGroup.prototype.createNew = FormFieldGroup.createNew;

FormFieldGroup.createTagList = function(values) {
  if (!isArray(values) && values != null) {
    throw new Error('FormFieldGroup.createTagList must accept an array of objects!');
  }

  values = values || [];

  if (
    !values.every(function(value) {
      return 'label' in value && 'value' in value;
    })
  ) {
    throw new Error(
      'FormFieldGroup.createTagList requires an array of objects with keys, "label" and "value"'
    );
  }

  return FormFieldGroup.createNew({
    autocomplete: 'contact/namesearch/search/',
    options: values,
    type: 'tagList',
    name: 'tagList'
  });
};

export default FormFieldGroup;
