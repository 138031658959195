import { apiUsers } from '@api/users';
import { GlobalDispatchContext, GlobalStateContext } from '@context/GlobalContextProvider';
import useModalManager from '@hooks/useModalManager';
import { handleErrorMessage } from '@utils/global';
import { message } from 'antd';
import moment from 'moment';
import { useTranslation } from 'next-i18next';
import { useCallback, useContext, useMemo } from 'react';

// If unverified account is older than PERIOD, a modal will block the UI
const GRACE_PERIOD_HOURS = 48;
const isGracePeriodExpired = createdDate => {
  const now = moment();
  const then = moment(createdDate);

  if (now.diff(then, 'hours') >= GRACE_PERIOD_HOURS) {
    return true;
  }
  return false;
};

// Make sure modal isn't shown if user is on verification page
const isVerifyPage = () => window.location.href.includes('settings/verify-email');

/**
 * Users must verify their emails to use the account. A 48 hour
 * grace period exists, after which a permanent modal must be shown. Before
 * then a small alert notice will be displayed on the dashboard.
 */
const useEmailVerify = () => {
  const { t } = useTranslation('common');
  const { closeModal } = useModalManager();
  const { account, user } = useContext(GlobalStateContext);
  const globalDispatch = useContext(GlobalDispatchContext);
  const userEmails = user?.emails;

  // was previously lacking the email.primary check, but as of 2023 the biz intent
  // is definitely to only care if the primary email is unverified
  const nonVerifiedEmail = useMemo(
    () => userEmails?.find(email => !email.verified && email.primary),
    [userEmails]
  );

  // Only show verification prompt if the email set as primary is not verified:
  const isVerified = useMemo(() => nonVerifiedEmail === undefined, [nonVerifiedEmail]);

  // If account was created more than 48 hours ago, lock access until email verified
  const shouldShowEmailVerifyModal = useMemo(
    () => !isVerified && !isVerifyPage() && isGracePeriodExpired(account?.createdDate),
    [account?.createdDate, isVerified]
  );

  const verifyEmail = useCallback(
    async key => {
      await apiUsers({
        url: `email/verify/${key}`,
        method: 'POST'
      });
      globalDispatch({
        type: 'UPDATE_VERIFY_EMAIL',
        nonVerifiedEmail: nonVerifiedEmail?.email
      });
      closeModal();
      message.success(t('verify-email.verified'));
    },
    [closeModal, globalDispatch, nonVerifiedEmail?.email, t]
  );

  const resendEmail = useCallback(async () => {
    if (nonVerifiedEmail && !isVerified) {
      try {
        await apiUsers({
          url: `email/verify`,
          method: `POST`,
          payload: {
            email: nonVerifiedEmail.email
          }
        });
        message.success(t('verify-email.sent'));
      } catch (e) {
        message.error(handleErrorMessage(e));
      }
    }
  }, [isVerified, nonVerifiedEmail, t]);

  return {
    nonVerifiedEmail,
    isVerified,
    shouldShowEmailVerifyModal,
    verifyEmail,
    resendEmail
  };
};

export default useEmailVerify;
