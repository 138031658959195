// eslint-disable-next-line import/no-extraneous-dependencies
import css from 'styled-jsx/css';
import theme from './theme';

export const overrides = css.global`
  /* General */
  body {
    --antd-wave-shadow-color: #bcbcbc;
    font-family: Inter;
  }

  /* Buttons */
  .ant-btn.narrow {
    padding: 0 10px;
  }

  .ant-btn-group .ant-btn-primary,
  .ant-btn.primary {
    position: relative;
    z-index: 1;
    background: ${theme.colors.primary};
    border-color: transparent;
    color: #fff;
  }

  .ant-btn-group .ant-btn-primary:focus,
  .ant-btn-group .ant-btn-primary:hover,
  .ant-btn.primary:hover,
  .ant-btn.primary:focus,
  .ant-btn.primary:active {
    background: ${theme.colors.primaryDarker};
    color: #fff;
  }

  .ant-btn.secondary {
    background-color: #fff;
    border-color: ${theme.colors.primary};
    color: ${theme.colors.primary};
  }
  .ant-btn.secondary:hover,
  .ant-btn.secondary:focus,
  .ant-btn.secondary:active {
    background-color: #e6f6ff;
    border-color: ${theme.colors.primaryDarker};
    color: ${theme.colors.primaryDarker};
  }

  .ant-btn.tertiary {
    border-color: ${theme.borderColor};
    background: #fff;
    color: ${theme.colors.text};
  }
  .ant-btn.tertiary:hover {
    background-color: #f5f5f5;
    border-color: #aaaaaa;
  }
  .ant-btn.tertiary:focus,
  .ant-btn.tertiary:active {
    background-color: #f5f5f5;
    color: ${theme.colors.text};
  }

  .ant-btn.ant-btn-danger {
    border-color: ${theme.colors.danger};
    background: #fff;
    color: ${theme.colors.danger};
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn.ant-btn-danger:hover,
  .ant-btn.ant-btn-danger:focus,
  .ant-btn.ant-btn-danger:active {
    background-color: #fff0f0;
    color: ${theme.colors.danger};
  }

  .ant-col.social-login-button button {
    align-items: center;
    justify-content: center;
    padding: 1rem 0 2rem;
    width: 100%;
    background: #fff;
    border: ${theme.border};
    border-radius: ${theme.borderRadius};
    cursor: pointer;
    transition: border-color ${theme.transitionEffect};
  }

  .ant-btn:disabled,
  .ant-btn.primary:disabled,
  .ant-btn.secondary:disabled,
  .ant-btn.tertiary:disabled {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }

  .ant-radio-button-wrapper-checked {
    color: ${theme.colors.primary};
    border-color: ${theme.colors.primary};
  }

  .ant-btn.status-btn,
  .ant-btn.status-btn:hover,
  .ant-btn.status-btn:active,
  .ant-btn.status-btn:focus {
    background-color: transparent;
    text-decoration: none;
  }
  .ant-btn.ant-btn-success {
    border-color: ${theme.colors.success};
    color: ${theme.colors.success};
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn.listing-filter-button,
  .ant-btn.listing-title,
  .ant-btn.ant-btn-success span.record-status,
  .ant-btn.ant-btn-working {
    color: ${theme.colors.text};
  }
  .ant-btn.listing-filter-button:active,
  .ant-btn.listing-title:hover > .listing-link {
    opacity: 1;
    color: ${theme.colors.secondary};
  }
  .ant-btn.listing-title .listing-link {
    opacity: 0;
    vertical-align: top;
  }
  .ant-btn.status-btn svg,
  .ant-btn.listing-title svg {
    margin-bottom: -7px;
  }
  .ant-btn.sub-menu-modal {
    color: rgba(0, 0, 0, 0.65);
    border: none;
    padding: 0;
    font-weight: 400;
    height: auto;
    width: 100%;
    text-align: left;
  }
  /* Dropdown Button Group */

  .ant-btn-group.ant-dropdown-button {
    display: flex;
  }

  .ant-btn-group.ant-dropdown-button .ant-btn-primary:first-child:not(:last-child) {
    border-right-color: ${theme.colors.primaryDarker};
  }
  .ant-btn-group.ant-dropdown-button .ant-btn-primary:last-child:not(:first-child),
  .ant-btn-group.ant-dropdown-button .ant-btn-primary + .ant-btn-primary {
    border-left-color: ${theme.colors.primaryDarker};
  }
  .ant-btn-group.ant-dropdown-button .ant-btn,
  .ant-btn-group.ant-dropdown-button .ant-btn.ant-dropdown-trigger {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* Forms */
  .ant-input-number-sm {
    padding: 0;
    line-height: 1.5;
    height: 24px;
  }

  .ant-select-dropdown {
    border-radius: ${theme.borderRadius};
    border: 1px solid ${theme.borderColor};
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    z-index: 999;
  }

  .ant-form-explain,
  .ant-form-extra {
    color: ${theme.colors.text};
  }

  .ant-select-selection--multiple > ul > li,
  .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    height: 30px;
    line-height: 28px;
  }

  .ant-form-explain,
  .ant-form-extra {
    font-size: 12px;
    color: ${theme.colors.muted};
  }

  .ant-select-loading .ant-select-arrow {
    color: ${theme.colors.primary};
  }
  .ant-input-number.phone-number-input .ant-input-number-handler-wrap {
    display: none;
  }

  /* Search Input */
  .ant-input-search input.ant-input::placeholder {
    color: ${theme.colors.text};
  }

  /* Table */
  .ant-table {
    color: ${theme.colors.text};
  }

  .ant-table-thead > tr > th {
    font-size: 16px;
    font-weight: 500;
  }
  .ant-table-tbody > tr > td .u-table-row-hover-show {
    opacity: 0;
    pointer-events: none;
    transition: all ${theme.transitionEffect};
  }
  .is-mobile .ant-table-tbody > tr > td .u-table-row-hover-show {
    opacity: 1;
  }
  .ant-table-tbody > tr:hover > td .u-table-row-hover-show,
  .ant-table-tbody > tr.ant-table-row-hover > td .u-table-row-hover-show {
    opacity: 1;
    pointer-events: auto;
  }
  .ant-table-row.u-table-row-faded {
    opacity: 0.6;
  }
  .ant-table-row.u-table-row-faded:hover {
    opacity: 1;
  }

  .ant-table-thead > tr > th .ant-table-column-sorter {
    visibility: hidden;
    transition: none;
  }
  .ant-table-thead
    > tr
    > th.ant-table-column-has-actions.ant-table-column-has-sorters.ant-table-column-sort
    .ant-table-column-sorter {
    visibility: visible;
  }
  .ant-table-thead > tr > th:hover .ant-table-column-sorter {
    visibility: visible;
  }
  .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner,
  .ant-table-thead
    > tr
    > th
    .ant-table-column-sorter
    .ant-table-column-sorter-inner
    .ant-table-column-sorter-up,
  .ant-table-thead
    > tr
    > th
    .ant-table-column-sorter
    .ant-table-column-sorter-inner
    .ant-table-column-sorter-down {
    transition: none;
  }
  /* Fixes a strange SSR extra padding issue on fixed header tables */
  .ant-table-fixed-header .ant-table-scroll .ant-table-header {
    margin-bottom: -15px;
    padding-bottom: 0;
  }

  .ant-table-wrapper.bordered {
    background: #fff;
    border: ${theme.border};
    border-radius: ${theme.borderRadius};
  }

  .ant-table-wrapper.first-cell-border .ant-table-row > td:first-child {
    border-right: ${theme.border};
  }

  .ant-table-wrapper.bordered .ant-table-tbody tr:last-child > td,
  .ant-table-wrapper.borderless-bottom .ant-table-tbody tr:last-child > td {
    border-bottom: none;
  }

  .ant-table-wrapper.bg-white .ant-table-thead > tr > th {
    background: #fff;
  }

  /* Skeleton */
  .ant-skeleton-paragraph {
    padding-left: 0;
  }

  /* Modals */
  .ant-modal-header {
    text-align: center;
  }
  .ant-modal-title {
    font-size: 18px;
    font-weight: 500;
  }
  /**
   * Override for legacy V3 forms CSS. Pages that have:
   * '@ant-design/compatible/assets/index.css'
   */
  .ant-modal-mask,
  .ant-image-preview-mask {
    z-index: 1300;
  }

  /* Tabs */
  .ant-tabs-tab-active {
    color: ${theme.colors.primary};
  }

  /* Icons */
  .anticon svg[class^='MuiSvgIcon-root'] {
    font-size: inherit;
  }

  /* Dropdown Menu */
  .ant-dropdown-menu-item > a,
  .ant-dropdown-menu-submenu-title > a {
    color: ${theme.colors.text};
  }
  .ant-dropdown-menu-item > a:hover,
  .ant-dropdown-menu-submenu-title > a:hover {
    color: ${theme.colors.primary};
  }
  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover {
    background-color: rgba(${theme.colors.primaryRgb}, 0.1);
  }

  .ant-dropdown-menu-item .ant-dropdown-menu-btn {
    display: inline-block;
    width: 100%;
    padding: 5px 12px;
    margin: -5px -12px;
    background: none;
    border: none;
    cursor: pointer;
  }

  .ant-custom-menu .ant-dropdown-menu-item,
  .ant-custom-menu.ant-dropdown-menu-item {
    padding: 0;
  }
  .ant-custom-menu .ant-dropdown-menu-item button,
  .ant-custom-menu.ant-dropdown-menu-item button,
  .ant-custom-menu .ant-dropdown-menu-item a {
    display: flex;
    align-items: center;
    padding: 5px 12px;
    margin: 0;
    width: 100%;
    text-align: left;
  }

  /* Cards */
  .ant-card-bordered {
    border-radius: ${theme.borderRadius};
    border: 1px solid ${theme.borderColor};
    background: #ffffff;
  }

  .ant-card-head {
    margin-bottom: 0;
  }

  .ant-card-head-title,
  .ant-card-head-title h2,
  .ant-card-head-title h3,
  .ant-card-head-title h4 {
    margin: 0;
    color: ${theme.colors.text};
    font-size: 18px;
    font-weight: 500;
  }

  .ant-card.table-card {
    transition: none;
  }
  .ant-card.table-card .ant-card-head {
    position: relative;
  }
  .ant-card.table-card .ant-card-body {
    padding: 0;
  }
  @media (max-width: 1200px) {
    .ant-card.table-card .ant-card-head-wrapper {
      flex-wrap: wrap;
      align-items: center;
    }
    .ant-card.table-card .ant-card-head-title {
    }
    .ant-card.table-card .ant-card-extra {
    }
  }

  .ant-table-row[data-row-key='Totals'],
  .ant-table-row[data-row-key='Totals'] .ant-table-cell-fix-left {
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23e4e4e4' fill-opacity='0.61' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.1) inset;
    font-weight: bold;
  }

  /* Collapse */
  .ant-collapse-header,
  .ant-collapse-header h2,
  .ant-collapse-header h3,
  .ant-collapse-header h4 {
    margin: 0;
    color: ${theme.colors.text};
    font-size: 14px;
  }

  /* RangePicker */
  .date-range-filter .ant-calendar-picker-input {
    border-color: ${theme.borderColor};
    background: #fff;
    color: ${theme.colors.text};
  }
  .date-range-filter .ant-calendar-picker-input:hover {
    background-color: #f5f5f5;
    border-color: #aaaaaa !important;
  }
  .date-range-filter .ant-calendar-picker-input:focus,
  .date-range-filter .ant-calendar-picker-input:active {
    background-color: #f5f5f5;
    color: ${theme.colors.text};
  }

  .ant-calendar-time-picker-btn {
    text-transform: capitalize;
    color: ${theme.colors.text};
    line-height: 1.499;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid ${theme.colors.text};
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    user-select: none;
    touch-action: manipulation;
    padding: 0 5px;
    font-size: 14px;
    border-radius: 4px;
    background-color: #fff;
  }
  .ant-calendar-time-picker-btn-disabled {
    border-color: rgba(0, 0, 0, 0.25);
  }
  .ant-calendar-time-picker-btn:hover {
    color: ${theme.colors.text};
    background-color: rgba(70, 79, 85, 0.1);
  }
  .ant-calendar-time-picker-btn:focus,
  .ant-calendar-time-picker-btn:active {
    background-color: ${theme.colors.text};
    color: #fff;
  }
  .ant-calendar-ok-btn {
    text-transform: uppercase;
  }

  .ant-calendar-footer .ant-tag-blue,
  .ant-tag-blue {
    color: ${theme.colors.primary};
    background: rgba(${theme.colors.primaryRgb}, 0.1);
    border-color: rgba(${theme.colors.primaryRgb}, 0.4);
  }

  @media (max-width: 767px) {
    .ant-calendar-range {
      width: 100%;
    }
    .ant-calendar-range-left .ant-calendar-header,
    .ant-calendar-range-left .ant-calendar-body,
    .ant-calendar-range-right .ant-calendar-header,
    .ant-calendar-range-right .ant-calendar-body {
      display: none;
    }
    .ant-calendar-range-right .ant-calendar-date-input-wrap {
      margin-left: 0;
    }
    .ant-calendar-range-middle {
      padding: 0 88px 0 0;
    }
  }

  /* DateRangePicker */
  @media only screen and (max-width: 480px) {
    .crm-overview-range-picker-dropdown .ant-picker-panels {
      display: flex;
      flex-direction: column;
    }
    .crm-overview-range-picker-dropdown .ant-picker-panel-container {
      margin-right: -2rem;
    }
  }

  /* TimePicker */
  .ant-time-picker-panel-select:first-child ul {
    display: flex;
    flex-direction: column;
  }
  .ant-time-picker-panel-select:first-child ul li:first-child {
    order: 12;
  }
  .ant-time-picker-panel-select ul {
    padding-bottom: 0 !important;
  }
  .ant-time-picker-panel-select:last-child ul {
    height: 192px !important;
  }

  /* Statistic */
  .ant-statistic-content {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  }

  /* Pagination */
  .ant-table-wrapper.sticky-pagination .ant-pagination,
  .ant-list.sticky-pagination .ant-list-pagination {
    position: sticky;
    bottom: 0;
    z-index: 2;
    padding: 1rem 0;
    margin: 0;
    width: 100%;
    background: #fff;
    border-radius: 0 0 4px 4px;
    text-align: center;
  }
  .ant-pagination {
    display: flex;
    justify-content: center;
  }
`;
