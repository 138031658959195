import { isDevelopment } from '@utils/environment';

/**
 * *DEPRECATED* use phoneNumberParser
 */
export const filterPhoneNumber = number => {
  if (isDevelopment) {
    console.log('filterPhoneNumber is DEPRECATED, use parsePhoneNumber instead');
  }

  if (number && number !== '') {
    // First remove all characters except for numbers
    const cleanNumber = number.toString().replace(/[^0-9]/g, '');
    // Then piece the number back with dashes
    return `${cleanNumber.slice(0, 3)}-${cleanNumber.slice(3, 6)}-${cleanNumber.slice(6, 11)}`;
  }
  return '';
};
