import { setLocale } from 'yup';

/**
 *
 * Helper
 *
 * Load in default validation messages to Yup from our translations provider.
 */
const setYupLocale = t => {
  setLocale({
    mixed: {
      required: t('common:validation.require')
    },
    string: {
      required: t('common:validation.require'),
      email: t('common:validation.rules-messages.email'),
      max: ({ max }) => t('common:validation.limit', { limit: max }),
      min: ({ min }) => t('common:validation.minimum', { limit: min })
    },
    number: {
      required: t('common:validation.require')
    },
    boolean: {
      required: t('common:validation.require')
    },
    date: {
      required: t('common:validation.require')
    },
    object: {
      required: t('common:validation.require')
    },
    array: {
      required: t('common:validation.require')
    }
  });
};

export default setYupLocale;
