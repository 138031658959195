import numbro from 'numbro';

/**
 * Function that accepts a value and a mantissa value.
 * mantissa = 1 results in 1 decimal place, 2 results in 2 decimal places, etc.
 *
 * Example output:
 * 1250 = 1.3k
 * 65 = 65
 * 32423434 = 32.4m
 * */
export const filterNumberAverage = (value, mantissa = 1) =>
  numbro(value).format({
    average: value >= 1000,
    mantissa
  });
