import {
  NotificationsDispatchContext,
  NotificationsStateContext
} from '@context/NotificationsContextProvider';
import { acknowledgeNotification } from '@utils/notifications';
import { message } from 'antd';
import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { useContext } from 'react';
import NotificationBannerContents from './NotificationBannerContents';

export const bannerHeight = '49px';

const NotificationBanner = () => {
  const dispatch = useContext(NotificationsDispatchContext);
  const { banners } = useContext(NotificationsStateContext);
  const { t } = useTranslation('notifications');

  const banner = banners[0] || false;

  const acknowledge = async () => {
    try {
      await acknowledgeNotification(dispatch, banner);
    } catch (e) {
      // console.log(e);
      message.error(t('common:messages.error.plain'));
    }
  };

  return (
    <AnimatePresence>
      {banner && <NotificationBannerContents banner={banner} onDismiss={acknowledge} />}
    </AnimatePresence>
  );
};

export default NotificationBanner;
