import { ModalManagerContextProvider } from '@context/ModalManagerContextProvider';
import NotificationsContextProvider from '@context/NotificationsContextProvider';
import { M360UIProvider } from '@madwire/m360ui';
import productRenderActions from '@utils/defaultPage/productRenderActions';
import { setBusiness } from '@utils/global';
import { updateSetup } from '@utils/setup';
import { SWR_DEFAULT_OVERRIDES } from '@utils/swrConfig';
import Router from 'next/router';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import { SWRConfig } from 'swr';

// Create the Context
export const GlobalContext = React.createContext();
export const GlobalStateContext = React.createContext();
export const GlobalDispatchContext = React.createContext();

// const yesterday = moment().subtract(1, 'day');

const initialState = {
  env: null,
  user: {},
  account: null,
  accountSwitch: {
    inProgress: false,
    oldAccount: {},
    newAccount: {}
  },
  accounts: [],
  permissions: new Set(),
  permissionsDidLoad: false,
  selectedBusiness: null,
  businesses: [],
  firstProductRenderInitDone: false,
  products: {},
  ui: {
    mobileDrawerOpen: false,
    currentProduct: ''
  },
  stripePromises: {},
  isMobile: false,
  isEmbedded: false,
  isNagDismissed: false
};

const consolidatePermissions = (newPermissions, legacyPermissions) => {
  // Combine New V2 Permissions with Legacy Permissions. `newPermissions`
  // is a Set and `legacyPermissions` is an Array.
  if (legacyPermissions) {
    legacyPermissions.forEach(legacyPermission => {
      newPermissions.add(legacyPermission);
    });
  }
};

function reducer(state, action) {
  switch (action.type) {
    case 'INIT': {
      const {
        env,
        user,
        accounts,
        currentAccount,
        currentProduct,
        permissions: { newPermissions, legacyPermissions }
      } = action;
      consolidatePermissions(newPermissions, legacyPermissions);
      return {
        ...state,
        user: {
          ...user,
          // Spreading in state.user, upon account change, preserves any changes that
          // the user has made this session to name/email/avatar (including verifying the email, SC-17583)
          // instead of overwriting these details with authProps that came in on app init.
          // On app init, it has no effect since it's an empty object in the intial state
          ...state.user,
          fullName: `${user.firstName} ${user.lastName}`
        },
        account: currentAccount,
        accounts,
        env,
        permissions: newPermissions,
        permissionsDidLoad: true,
        ui: {
          ...state.ui,
          currentProduct
        }
      };
    }
    case 'UPDATE_USER': {
      const { user } = action;
      return {
        ...state,
        user: {
          ...user,
          fullName: `${user.firstName} ${user.lastName}`
        }
      };
    }
    case 'ADD_USER_EMAIL': {
      const newEmail = action.payload;
      const emails = state.user.emails.slice();
      emails.push(newEmail);
      return {
        ...state,
        user: {
          ...state.user,
          emails
        }
      };
    }
    case 'REMOVE_USER_EMAIL': {
      const removedEmail = action.payload;
      return {
        ...state,
        user: {
          ...state.user,
          emails: state.user.emails.reduce((acc, email) => {
            if (email.email !== removedEmail) acc.push(email);
            return acc;
          }, [])
        }
      };
    }
    case 'CHANGE_USER_PRIMARY_EMAIL': {
      const newPrimaryEmail = action.payload;
      return {
        ...state,
        user: {
          ...state.user,
          emails: state.user.emails.map(email => ({
            ...email,
            primary: email.email === newPrimaryEmail
          }))
        }
      };
    }
    case 'PRODUCTS_RELOAD_SUCCESS': {
      return {
        ...state,
        account: {
          ...state.account,
          refetch: false,
          products: action.payload.products,
          productListDirty: false
        }
      };
    }
    case 'REFETCH_ACCOUNT': {
      return {
        ...state,
        account: {
          ...state.account,
          refetch: true
        }
      };
    }
    case 'INITIAL_ACCOUNT_SWITCH': {
      const { newAccount } = action;
      const { products } = state;
      const productKeys = Object.keys(products);
      let newProducts = {};
      if (productKeys.length > 0) {
        newProducts = productKeys.reduce((acc, key) => {
          acc[key] = {
            ...products[key],
            global_render_action_complete: false
          };
          return acc;
        }, {});
      }
      return {
        ...state,
        accountSwitch: {
          inProgress: true,
          oldAccount: state.account,
          newAccount
        },
        permissionsDidLoad: false,
        firstProductRenderInitDone: false,
        products: newProducts
      };
    }
    case 'FINALIZE_ACCOUNT_SWITCH': {
      const { newPermissions, legacyPermissions } = action.payload.permissions;
      consolidatePermissions(newPermissions, legacyPermissions);
      return {
        ...state,
        account: { ...action.payload.account },
        permissions: newPermissions,
        permissionsDidLoad: true,
        accountSwitch: {
          ...state.accountSwitch,
          inProgress: false
        },
        selectedBusiness: null,
        businesses: []
      };
    }
    case 'RENAME_ACCOUNT': {
      return {
        ...state,
        account: {
          ...state.account,
          displayName: action.name
        }
      };
    }
    case 'UPDATE_ACCOUNT_TIMEZONE': {
      return {
        ...state,
        account: {
          ...state.account,
          timezone: action.payload
        }
      };
    }
    case 'UPDATE_SELECTED_BUSINESS': {
      const { businessId } = action;
      // Change the selected Business object.
      const selectedBusiness = state.businesses.filter(
        business => business.id === parseInt(businessId)
      )[0];
      if (selectedBusiness) {
        setBusiness(selectedBusiness);
        return {
          ...state,
          selectedBusiness
        };
      }
      return {
        ...state
      };
    }
    case 'SET_GLOBAL_BUSINESS': {
      // Set the array of available businesses.
      // Set the inital Business, either from local storage or pick the first from the array.
      const { businesses, savedBusiness } = action;
      return {
        ...state,
        selectedBusiness: savedBusiness || businesses[0],
        businesses
      };
    }
    case 'ADD_NEW_BUSINESS': {
      return {
        ...state,
        selectedBusiness: action.business,
        businesses: state.businesses.concat(action.business)
      };
    }
    case 'EDIT_BUSINESS': {
      return {
        ...state,
        selectedBusiness: action.business,
        businesses: state.businesses.map(business => {
          if (business.id === action.business.id) {
            return action.business;
          }
          return business;
        })
      };
    }
    case 'SET_PRODUCT_SETUP': {
      return {
        ...state,
        account: {
          ...state.account,
          products: updateSetup(state.account.products, action)
        }
      };
    }
    case 'SET_PRODUCT_VALUE': {
      const { productKey, itemKey, value } = action;
      return {
        ...state,
        products: {
          ...state.products,
          [productKey]: {
            ...state.products[productKey],
            [itemKey]: value
          }
        }
      };
    }
    case 'ADD_STRIPE_PROMISE': {
      const { newPromise, stripePromiseKey } = action;
      return {
        ...state,
        stripePromises: {
          ...state.stripePromises,
          [stripePromiseKey]: newPromise
        }
      };
    }
    case 'FIRST_RENDER_INIT_DONE': {
      return {
        ...state,
        firstProductRenderInitDone: true
      };
    }
    case 'TOGGLE_MOBILE_DRAWER': {
      return {
        ...state,
        ui: {
          ...state.ui,
          mobileDrawerOpen: action.open
        }
      };
    }
    case 'SET_CURRENT_PRODUCT': {
      return {
        ...state,
        ui: {
          ...state.ui,
          currentProduct: action.payload
        }
      };
    }
    case 'UPDATE_SINGLE_PRODUCT': {
      return {
        ...state,
        account: {
          ...state.account,
          products: state.account.products.map(product => {
            if (product.id === action.product.id) {
              return action.product;
            }
            return product;
          })
        }
      };
    }
    case 'UPDATE_VERTICAL': {
      return {
        ...state,
        account: {
          ...state.account,
          vertical: action.vertical
        }
      };
    }
    case 'UPDATE_ACCOUNT': {
      return {
        ...state,
        account: {
          ...state.account,
          ...action.payload
        }
      };
    }
    case 'UPDATE_VERIFY_EMAIL': {
      return {
        ...state,
        user: {
          ...state.user,
          emails: state.user.emails.map(email =>
            email.email === action.nonVerifiedEmail ? { ...email, verified: true } : email
          )
        }
      };
    }
    case 'SET_IS_EMBEDDED': {
      return {
        ...state,
        isEmbedded: true
      };
    }
    case 'UPDATE_IS_MOBILE': {
      return {
        ...state,
        isMobile: action.isMobile
      };
    }
    case 'DISMISS_NAG': {
      return {
        ...state,
        isNagDismissed: true
      };
    }

    default:
      throw new Error(`Error in Global Context, ${action.type} is not defined`);
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState, init => ({
    ...init
  }));

  const handleRouteChange = useCallback(
    url => {
      productRenderActions(url || Router.router.route, state, dispatch);
    },
    [state]
  );

  useEffect(() => {
    const dataReady = !!state.account;
    if (!state.firstProductRenderInitDone && !state.accountSwitch.inProgress && dataReady) {
      dispatch({ type: 'FIRST_RENDER_INIT_DONE' });
      handleRouteChange();
    }
    Router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      Router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [
    handleRouteChange,
    state.account,
    state.firstProductRenderInitDone,
    state.accountSwitch.inProgress
  ]);

  const memoizedGlobalContextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  useEffect(() => {
    const hasEmbeddedParam = new URLSearchParams(window.location.search).has('embedded');
    if (hasEmbeddedParam) {
      sessionStorage.setItem('embedded', true);
    }
    if (hasEmbeddedParam || sessionStorage.getItem('embedded') === 'true') {
      dispatch({ type: 'SET_IS_EMBEDDED' });
    }
  }, []);

  return (
    <M360UIProvider disableNormalize>
      <GlobalStateContext.Provider value={state}>
        <GlobalDispatchContext.Provider value={dispatch}>
          <GlobalContext.Provider value={memoizedGlobalContextValue}>
            <NotificationsContextProvider
              accountNumber={state.account && state.account.accountNumber}
            >
              <SWRConfig value={SWR_DEFAULT_OVERRIDES}>
                {/* Modal manager should be last so that modal contents
                 * have access to all parent context values and behave
                 * consistently across the UI. */}
                <ModalManagerContextProvider>{children}</ModalManagerContextProvider>
              </SWRConfig>
            </NotificationsContextProvider>
          </GlobalContext.Provider>
        </GlobalDispatchContext.Provider>
      </GlobalStateContext.Provider>
    </M360UIProvider>
  );
};

GlobalContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default GlobalContextProvider;
