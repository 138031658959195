import { apiAccountsNoAuth, apiSignupToken } from '@api/signup';
import { handleErrorMessage } from '@utils/global';
import { getAuthHeaders, SIGNUP_FORM_EXTENTION, STORAGE_KEY } from './constants';

// Fetch incomplete form data from API
export const getSavedFormData = async accessToken => {
  const { data, status, statusText } = await apiAccountsNoAuth({
    url: `/signup/incomplete`,
    method: 'GET',
    headers: getAuthHeaders(accessToken),
    cache: 'no-store'
  });
  if (status !== 200) {
    throw handleErrorMessage(statusText);
  }
  return data?.response?.[0];
};

export const getAccessToken = async resumeKey => {
  try {
    const { data, status, statusText } = await apiSignupToken({
      url: `/${resumeKey}`,
      method: 'GET'
    });
    if (status !== 200) {
      throw handleErrorMessage(statusText);
    }
    return data?.token;
  } catch (e) {
    console.error(e.message);
  }
};

export const getRedirectUrl = async token => {
  const resp = await apiSignupToken({ method: 'POST', payload: { token } });
  // TODO: handle failure to get token, by this point the user is created, so
  // a login and redirect to the same location will be necessary.
  if (resp.status !== 201) throw new Error('Failed to create signup Token');
  return `${SIGNUP_FORM_EXTENTION}?resumeKey=${resp.data.key}`;
};

export const storeToken = token => sessionStorage.setItem(STORAGE_KEY, token);
export const removeToken = () => sessionStorage.removeItem(STORAGE_KEY);
export const getToken = () => sessionStorage.getItem(STORAGE_KEY);
