import { getAccountNumber } from '@utils/auth';
import crmRegex from '@utils/crmRegex';
import axios from 'axios';
import FormFieldGroup from '../models/FormFieldGroup';
import { apiRequest, generateBaseUrl, generateDataOrParams, getApiEnvironment } from './apiConfig';

const env = getApiEnvironment();

export const baseURL = `https://${env}.marketing360.com/api/crm/`;
export const externalBaseURL =
  env === 'app'
    ? 'https://crm.api.marketing360.com/crm/v1.1'
    : 'https://crm.api.stage.marketing360.com/crm/v1.1';

export const apiCrm = ({ url, method, payload, cache = false, showEndpoint = false }) => {
  const baseRequest = apiRequest(baseURL);
  const dataOrParams = generateDataOrParams(method);
  const config = {
    url: `${getAccountNumber()}/${url}`,
    method,
    [dataOrParams]: payload,
    cache
  };

  if (showEndpoint) {
    return {
      endpoint: `${baseURL}/${baseRequest.getUri(config)}`,
      makeRequest: () => baseRequest(config)
    };
  }

  return baseRequest(config);
};

export const apiCrmContacts = ({ url, method, payload, cache = false, showEndpoint = false }) => {
  const baseRequest = apiRequest(baseURL);
  const dataOrParams = generateDataOrParams(method);
  const config = {
    url: `${getAccountNumber()}/${url}`,
    method,
    [dataOrParams]: payload,
    cache,
    transformResponse: axios.defaults.transformResponse.concat(data => {
      if (
        crmRegex.settingsById.test(url) ||
        crmRegex.settingsByIdAndType.test(url) ||
        crmRegex.settingsByType.test(url) ||
        crmRegex.createContactForm.test(url) ||
        crmRegex.filtersForm.test(url) ||
        crmRegex.filtersFormField.test(url) ||
        crmRegex.existingFilterForm.test(url) ||
        crmRegex.sourceFieldForm.test(url) ||
        crmRegex.sourceFieldFormByMapping.test(url) ||
        crmRegex.deleteContactField.test(url) ||
        crmRegex.ftpImportForm.test(url) ||
        crmRegex.ftpImportConfig.test(url) ||
        crmRegex.ftpImportMap.test(url) ||
        crmRegex.ftpImportSettings.test(url) ||
        crmRegex.ftpImportCancel.test(url) ||
        crmRegex.ftpImportFormSingle.test(url) ||
        crmRegex.ftpImportConfigSingle.test(url) ||
        crmRegex.assignAllContactsForm.test(url)
      ) {
        if (data.response) {
          if (Array.isArray(data.response)) {
            data.response = data.response.map(FormFieldGroup.createNew);
          } else {
            data.response = FormFieldGroup.createNew(data.response);
          }
        }
      } else if (
        crmRegex.deleteContact.test(url) ||
        crmRegex.exportContact.test(url) ||
        crmRegex.importContactForm.test(url) ||
        crmRegex.mergeFields.test(url) ||
        crmRegex.deleteMultipleContacts.test(url)
      ) {
        if (Array.isArray(data.response)) {
          data.response = data.response.map(FormFieldGroup.createNew);
        } else if (data.form) {
          if (data.form.map) {
            data.form = data.form.map(FormFieldGroup.createNew);
          } else if (data.form.useFilters) {
            data.form.useFilters = FormFieldGroup.createNew(data.form.useFilters);
          }
        }
      } else if (crmRegex.editAllContactFields.test(url)) {
        // eslint-disable-next-line guard-for-in, no-restricted-syntax
        for (const key in data.response) {
          const value = data.response[key];
          if (Array.isArray(value)) {
            data.response[key] = value.map(FormFieldGroup.createNew);
          } else {
            data.response[key] = FormFieldGroup.createNew(value);
          }
        }
      } else if (
        crmRegex.editContactField.test(url) ||
        crmRegex.editContactFieldGrid.test(url) ||
        crmRegex.filtersFormField.test(url) ||
        crmRegex.associatedContactsForm.test(url)
      ) {
        data.response = FormFieldGroup.createNew(data.response);
      }

      return data;
    })
  };

  if (showEndpoint) {
    return {
      endpoint: `${baseURL}/${baseRequest.getUri(config)}`,
      makeRequest: () => baseRequest(config)
    };
  }

  return baseRequest(config);
};

export const apiCrmFileUpload = ({ url, payload }) => {
  const baseRequest = apiRequest(baseURL);
  return baseRequest({
    url: `${getAccountNumber()}/${url}`,
    method: 'post',
    data: payload,
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  });
};

export const apiCrmForms = async ({ url, method, payload, cache = false }) => {
  const baseFormsRequest = apiRequest(generateBaseUrl({ noPrefix: true }));
  const dataOrParams = generateDataOrParams(method);
  return baseFormsRequest({
    url,
    method,
    [dataOrParams]: payload,
    cache
  });
};

export const externalApiCrm = ({ url, method, payload = {}, cache = false }) => {
  const baseRequest = apiRequest(externalBaseURL);
  const dataOrParams = generateDataOrParams(method);
  const config = {
    url: `${getAccountNumber()}/${url}`,
    method,
    [dataOrParams]: payload,
    cache
  };

  return baseRequest(config);
};
