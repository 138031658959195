// eslint-disable-next-line import/no-cycle
import {
  ModalManagerContext,
  ModalManagerContextProps
} from '@context/ModalManagerContextProvider';
import { useContext } from 'react';

/**
 * Types
 */
type UseModalManagerType = Pick<
  ModalManagerContextProps,
  'openModal' | 'closeModal' | 'showModal' | 'reloadOnClose' | 'handleDestroy'
>;

/**
 *
 * Hook
 *
 */
const useModalManager = (): UseModalManagerType => {
  const context = useContext(ModalManagerContext);

  if (!context) {
    throw new Error('useModalManager must be used within a ModalProvider');
  }

  // Destructure to only expose options allowed for normal modals
  const { openModal, closeModal, showModal, reloadOnClose, handleDestroy } = context;
  return {
    openModal,
    closeModal,
    showModal,
    reloadOnClose,
    handleDestroy
  };
};

export default useModalManager;
