export const filterUnderscoreCapitalize = value => {
  if (!value) {
    return null;
  }
  // If there's no underscore, return a Capitalized 'value'
  // filterUnderscoreCapitalize('CONVERSIONS') will filter to 'Conversions'
  if (!value.includes('_')) {
    const lowercase = value.toLowerCase();
    return lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
  }
  // Remove the underscore
  // filterUnderscoreCapitalize('LEAD_GENERATION') will filter to 'Lead Generation'
  const splitValue = value.split('_').map(s => {
    const lowercase = s.toLowerCase();
    return lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
  });
  return splitValue.join(' ');
};
