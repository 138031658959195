var f = function FormFieldGroup_Markup(data) {
  this.update(data);
};

f.prototype.update = function(data) {
  this.parentUpdate(data);
  this.value = data.defaultValues[0];
};

f.prototype.payloadValue = function() {
  return this.value;
};

export default f;
