import { GlobalDispatchContext } from '@context/GlobalContextProvider';
import { useResponsiveValue } from '@madwire/m360ui';
import { useContext, useEffect } from 'react';

const MobileDetect = () => {
  const dispatch = useContext(GlobalDispatchContext);
  // Let this component watch and dispatch the isMobile flag to GlobalContext;
  // we can't use useResponsiveValue there because that's where M360UIProvider is rendered
  const isMobile = useResponsiveValue([true, true, false]);
  useEffect(() => {
    dispatch({ type: 'UPDATE_IS_MOBILE', isMobile });
  }, [isMobile, dispatch]);
  return null;
};

export default MobileDetect;
