import { GlobalStateContext } from '@context/GlobalContextProvider';
import { Button, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

const IconButton = React.forwardRef(
  ({ icon, btnText, size, iconMode, className, onClick, tooltip, style, label }, ref) => {
    const { isMobile } = useContext(GlobalStateContext);

    let button = null;

    if (isMobile || iconMode) {
      button = (
        <button
          ref={ref}
          type="button"
          className={`u-btn-text u-btn-icon ${className}`}
          onClick={onClick}
          style={style}
          aria-label={label}
          title={label}
        >
          {icon}
        </button>
      );
    } else {
      button = (
        <Button
          ref={ref}
          size={size}
          className={`u-btn-icon ${className}`}
          onClick={onClick}
          style={style}
          title={btnText}
        >
          <span className="u-mr-1" style={{ display: 'flex', alignContent: 'center' }}>
            {icon}
          </span>
          {btnText}
        </Button>
      );
    }

    if (tooltip) {
      return (
        <Tooltip ref={ref} title={tooltip}>
          {button}
        </Tooltip>
      );
    }
    return button;
  }
);

IconButton.propTypes = {
  icon: PropTypes.element.isRequired,
  btnText: PropTypes.string,
  size: PropTypes.string,
  iconMode: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
  tooltip: PropTypes.string,
  label: PropTypes.string
};

IconButton.defaultProps = {
  className: '',
  style: {}
};

export default React.memo(IconButton);
