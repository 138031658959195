export const filterCurrency = (
  value = '0',
  hideCents = false,
  locale = 'en-US',
  currency = 'USD'
) =>
  new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: hideCents ? 0 : 2,
    maximumFractionDigits: hideCents ? 0 : 2
  }).format(value);
