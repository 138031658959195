import theme from '@assets/theme';
import Loading from '@components/Loading';
import {
  NotificationsDispatchContext,
  NotificationsStateContext
} from '@context/NotificationsContextProvider';
import NotificationImportantOutlined from '@mui/icons-material/NotificationImportantOutlined';
import NotificationsOutlined from '@mui/icons-material/NotificationsOutlined';
import { acknowledgeNotification } from '@utils/notifications';
import { message } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import ToastBody from '../ToastBody';
import DropdownContentEmpty from './DropdownContentEmpty';

const DropdownContent = ({ t }) => {
  const { loaded, connected, error, toasts } = useContext(NotificationsStateContext);
  const dispatch = useContext(NotificationsDispatchContext);

  const toastsData = toasts.slice(0).reverse();

  const acknowledge = async notification => {
    try {
      await acknowledgeNotification(dispatch, notification);
    } catch (e) {
      // console.log(e);
      message.error(t('common:messages.error.plain'));
    }
  };

  const showLoading = !error && !loaded;
  const showConnectionError = loaded && !connected;
  const showServiceError = error;
  const showNotifications = !error && loaded;

  return (
    <div className="notifications-container">
      {showLoading && (
        <div className="notifications-loader">
          <NotificationsOutlined
            className="u-mb-2"
            style={{ fontSize: 128, color: theme.borderColor }}
          />
          <Loading />
        </div>
      )}
      {showServiceError && (
        <div className="u-p-4 u-text-center" style={{ color: theme.colors.gray }}>
          <NotificationImportantOutlined style={{ fontSize: 64, color: theme.borderColor }} />
          <br />
          {t('init-error')}
        </div>
      )}
      {showNotifications && (
        <>
          <div className="notifications-bodies">
            {toastsData.length === 0 && <DropdownContentEmpty />}
            <AnimatePresence>
              {toastsData.map(notification => (
                <motion.div
                  key={`notification${notification.id}`}
                  initial={{ opacity: 1 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ ease: 'linear', duration: toastsData.length > 1 ? 0.2 : 0 }}
                >
                  <ToastBody
                    key={notification.id}
                    notification={notification}
                    acknowledge={acknowledge}
                    t={t}
                  />
                </motion.div>
              ))}
            </AnimatePresence>
          </div>
        </>
      )}
      <style jsx>{`
        .notifications-loader,
        .notifications-container {
          display: block;
          width: 100%;
        }
        .notifications-loader {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: relative;
          height: 100%;
        }
        .notifications-bodies {
          height: 100%;
          overflow-y: auto;
          border-bottom-right-radius: ${theme.borderRadius};
          border-bottom-left-radius: ${theme.borderRadius};
        }
        .notifications-title {
          border-bottom: ${theme.border};
        }
      `}</style>
    </div>
  );
};

DropdownContent.propTypes = {
  t: PropTypes.func
};

export default DropdownContent;
