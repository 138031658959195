// type FormField_Currency =
//   { "name": String
//     "label": String,
//     "currency": ISO4217CurrencyCode,
//     "locale": BCP47String,
//     "description": String,
//     "type":"currency",
//     "defaultValues": Array Float }
var f = function FormFieldGroup_Currency(data) {
  this.update(data);
  this.rawValue = this.defaultValues[0];

  if (this.rawValue) {
    this.value = formatCurrency(this.rawValue, this.locale, this.currency);
  } else {
    this.value = '';
    this.rawValue = '';
  }
};

var formatCurrency = function(value, locale, currency) {
  return (value || 0).toLocaleString(locale, {
    style: 'currency',
    currency: currency,
    useGrouping: false
  });
};

f.prototype.currencyToSymbol = function() {
  switch (this.currency) {
    case 'USD':
      return '$';
      break;
    case 'EUR':
      return '€';
      break;
    case 'JPY':
    case 'CNY':
      return '¥';
      break;
    default:
      return this.currency + '(nd)';
  }
};

f.prototype.formatCurrency = function() {
  return formatCurrency(this.value, this.locale, this.currency);
};

f.prototype.update = function(data) {
  this.parentUpdate(data);
  this.rawValue = data.defaultValues[0];

  if (this.rawValue) {
    this.value = formatCurrency(this.rawValue, this.locale, this.currency);
  } else {
    this.value = '';
    this.rawValue = '';
  }
};

f.prototype.payloadValue = function() {
  return this.value || '';
};

export default f;
