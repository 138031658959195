import theme from '@assets/theme';
import IconButton from '@components/IconButton';
import IconCdn from '@components/IconCdn';
import Close from '@mui/icons-material/Close';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useState } from 'react';

const ToastBody = ({ notification, acknowledge, t, noHover }) => {
  const [dismissLoading, setDismissLoading] = useState(false);
  const { message, createdAt, product } = notification;

  const actions = acknowledge ? (
    <IconButton
      className="hover-visible"
      icon={<Close style={{ fontSize: '16px' }} label={t('dismiss')} />}
      iconMode
      onClick={async () => {
        try {
          setDismissLoading(true);
          await acknowledge(notification);
        } catch (e) {
          setDismissLoading(false);
        }
      }}
    />
  ) : null;

  const dismissStyling = dismissLoading
    ? {
        opacity: 0.75,
        pointerEvents: 'none'
      }
    : {};

  return (
    <div
      className={`notification-body ${acknowledge ? 'u-py-3 u-pl-4 u-pr-3' : ''} u-d-flex`}
      style={dismissStyling}
    >
      <div className="notification-details u-d-flex">
        <div className="message u-font-size-normal">
          {notification.messageIsHtml ? (
            <span dangerouslySetInnerHTML={{ __html: message }} />
          ) : (
            message
          )}
        </div>
        <div className="timestamp u-font-size-sm u-d-flex u-flex-center u-mt-1">
          {product && product !== 'none' && (
            <div className="u-mr-2" style={{ height: 21 }}>
              <IconCdn iconCode={product} width={16} />
            </div>
          )}
          {createdAt && moment(createdAt).fromNow()}
        </div>
      </div>
      {actions}
      <style jsx>{`
        .notification-body {
          align-items: baseline;
          justify-content: space-between;
          border-bottom: ${acknowledge ? theme.border : 'none'};
          background-color: #fff;
          transition: background-color ${theme.transitionEffect};
        }
        .notification-details {
          flex-direction: column;
        }
        .timestamp {
          color: ${theme.colors.gray};
        }
        :global(.toast-body::after) {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 5px;
          background-image: ${`linear-gradient(90deg, ${theme.colors.primaryDarker}, ${theme.colors.primary})`};
          border-radius: ${theme.borderRadius} ${theme.borderRadius} 0 0;
        }
        :global(.ant-notification-notice-message) {
          margin-bottom: 0;
        }
        :global(.ant-notification-notice) {
          padding-top: 1.5rem;
        }
        :global(.ant-notification-notice-close) {
          display: none;
        }
        :global(.ant-notification-notice-btn) {
          float: none;
        }
        :global(.ant-notification-notice .u-btn-text) {
          color: ${theme.colors.gray};
        }
      `}</style>
      <style jsx>{`
        ${noHover
          ? ``
          : `
      .notification-body:hover {
        background: #e5f0fa;
      }
      .notification-body :global(.hover-visible) {
        opacity: 0;
        pointer-events: none;
        transition: opacity ${theme.transitionEffect};
      }
      .notification-body:hover :global(.hover-visible) {
        pointer-events: auto;
        opacity: 1;
      }
      :global(.ant-notification-notice .u-btn-text:hover) {
        color: ${theme.colors.primaryDarker};
      }
      `}
      `}</style>
    </div>
  );
};

ToastBody.propTypes = {
  notification: PropTypes.object,
  acknowledge: PropTypes.func,
  t: PropTypes.func,
  noHover: PropTypes.bool
};

export default ToastBody;
