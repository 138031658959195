import moment from 'moment';

let f = function FormFieldGroup_Date(data) {
  this.update(data);
};

f.prototype.update = function(data) {
  this.parentUpdate(data);

  if (!this.defaultValues[0] || this.defaultValues[0] == null) {
    this.date = null;
  } else {
    this.date = moment(this.defaultValues[0], 'X').toDate();
  }
};

f.prototype.payloadValue = function () {
  if (this.date) {
    return moment(this.date).format('X');
  } 
    return null;
  
};

f.prototype.setValue = function(value) {
  if (!(value instanceof Date)) {
    throw new Error('date FormFieldGroup.setValue must accept Date object as argument!');
  }

  if (this.date) {
    // We're updating an existing date object
    this.date.setTime(value.getTime());
  } else {
    // This is a new date object
    this.date = value;
  }
};

f.prototype.tempSetNow = function(value) {
  this.date.setTime(Date.now());
};

export default f;
