import theme from '@assets/theme';
import { motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';

export const bannerHeight = '49px';

const NotificationBannerContents = ({ banner, onDismiss }) => {
  const { t } = useTranslation('notifications');
  return (
    <motion.div
      className="banner-container"
      key={`banner${banner.id}`}
      initial={{ opacity: 1, height: 0 }}
      animate={{ opacity: 1, height: 'auto' }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ ease: 'easeOut', duration: 0.2 }}
    >
      <div className="banner u-d-flex u-flex-center u-p-3" style={{ justifyContent: 'center' }}>
        {banner.messageIsHtml ? (
          <span dangerouslySetInnerHTML={{ __html: banner.message }} />
        ) : (
          banner.message
        )}
        <small>
          <button
            type="button"
            className="u-btn-text dismiss-link u-ml-3"
            href="#"
            onClick={onDismiss}
          >
            {t('dismiss')}
          </button>
        </small>
        <style jsx>{`
          :global(.banner-container) {
            position: sticky;
            top: 0;
            left: 0;
            z-index: 99999; /** Must be high to go over SplashScreen.js */
          }
          .banner {
            min-height: ${bannerHeight};
            background: #cce2f6;
          }
          .dismiss-link {
            text-decoration: underline;
            color: ${theme.colors.text};
          }
          .dismiss-link:hover {
            color: #000;
          }
        `}</style>
      </div>
    </motion.div>
  );
};

NotificationBannerContents.propTypes = {
  banner: PropTypes.object.isRequired,
  onDismiss: PropTypes.func.isRequired
};

export default NotificationBannerContents;
