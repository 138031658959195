import { configureScope } from '@sentry/nextjs';
import {
  Account,
  AccountAssignedEmployee,
  AccountBillingType,
  AccountCorePlan
} from '@typedefs/Account';
import { AccountStatusEnum } from '@typedefs/AccountStatus';
import { GenericObject } from '@typedefs/General';
import { Product, SetupObject } from '@typedefs/Product';
import { UserProfile } from '@typedefs/UserProfile';
import { isProduction } from './environment';
import { findEmployee } from './findEmployee';

/**
 * Global types
 */
declare global {
  interface Window {
    Appcues: {
      identify: (userId: string, properties: GenericObject) => void;
      page: () => void;
      track: (eventName: string, properties: GenericObject) => void;
      on: (eventName: string, eventHandler: () => void) => void;
      off: (eventName: string, eventHandler: () => void) => void;
    };
    // used by Google Tag Manager:
    dataLayer: GenericObject[];
    // FullStory:
    FS: {
      identify: (
        userId: string,
        options: {
          displayName: string;
          email: string;
        }
      ) => void;
      setUserVars: (opts: { accountId: string }) => void;
    };
  }
}

/**
 * Google Tag Manager
 *
 * Scripts to be included frontend pages, to enable
 * tracking on Google platforms.
 */

const gtmHeadSnippet = isProduction
  ? `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=A8IOropVUa5FrsgNhoaWhw&gtm_preview=env-2&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-NP7ZN6F');`
  : `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=AY3jzQ5dnF3XitowjtTXQw&gtm_preview=env-5&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-NP7ZN6F');`;

const gtmBodySnippet = isProduction
  ? `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NP7ZN6F&gtm_auth=A8IOropVUa5FrsgNhoaWhw&gtm_preview=env-2&gtm_cookies_win=x" height="0" width="0" style="display:none;visibility:hidden"></iframe>`
  : `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NP7ZN6F&gtm_auth=AY3jzQ5dnF3XitowjtTXQw&gtm_preview=env-5&gtm_cookies_win=x" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

export { gtmBodySnippet, gtmHeadSnippet };

export const recordPageView = (url: string): void => {
  // Push page view to google tag manager
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'Pageview',
    pagePath: url
  });

  if (window && window.Appcues) {
    window.Appcues.page();
  }
};

// Appcues helpers
/* eslint-disable no-param-reassign */
const generateSetupStatus = (statuses: Record<string, number>, current: Product) => {
  // Product setup is stored as JSON
  let { setup } = current;
  const { status } = current;
  if (typeof setup === 'string') {
    setup = JSON.parse(current.setup as string) as SetupObject;
  }

  const setupKey = `${current.name}_setup_complete`;
  if (!setup || typeof setup.currentStep !== 'number') {
    statuses[setupKey] = 1;
  } else {
    statuses[setupKey] = setup.currentStep;
  }

  // Indicate setup complete in Appcues.
  if (setup && setup.isComplete) {
    statuses[setupKey] += 1;
  }

  const statusKey = `${current.name}_active`;
  if (status && status.toLowerCase() === 'active') {
    statuses[statusKey] = 1;
  } else {
    statuses[statusKey] = 0;
  }

  return statuses;
};
/* eslint-enable no-param-reassign */

const generateSetupStatuses = (products: Product[]) => products.reduce(generateSetupStatus, {});

const isMadwireUser = (email: string): boolean =>
  email.endsWith('@madwire.com') || email.endsWith('@madwiremedia.com');

// filter to a flat array of active-only plan names
// (Appcues does not support arrays or objects as values):
const processAccountPlans = (plans: AccountCorePlan[]) =>
  plans
    .filter(plan => plan.active)
    .map(plan => plan.plan)
    .join(', ');

interface AppcuesIdentifyProps {
  accountId: string;
  accountBillingType: AccountBillingType;
  accountCreatedDate: string;
  accountPlans: AccountCorePlan[];
  accountStatusId: number;
  agencyId: number;
  agencyName: string;
  assignedEmployees: AccountAssignedEmployee[];
  externalAccountId?: string;
  migrated: number;
  products: Product[];
  profile: UserProfile;
  userId: string;
}
export const appcuesIdentify = ({
  accountId,
  accountBillingType,
  accountCreatedDate,
  accountPlans,
  accountStatusId,
  agencyId,
  agencyName,
  assignedEmployees,
  externalAccountId,
  migrated,
  products,
  profile,
  userId
}: AppcuesIdentifyProps): void => {
  if (window && window.Appcues) {
    const baseProperties = {
      accountId,
      accountBillingType,
      accountCreatedDate,
      accountPlans: processAccountPlans(accountPlans),
      accountStatus: AccountStatusEnum[accountStatusId],
      agencyId,
      agencyName,
      externalAccountId,
      email: profile?.email,
      firstName: profile?.firstName,
      has_team_member: !!findEmployee(assignedEmployees),
      is_madwire_user: isMadwireUser(profile?.email),
      migrated
    };

    window.Appcues.identify(
      userId,
      Array.isArray(products)
        ? {
            ...baseProperties,
            ...generateSetupStatuses(products)
          }
        : baseProperties
    );
  }
};

export const appcuesTrack = (eventName: string, properties: GenericObject): void => {
  if (window && window.Appcues) {
    window.Appcues.track(eventName, properties);
  }
};

export const recordUserDetails = (userId: string, profile: UserProfile, account: Account): void => {
  // Push user ID to Sentry
  configureScope(scope => {
    scope.setUser({ id: userId });
  });

  // Push user ID to google tag manager
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'Madwire',
    userId,
    isMadwireUser: isMadwireUser(profile?.email)
  });

  // Push user details to FullStory
  if (typeof window?.FS !== 'undefined') {
    window.FS.identify(userId, {
      displayName: `${profile.firstName} ${profile.lastName}`,
      email: profile.email
    });
  }
  // Push user details to Appcues
  appcuesIdentify({
    accountId: account?.accountNumber,
    accountBillingType: account?.billingType,
    accountCreatedDate: account?.createdDate,
    accountPlans: account?.plans,
    accountStatusId: account?.statusId,
    agencyId: account?.agencyId,
    agencyName: account?.agencyInfo?.displayName,
    assignedEmployees: account?.assignedEmployees,
    externalAccountId: account?.externalAccountNumber,
    migrated: account?.migrated ? parseInt(account.migrated, 10) : 0,
    products: account?.products,
    profile,
    userId
  });
};

export const recordAccount = (accountNumber: string): void => {
  // Push account ID to Sentry
  configureScope(scope => {
    scope.setTag('account_number', accountNumber);
  });

  // Push account ID to google tag manager
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'Madwire',
    accountId: accountNumber
  });

  // Push account ID to FullStory
  if (typeof window?.FS !== 'undefined') {
    window?.FS.setUserVars({
      accountId: accountNumber
    });
  }
};
