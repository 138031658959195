import axios, { AxiosInstance } from 'axios';
import { isProduction, isSecurity, isStage } from '@utils/environment';
import { getGlobalVariable } from '@utils/globalVariables';

interface ApiGlobalVariable {
  extend(baseUrl: string): AxiosInstance;
  clearCache(): void;
}

export const getApiEnvironment = (): string => {
  if (isProduction) return 'app';
  if (isStage) return 'stage';
  if (isSecurity) return 'security';
  return 'qa';
};

export const apiRequest = (baseUrl: string): AxiosInstance => {
  const api: ApiGlobalVariable = getGlobalVariable('api');
  return api.extend(baseUrl);
};

export const apiRequestNoAuth = (baseURL: string): AxiosInstance => {
  const baseReq = {
    baseURL
  };
  return axios.create(baseReq);
};

export const resetApiCache = (): void => {
  const api: ApiGlobalVariable = getGlobalVariable('api');
  api.clearCache();
};

const generateGlobalBaseUrl = (): string => {
  if (isProduction) {
    return 'https://app.marketing360.com';
  }
  if (isSecurity) {
    return 'https://security.marketing360.com';
  }
  return 'https://stage.marketing360.com';
};

export const globalBaseURL: string = generateGlobalBaseUrl();

export const generateBaseUrl = ({
  additionalPath = '',
  noPrefix
}: {
  additionalPath?: string;
  noPrefix?: boolean;
}): string => {
  if (noPrefix) {
    return `${globalBaseURL}/${additionalPath}`;
  }
  return `${globalBaseURL}/api/${additionalPath}`;
};

export const generateDataOrParams = (
  method: string | undefined,
  useParamsForDelete = true
): 'params' | 'data' => {
  const isGet = method?.toLowerCase() === 'get';
  const isDelete = method?.toLowerCase() === 'delete';
  return isGet || (isDelete && useParamsForDelete) ? 'params' : 'data';
};
