import { recordAccount } from '@utils/externalScripts';
import { getGlobalVariable, setGlobalVariable } from '../globalVariables';

export const getAccountNumber = () => {
  // This must not be a cookie and instead in global session state
  // to allow multiple tabs opened to different accounts.
  const accountNumber = getGlobalVariable('accountNumber');
  if (accountNumber) {
    return accountNumber;
  }
  return false;
};

export const setAccountNumber = accountNumber => {
  // Send account number to external scripts
  recordAccount(accountNumber);
  setGlobalVariable('accountNumber', accountNumber);
};
