export default {
  border: '1px solid #e6e6e6',
  borderColor: '#e6e6e6',
  borderRadius: '5px',
  elementHeight: '36px',
  rowHoverBackground: '#fafafa',
  transitionEffect: '0.2s ease',
  colors: {
    ads: '#2c88c8',
    content: '#006DD0',
    nla: '#006DD0',
    creative: '#f47018',
    video: '#8e44ad',
    lsa: '#8e44ad',
    other: '#5fdbbe',
    text: '#464F55',
    primary: '#006DD0',
    primaryRgb: '0,109,208',
    primaryDarker: '#07569D',
    primaryLighter: '#22A6DF',
    primaryHover: 'rgba(169,220,255,0.25)',
    danger: '#d83f3f',
    success: '#158906',
    muted: '#64798d',
    gray: '#9D9D9D',
    lightgray: '#E6E6E6',
    lightgrayLighter: '#F3F3F3',
    positive: '#478861',
    negative: '#515a5f',
    google: '#4285F4',
    facebook: '#395692',
    instagram: '#EF0873',
    linkedin: '#117BB4',
    youtube: '#FF0000',
    pinterest: '#D7143A',
    twitter: '#00AEEF',
    bing: '#008373',
    topRatedLocal: '#0C4064',
    chart: [
      '#006dd0',
      '#337ad5',
      '#4c87db',
      '#5f94e0',
      '#70a2e5',
      '#80b0eb',
      '#8fbef0',
      '#9dcdf5',
      '#abdbfa',
      '#b9eaff'
    ]
  }
};
