/**
 * Imports
 */
const { default: Environment, detectEnvironment } = require('@madwire/m360-environment');

/**
 * Constants
 */
const ENV = detectEnvironment(process.env.NODE_ENV, process.env.ENV);
const CONFIG = Environment(ENV, 'marketing360Url');

module.exports = {
  ENV,
  CONFIG,
  isProduction: ENV === 'production',
  isStage: ENV === 'stage',
  isQa: ENV === 'qa',
  isDevelopment: ENV === 'development',
  isSecurity: ENV === 'security',
  getUrl: key => CONFIG[key]
};
