import castArray from './castArray';
import compose from './compose';
import get from './get';

/**
 * Extract the response property of the axios provided madwire standard api
 * response object and cast it to an array if it isn't already.
 * @param {Any} response axios provided standard api response
 * @param {Any} [fallback] if no value exists at data.response, this is returned
 * wrapped in an array.
 * { data: { response: Any } }
 * @returns {Any[]}
 */
const getResponseDataArray = compose(castArray, (result, fallback) =>
  get(result, 'data.response', fallback)
);

export default getResponseDataArray;
