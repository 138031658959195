import theme from '@assets/theme';
import NotificationsOutlined from '@mui/icons-material/NotificationsOutlined';
import { useTranslation } from 'next-i18next';

const DropdownContentEmpty = () => {
  const { t } = useTranslation('notifications');

  return (
    <div
      className={`u-p-4 u-d-flex u-flex-center`}
      style={{ justifyContent: 'center', flexDirection: 'column' }}
    >
      <NotificationsOutlined style={{ fontSize: 64, color: theme.borderColor }} />

      <div
        style={{
          color: theme.colors.gray
        }}
      >
        {t('no-notifications')}
      </div>
    </div>
  );
};

export default DropdownContentEmpty;
