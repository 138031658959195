import _ from 'lodash';
import EntityState from './EntityState';

function FileItem(data) {
  this.update(data);
}

_.defaults(FileItem.prototype, EntityState.prototype);

FileItem.prototype.update = function(data) {
  this._originalTitle = '';
  if (data.hasOwnProperty('title')) {
    this._originalTitle = data.title;
  }
  $.extend(this, data);
};

FileItem.prototype.rowClassRules = function() {
  return _.assign(
    {
      'tr-deleted': !this.inS3
    },
    EntityState.prototype.rowClassRules.apply(this)
  );
};

FileItem.createNew = function(data) {
  return new FileItem(data);
};

FileItem.prototype.controlsVisible = function() {
  return !this.frozen && this.frozen != 1;
};

FileItem.prototype.startFrontEndDelete = function() {
  this.deleted = true;
  this.title = this._originalTitle + ' (Deleting...)';
};

FileItem.prototype.undelete = function() {
  this.deleted = false;
  this.title = this._originalTitle;
};

FileItem.prototype.frontEndDelete = function() {
  this.deleted = true;
  this.title = this._originalTitle + ' (Deleted)';
};

export default FileItem;
