/**
 *  The model EntityState to be extended by other objects
 *  @memberof m/crm
 *  @since  6/21/2016
 *  @constructor EntityState
 */
function EntityState() {}

EntityState.prototype.rowClassRules = function() {
  return {
    'tr-deleted': this.isDeleted(),
    'bgAnimated--success': this.isNewEntity() || this.isUpdatedEntity()
  };
};

EntityState.prototype.setNewEntity = function() {
  this._isNewEntity = true;
};

EntityState.prototype.setUpdatedEntity = function() {
  this._isUpdatedEntity = true;
};

EntityState.prototype.resetEntity = function() {
  this._isNewEntity = false;
};

EntityState.prototype.isNewEntity = function() {
  return this._isNewEntity;
};

EntityState.prototype.isUpdatedEntity = function() {
  return this._isUpdatedEntity;
};

EntityState.prototype.undelete = function() {
  this.deleted = false;
};

EntityState.prototype.delete = function() {
  this.deleted = true;
};

EntityState.prototype.toggleDelete = function() {
  this.deleted = !this.deleted;
};

EntityState.prototype.isDeleted = function() {
  return this.deleted;
};

export default EntityState;
