import { filterNumber } from '@utils/filters';
import reduce from 'lodash/reduce';
import moment from 'moment';
import { createElement } from 'react';
import { getAccountNumber } from './auth';
import { isProduction, isStage } from './environment';

export * from './findEmployee';
export * from './general';

export const cdnUrl = `https://d22if4xzkonim2.cloudfront.net`;
export const dateFormat = 'MM/DD/YYYY';
export const timeFormat = 'h:mm a';
export const dateAndTimeFormat = 'MM/DD/YYYY, h:mm a';
export const MARKETING_360_URL = 'https://www.marketing360.com';
export const MARKETING_360_SUPPORT_EMAIL = 'support@marketing360.com';
export const SALES_PHONE_NUMBER = '833-277-1327';
export const HOURS_PICKER_MOMENT_FMT = moment.HTML5_FMT.TIME;
export const M360_GOOGLE_STORE_URL =
  'https://play.google.com/store/apps/details?id=com.blankfactor.marketing360';
export const M360_APPLE_STORE_URL = 'https://apps.apple.com/us/app/marketing-360/id1576707541';
export const capitalize = string => string.replace(/^\w/, c => c.toUpperCase());

export const makeRandomString = (length = 5) => {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i += 1)
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  return text;
};

// Detects if the object is a Moment object, and then returns a unix timestamp
export const ifTimeGetUnix = value =>
  value instanceof Object && value._isAMomentObject ? moment(value).unix() : value;

// Takes a unix timestamp (in seconds) and returns a Moment object
export const unixToMoment = unix => moment.unix(parseInt(unix));

export const dndReorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const dndMoveMultiple = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);
  destClone.splice(droppableDestination.index, 0, removed);
  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;
  return result;
};

/**
 * Functions for Pages with Tables, Filters, and Pagination
 */

// Clears all active filters
export const makeEmptyFilters = activeFilters =>
  reduce(
    activeFilters,
    (acc, value, key) => {
      acc[key] = [];
      return acc;
    },
    {}
  );

// Returns a sort object used by Tables
export const sortTableColumns = sorter => {
  let sort = [];
  let sortOrder = 'asc'; // Workaround to set default sort order and change verbiage for CRM backend.
  if (sorter.order) {
    if (sorter.order === 'descend') {
      sortOrder = 'desc';
    }
    sort = [
      {
        by: sorter.columnKey,
        order: sortOrder
      }
    ];
  }
  return sort;
};

// Goes through the pagination object and gets the best available page endpoint based on page number
export const makePaginationEndpoint = (pagination, pageNumber) => {
  // If the first or last page is selected, return that from the pagination object.
  if (pageNumber === 1) {
    return pagination.firstpage;
  }
  if (pagination.totalpages === pageNumber) {
    return pagination.lastpage;
  }
  const prevpages = pagination.prevpages || [];
  const nextpages = pagination.nextpages || [];
  // Combine the previous and next pages into one array;
  const availablePages = [...prevpages, ...nextpages];
  // If the desired pageNumber is in the array, return the resource
  const selectedPage = availablePages
    .filter((page, index) => page.number === pageNumber || index === pageNumber - 1)
    .map(page => page.resource || page);

  // If the desired pageNumber isn't available, return the "furthest" previous or next page
  if (selectedPage.length === 0) {
    let bestPage;
    if (pageNumber < pagination.currentpage) {
      bestPage = availablePages[0];
    } else {
      bestPage = availablePages[availablePages.length - 1];
    }
    return bestPage.resource || bestPage;
  }
  return selectedPage[0];
};

// For graphs, will get a total count of records and massage data as needed
export const convertAndTotalStats = (data, countKey, setTotalCallback) => {
  let total = 0;
  const newData = data.map(item => {
    total += parseFloat(item[countKey]);
    return { ...item, [countKey]: parseFloat(item[countKey]) };
  });

  setTotalCallback(total);

  return newData;
};

export const getHighlighterText = value => {
  let returnValue = value;
  if (typeof value === 'object' && value !== null) {
    returnValue = value?.props?.textToHighlight;
  }

  if (returnValue) {
    return returnValue;
  }
  return '';
};

export const stringSorter = (a, b) => {
  const aString = getHighlighterText(a).toLowerCase();
  const bString = getHighlighterText(b).toLowerCase();
  if (aString < bString) {
    return -1;
  }
  if (aString > bString) {
    return 1;
  }
  return 0;
};

/**
 * @param {Any} error
 * @param {String} fallback
 */
export const handleErrorMessage = (error, fallback = '') => {
  console.error(error);

  // If the error has a 'response' object, then it's most likely a backend api error
  if (error?.response) {
    const { response } = error;
    if (response.data.error && typeof response.data.error === 'string') {
      const { error: respError } = response.data;
      return respError;
    }
    // API Standards. Ideally most error should fall under this
    if (response.data.error && response.data.error.message) {
      // Sometimes there are additional properties with the error
      if (response.data.error.properties) {
        const properties = Object.values(response.data.error.properties);
        // Return the first property for now. We may need to concat them together in the future.
        if (typeof properties[0] === 'string') {
          return properties[0];
        }
      }
      return response.data.error.message;
    }
    // CRM Form Field Groups
    if (response.data.errors) {
      const errorGroup = Object.values(response.data.errors);
      return errorGroup[0][0]; // Return the first error in the group
    }
    // Some CRM Errors have this structure
    if (response.data.message) {
      return response.data.message;
    }
    // Forms possible error on structure (name conflict)
    if (Array.isArray(response.data)) {
      const errors = response.data.filter(item => typeof item.message === 'string');
      if (errors.length > 0) return errors.map(item => item.message).join('\n');
    }
  }

  if (error?.message && typeof error.message === 'string') {
    return error.message;
  }

  return fallback || 'An Error has occurred';
};

export const getBusiness = (suffix = '_Business') => {
  const business = localStorage.getItem(`mm360_${getAccountNumber()}${suffix}`);
  if (!business || business === 'undefined') {
    return null;
  }
  return JSON.parse(business);
};

export const setBusiness = (business, suffix = '_Business') => {
  localStorage.setItem(`mm360_${getAccountNumber()}${suffix}`, JSON.stringify(business));
};

export const renderPaginationItem = (item, type, element) => {
  if (type === 'page') {
    return createElement('a', null, filterNumber(item));
  }
  return element;
};

// The optional getRange param can be used to adjust the range to account for static rows.
export const createPagination = ({
  current,
  pageSize,
  total,
  onChange,
  hideOnSinglePage = true,
  showTotal,
  size = 'small',
  showSizeChanger = false
}) => ({
  size,
  current,
  hideOnSinglePage,
  pageSize,
  showSizeChanger,
  total,
  showTotal:
    typeof showTotal === 'function'
      ? showTotal
      : showTotal &&
        ((_, [low, high]) =>
          `${filterNumber(low)}-${filterNumber(high)} of ${filterNumber(total)} items`),
  onChange,
  itemRender: renderPaginationItem
});

export const noop = () => {};

// Used with Antd Table Columns
// This will allow the first click on sort to be 'descend' instead of 'ascend'
export const tableSortDecendFirst = ['descend', 'ascend'];

/**
 * True if data is any of undefined, null, the empty string.
 * @param  {Any}     data
 * @return {Boolean}
 */
export const isEmpty = data =>
  data === null || data === undefined || (typeof data === 'string' && data.trim() === '');

export const NAV_OFFSET = 156;

/**
 * Account for the sticky nav when scrolling to errors. See:
 * https://ant.design/components/form/#FormInstance and
 * https://github.com/stipsan/scroll-into-view-if-needed/tree/master#function
 * This function can be provided as the behavior option to achieve the offset.
 */
export const makeScrollBehaviorNavOffset =
  (offset = NAV_OFFSET) =>
  actions =>
    actions.forEach(({ el, top, left }) => {
      el.scrollTop = top - offset;
      el.scrollLeft = left;
    });

export const isCobrandedAgency = agencyInfo =>
  !!(agencyInfo && (agencyInfo.cobrand || parseInt(agencyInfo.cobrand) > 0));

/**
 * "Given an array of objects, return a single object with the keys being the value
 * of the key property of each object in the array."
 *
 * @param arrayOfObjects - an array of objects
 * @param key - The key to use to create the object.
 */
export const toSingleObject = (arrayOfObjects, key) =>
  arrayOfObjects.reduce((accum, current) => {
    if (key in current) {
      accum[current[key]] = current;
    }
    return accum;
  }, {});

export const isProductionOrStage = isProduction || isStage;

/**
 * Returns the value of a Segment write key based on the current environment.
 *
 * @returns {string} The Segment write key for the current environment.
 */
export const getSegmentWriteKey = ENV => {
  if (ENV === 'production') {
    return process.env.SEGMENT_WRITE_KEY_PROD;
  }
  if (ENV === 'stage') {
    return process.env.SEGMENT_WRITE_KEY_STAGE;
  }
  return process.env.SEGMENT_WRITE_KEY_QA;
};

export const sleep = (ms = 0) =>
  new Promise(resolve => {
    setTimeout(resolve, ms);
  });
