import { Button, Modal } from 'antd';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';
import { useState } from 'react';

const NotificationModal = ({
  acknowledge,
  data,
  dismissButtonType = 'tertiary',
  dismissButtonLabel = null
}) => {
  const { t } = useTranslation('notifications');
  const [thisVisible, setThisVisible] = useState(true);

  const close = () => {
    setThisVisible(false);
    acknowledge(data);
  };

  return (
    <Modal
      open={thisVisible}
      footer={null}
      closable={false}
      // Ensure the content fits on the screen
      bodyStyle={{ maxHeight: '70vh', overflowY: 'auto' }}
    >
      <div
        className="u-d-flex u-flex-center u-text-center u-py-2"
        style={{ flexDirection: 'column' }}
      >
        <div className="u-pb-2 u-px-4">
          <p>
            {data.messageIsHtml ? (
              <span dangerouslySetInnerHTML={{ __html: data.message }} />
            ) : (
              data.message
            )}
            {data.link && data.linkLabel && (
              <span className="u-d-block u-pt-2">
                <a href={data.link} target="_blank" rel="noopener noreferrer">
                  {data.linkLabel}
                </a>
              </span>
            )}
          </p>
        </div>
        <Button className={dismissButtonType} onClick={close}>
          {dismissButtonLabel || t('dismiss')}
        </Button>
      </div>
    </Modal>
  );
};

NotificationModal.propTypes = {
  acknowledge: PropTypes.func,
  data: PropTypes.object,
  dismissButtonType: PropTypes.string,
  dismissButtonLabel: PropTypes.string
};

export default NotificationModal;
