import '@assets/antd-custom.less';
import { overrides } from '@assets/antd-overrides';
import { globals } from '@assets/styles';
import { utilities } from '@assets/utility-styles';
import ErrorPage from '@components/errors/ErrorPage';
import AdBlockDetect from '@components/layout/AdBlockDetect';
import AuthWrapper from '@components/layout/AuthWrapper';
import MobileDetect from '@components/layout/MobileDetect';
import SplashScreen from '@components/transitions/SplashScreen';
import GlobalContextProvider from '@context/GlobalContextProvider';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import { captureException } from '@sentry/nextjs';
import { isDevelopment } from '@utils/environment';
import { recordPageView } from '@utils/externalScripts';
import { setYupLocale } from '@utils/general';
import { getGlobalVariable } from '@utils/globalVariables';
import { appWithTranslation, withTranslation } from 'next-i18next';
import App from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import NProgress from 'nprogress';
import React from 'react';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import ReactDOM from 'react-dom';
import nextI18NextConfig from '../next-i18next.config';

/**
 * Workaround for next-css issue:
 * https://github.com/zeit/next-plugins/issues/282
 */
import '../public/empty.css';

// Accessibility Checking
if (isDevelopment && process.env.AUDIT === 'true' && typeof window !== 'undefined') {
  // we really do only want this in development, so devDependencies is fine for react-axe
  /* eslint-disable-next-line import/no-extraneous-dependencies,global-require */
  const axe = require('react-axe');
  axe(React, ReactDOM, 1000);
}

const navigationTimer = {};

// Loader
Router.events.on('routeChangeStart', url => {
  // Force a reload to the new page if client-side routing disabled.
  // Currently used for when a server-side update is available.
  if (getGlobalVariable('clientSideRoutingDisabled') === true) {
    window.location.href = url;
  }

  if (!isDevelopment) {
    // https://github.com/zeit/next-plugins/issues/282
    // When Client side loading gets stuck due to the above issue, force a load after 5 seconds.
    navigationTimer[url] = setTimeout(() => {
      window.location.href = url;
    }, 5000);
  }
  NProgress.start();
});
Router.events.on('routeChangeComplete', url => {
  if (navigationTimer[url]) {
    clearTimeout(navigationTimer[url]);
  }
  recordPageView(url);
  return NProgress.done();
});
Router.events.on('routeChangeError', (err, url) => {
  if (navigationTimer[url] && err.cancelled) {
    clearTimeout(navigationTimer[url]);
  }
  NProgress.done();
});

class MyApp extends App {
  constructor() {
    // eslint-disable-next-line prefer-rest-params
    super(...arguments);
    this.state = {
      loaderVisible: true,
      hasError: false,
      errorEventId: undefined
    };
  }

  static async getInitialProps({ Component, ctx }) {
    try {
      let pageProps = {};
      if (Component.getInitialProps) {
        pageProps = await Component.getInitialProps(ctx);
      }
      return { pageProps };
    } catch (error) {
      // Capture errors that happen during a page's getInitialProps.
      // This will work on both client and server sides.
      const errorEventId = captureException(error, ctx);
      return {
        hasError: true,
        errorEventId
      };
    }
  }

  static getDerivedStateFromProps(props, state) {
    // If there was an error generated within getInitialProps, and we haven't
    // yet seen an error, we add it to this.state here
    return {
      hasError: props.hasError || state.hasError || false,
      errorEventId: props.errorEventId || state.errorEventId || undefined
    };
  }

  static getDerivedStateFromError() {
    // React Error Boundary here allows us to set state flagging the error (and
    // later render a fallback UI).
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const errorEventId = captureException(error, { errorInfo });

    // Store the event id at this point as we don't have access to it within
    // `getDerivedStateFromError`.
    this.setState({ errorEventId });
  }

  componentDidMount() {
    // This is only called once, when the app is first loaded. We need this to
    // submit the page to GA, otherwise only subsequent navigations are recorded.
    recordPageView(new URL(window.location.href).pathname);
    // establish locale for Yup form schema validation tool (used w/ react-hook-form)
    setYupLocale(this.props.t);
  }

  hideLoader = () => {
    const { loaderVisible } = this.state;
    if (loaderVisible) {
      this.setState({ loaderVisible: false });
    }
  };

  render() {
    const { Component, pageProps, router } = this.props;
    const { loaderVisible } = this.state;

    return (
      <>
        <Head>
          {/* Per NextJS: The viewport tag should be handled by next/head in pages/_app.js */}
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
          />
        </Head>
        {this.state.hasError ? (
          <ErrorPage />
        ) : (
          <div>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <GlobalContextProvider {...pageProps}>
              <>
                {/* Special case: Signup page doesn't require auth */}
                {['/signup', '/signup/form'].includes(router.pathname) ? (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <Component pathname={router.pathname} {...pageProps} />
                ) : (
                  <>
                    <AdBlockDetect />
                    <MobileDetect />
                    <SplashScreen visible={loaderVisible} />
                    <AuthWrapper hideLoader={this.hideLoader}>
                      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                      <Component pathname={router.pathname} {...pageProps} />
                    </AuthWrapper>
                  </>
                )}
              </>
            </GlobalContextProvider>
            <style jsx global>
              {globals}
            </style>
            <style jsx global>
              {utilities}
            </style>
            <style jsx global>
              {overrides}
            </style>
          </div>
        )}
      </>
    );
  }
}

export default appWithTranslation(withTranslation()(MyApp), nextI18NextConfig);
