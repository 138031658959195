var f = function FormFieldGroup_FtpMap(data) {
  this.update(data);
};

f.prototype.update = function(data) {
  this.parentUpdate(data);
};

f.prototype.payloadValue = function() {
  if (this.primary != null) {
    var primary = this.primary.payloadValue();
  }
  var cluster = {
    csvColumn: this.csvColumn.payloadValue() || '',
    crm: this.name,
    primary: primary || null,
    unique: this.unique.payloadValue()
  };

  return cluster;
};

export default f;
