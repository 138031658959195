import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// Enforce minimum time splash screen is visible.
// Using AnimatePresence on wherever render of SplashScreen
// is used. We delay the removal of the splash screen until the
// `totalAnimateDuration` time has elapsed.
// Prevents splash screen from disappearing before logo
// animation is complete.
const MinVisibleFadeOut = ({ visible, minVisibleSeconds, style, children }) => {
  const [time, setTime] = useState(0);

  useEffect(() => {
    if (visible) {
      const interval = setInterval(() => {
        setTime(currentTime => {
          const newTime = currentTime + 100;
          if (newTime === minVisibleSeconds) {
            clearInterval(interval);
          }
          if (newTime > minVisibleSeconds) {
            return 100;
          }
          return newTime;
        });
      }, 100);
    }
  }, [minVisibleSeconds, visible]);

  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          key="min-visible-fade-out"
          animate={{ opacity: 1 }}
          transition={{
            easing: 'linear',
            duration: 0.2,
            delay: (minVisibleSeconds - time) / 1000
          }}
          exit={{ opacity: 0 }}
          style={style}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

MinVisibleFadeOut.propTypes = {
  visible: PropTypes.bool,
  minVisibleSeconds: PropTypes.number.isRequired,
  style: PropTypes.object,
  children: PropTypes.element.isRequired
};

export default React.memo(MinVisibleFadeOut);
