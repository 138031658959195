const { isDevelopment, isProduction, isStage, isQa, isSecurity } = require('./environment');

export const sentryEnvironment = () => {
  if (isProduction) return 'Production';
  if (isStage) return 'Stage';
  if (isQa) return 'QA';
  if (isSecurity) return 'Security';
  return 'Development';
};

/**
 * Regex or string matching error messages to be ignored
 * and not forwarded to Sentry.
 *
 * ResizeObserver: These are harmless errors, based on this answer:
 * - https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
 */
export const ignoreErrors = [/ResizeObserver/];

export const initOptions = {
  enabled: !isDevelopment,
  dsn: process.env.SENTRY_DSN,
  maxBreadcrumbs: 50,
  attachStacktrace: true,
  ignoreErrors,
  environment: sentryEnvironment()
};
