var f = function FormFieldGroup_Checkbox(data) {
  this.update(data);
};

f.prototype.update = function(data) {
  this.parentUpdate(data);
  if (this.defaultValues[0] == 1 || this.checked == 1) {
    this.selected = true;
  }
};

f.prototype.payloadValue = function() {
  // Dropdowns with selected value un-truthy
  if (this.selected == true) {
    this.selected = 1;
  } else {
    this.selected = 0;
  }
  return this.selected;
};

f.prototype.singleOptionChecked = function() {
  if (this.selected == true) {
    this.selected = 1;
  } else {
    this.selected = 0;
  }
  return this.selected;
};

export default f;
