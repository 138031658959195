export const SUCCESS_MANAGER = 'Success Manager';
export const MARKETING_CONSULTANT = 'Marketing Consultant';
export const PARTNERSHIPS = 'Partnerships';

/**
 * Find the first 'Marketing Consultant' from all of the assigned employees.
 * @param  {Array} assignedEmployees
 * @return {Object}
 */
export const findMarketingConsultant = assignedEmployees => {
  if (!assignedEmployees) {
    return null;
  }
  return assignedEmployees.find(employee => employee.title === MARKETING_CONSULTANT);
};

/**
 * Find the first 'Partner' or 'Success Manager' from all of the assigned employees.
 * If there is no ME, find the first 'Marketing Consultant' available.
 * @param  {Array} assignedEmployees
 * @return {Object}
 */
export const findEmployee = assignedEmployees => {
  if (!assignedEmployees) {
    return null;
  }
  const partnership = assignedEmployees.find(employee => employee.title === PARTNERSHIPS);
  if (partnership) {
    return partnership;
  }
  const successManager = assignedEmployees.find(employee => employee.title === SUCCESS_MANAGER);
  if (successManager) {
    return successManager;
  }
  return findMarketingConsultant(assignedEmployees);
};
