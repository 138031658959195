import { isProduction, isQa, isStage } from '@utils/environment';

const { generateDataOrParams, apiRequestNoAuth } = require('./apiConfig');

export const getBaseSignupTokenURL = () => {
  if (isProduction) {
    return `https://app.marketing360.com`;
  }
  if (isStage) {
    return `https://stage.marketing360.com`;
  }
  if (isQa) {
    return `https://qa.marketing360.com`;
  }
  return window.location.origin;
};

export const getBaseSignupURL = () => {
  if (isProduction) return `https://app.marketing360.com`;
  return `https://stage.marketing360.com`;
};

export const apiSignupToken = ({ url, method, payload }) => {
  const baseURL = getBaseSignupTokenURL();

  const baseRequest = apiRequestNoAuth(`${baseURL}/signupToken`);
  const dataOrParams = generateDataOrParams(method);
  return baseRequest({
    url,
    method,
    [dataOrParams]: payload
  });
};

const getBaseLoginURL = () => {
  if (isProduction) return 'https://login.marketing360.com/auth/realms/marketing360';
  return 'https://login.stage.marketing360.com/auth/realms/marketing360';
};

export const apiLogin = ({ url, method, payload, ...rest }) => {
  const baseRequest = apiRequestNoAuth(getBaseLoginURL());
  const dataOrParams = generateDataOrParams(method);
  return baseRequest({
    url,
    method,
    [dataOrParams]: payload,
    ...rest
  });
};

export const apiUsersNoAuth = ({ url, method, payload }) => {
  const baseRequest = apiRequestNoAuth(`${getBaseSignupURL()}/api/users`);
  const dataOrParams = generateDataOrParams(method);
  return baseRequest({ url, method, [dataOrParams]: payload });
};

export const apiAccountsNoAuth = ({ url, method, payload = {}, ...rest }) => {
  const baseRequest = apiRequestNoAuth(`${getBaseSignupURL()}/api/accounts`);
  const dataOrParams = generateDataOrParams(method);
  return baseRequest.request({
    url,
    method,
    [dataOrParams]: payload,
    ...rest
  });
};

export const getDashboardRedirect = async (accessToken, formData) => {
  const baseRedirectUrl = getBaseSignupTokenURL();
  // get a login cookie before redirecting
  await fetch(`${getBaseLoginURL()}/session-cookie-redirect?redirect_uri=${baseRedirectUrl}`, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    redirect: 'manual',
    referrerPolicy: 'origin-when-cross-origin',
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
  const redirectQS = `siq_email=${encodeURIComponent(formData.email)}&siq_name=${encodeURIComponent(
    `${formData.firstName} ${formData.lastName}`
  )}`;
  return `/?${redirectQS}`;
};
