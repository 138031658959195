import { getAccountNumber } from '@utils/auth';
import { apiRequest, generateDataOrParams, getApiEnvironment, globalBaseURL } from './apiConfig';

// Creating the base request
export const baseURL = `${globalBaseURL}/core/index.php`;

export const apiSettings = ({ method, payload, cache = false }) => {
  const baseRequest = apiRequest(baseURL);
  const dataOrParams = generateDataOrParams(method);
  return baseRequest({
    url: `settings/${getAccountNumber()}`,
    method,
    [dataOrParams]: payload,
    cache
  });
};

const accountUrl = `https://${getApiEnvironment()}.marketing360.com/api/accounts`;

export const apiAccounts = ({
  url = '',
  method,
  payload,
  withAccountNumber = false,
  cache = false,
  showEndpoint = false
}) => {
  const baseRequest = apiRequest(baseURL);
  const dataOrParams = generateDataOrParams(method);
  let finalUrl = `${accountUrl}/${url}`;
  if (withAccountNumber) {
    finalUrl = `${accountUrl}/${getAccountNumber()}${url}`;
  }

  const config = {
    url: finalUrl,
    method,
    [dataOrParams]: payload,
    cache
  };

  if (showEndpoint) {
    return {
      endpoint: `${baseURL}/${baseRequest.getUri(config)}`,
      makeRequest: () => baseRequest(config)
    };
  }

  return baseRequest(config);
};

const permissionsUrl = `https://${getApiEnvironment()}.marketing360.com/api/users/users`;

export const apiPermissions = ({ url, method, payload, cache = false }) => {
  const permissionsRequest = apiRequest(permissionsUrl);
  const dataOrParams = generateDataOrParams(method);
  return permissionsRequest({
    url,
    method,
    [dataOrParams]: payload,
    cache
  });
};
