import { GlobalStateContext } from '@context/GlobalContextProvider';
import throttle from 'lodash/throttle';
import PropTypes from 'prop-types';
import { CSSProperties, FC, useContext, useEffect, useState } from 'react';
import Headroom from 'react-headroom';

/**
 * Keeps navbar sticky at top of page if on desktop display.
 * If on mobile, dynamically show/hide based on scroll direction,
 * but then force it to be visible if navbar dropdown is open.
 */
const NavBarWrapper: FC = ({ children }) => {
  const [disableHiding, setDisableHiding] = useState(false);
  const { isEmbedded, account, isMobile, isNagDismissed } = useContext(GlobalStateContext);

  // when an account has certain statuses (2: past due, 8: trial, 9: expired),
  // a 60px-tall notification banner appears above the navbar,
  // so set a fixed container height, which can help for certain page layouts.
  // Banner is dismissible in the case of a trial status.
  // (only applicable on window sizes > our isMobile threshold, and if not embedded)
  const statusesWithBanners = [2, 8, 9];
  let containerHeight;
  if (isEmbedded) {
    containerHeight = '0px';
  } else if (isMobile) {
    containerHeight = 'auto';
  } else {
    containerHeight =
      !statusesWithBanners.includes(account?.statusId) || isNagDismissed ? '60px' : '120px';
  }

  // If navbar dropdown overlay is visible, disabled dynamic
  // hiding of navbar on scroll events.
  const checkOverlayVisibility = throttle(() => {
    const overlayIsVisible = document
      .getElementsByClassName('g-nav__overlay')[0]
      .classList.contains('g-nav__overlay--visible');
    if (overlayIsVisible) {
      setDisableHiding(true);
    } else {
      setDisableHiding(false);
    }
  }, 100);

  useEffect(() => {
    if (isMobile && !isEmbedded) {
      window.addEventListener('scroll', checkOverlayVisibility);
    }

    return () => {
      if (isMobile && !isEmbedded) {
        window.removeEventListener('scroll', checkOverlayVisibility);
      }
    };
  }, [checkOverlayVisibility, isMobile, isEmbedded]);

  let position: CSSProperties['position'] = 'sticky';
  if (isMobile) {
    position = disableHiding ? 'fixed' : 'relative';
  }

  return (
    <div
      key="navBarWrapper"
      style={{
        position,
        top: 0,
        zIndex: 1150,
        height: containerHeight,
        overflow: isEmbedded ? 'hidden' : undefined,
        width: '100%'
      }}
    >
      <Headroom disable={!isMobile || disableHiding}>{children}</Headroom>
    </div>
  );
};

NavBarWrapper.propTypes = {
  children: PropTypes.element
};

export default NavBarWrapper;
