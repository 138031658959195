// eslint-disable-next-line import/no-cycle
import ConfirmCloseConfirmationModal from '@components/ConfirmCloseConfirmationModal';
import { noop } from '@utils/global';
import { useRouter } from 'next/router';
import React, { FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';

/**
 * Constants & Helpers
 */
export type ModalManagerContextProps = {
  openModal: (component: JSX.Element) => void;
  closeModal: (opts?: {
    immediate?: boolean;
    promptOnClose?: boolean;
    reloadOnClose?: boolean;
    forceClose?: boolean;
  }) => void;
  showModal: boolean;
  reloadOnClose: boolean;
  handleDestroy: (immediate?: boolean, shouldDestroyConfirmModal?: boolean) => void;
  openConfirmModal: (component: JSX.Element) => void;
  closeConfirmModal: () => void;
  showConfirmModal: boolean;
};

/**
 * Context
 */
export const ModalManagerContext = React.createContext<ModalManagerContextProps>({
  openModal: noop,
  closeModal: noop,
  showModal: false,
  reloadOnClose: false,
  handleDestroy: noop,
  openConfirmModal: noop,
  closeConfirmModal: noop,
  showConfirmModal: false
});

/**
 *
 * Component
 *
 */
export const ModalManagerContextProvider: FC<PropsWithChildren<never>> = ({
  children
}): JSX.Element => {
  const router = useRouter();
  // In theory, there should only ever be a max of two modals visible at one time.
  // A general purpose modal and a confirmation. Beyond that modals are not allowed
  // to be layered.
  const [modalComponent, setModalComponent] = useState<JSX.Element | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [confirmModalComponent, setConfirmModalComponent] = useState<JSX.Element | null>(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  /**
   * Using reloadOnClose for instances where we want to conditionally query when the modal is closed/canceled.
   * I.e. when an import partially completes and then errors,  some data may have gone through
   * and should be reflected on the page after closing the modal.
   * Different from afterClose which runs every time the modal closes.
   */
  const [reloadOnClose, setReloadOnClose] = useState(false);

  useEffect(() => {
    const handleRouteChange = () => {
      setShowModal(false);
      setShowConfirmModal(false);
    };
    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router]);

  const handleDestroy = (immediate?: boolean, shouldDestroyConfirmModal = false) => {
    const time = immediate ? 0 : 200;
    // timeout used to allow close animation to complete before destroying component
    setTimeout(() => {
      if (shouldDestroyConfirmModal) {
        setConfirmModalComponent(null);
      } else {
        setModalComponent(null);
        setReloadOnClose(false);
      }
    }, time);
  };

  /**
   *
   * Normal Modals
   *
   */
  const closeModal = useCallback(
    ({ reloadOnClose: reload, promptOnClose, forceClose, immediate } = {}) => {
      if (reload) setReloadOnClose(true);

      if (promptOnClose && !forceClose) {
        setConfirmModalComponent(<ConfirmCloseConfirmationModal />);
        setShowConfirmModal(true);
      } else {
        setShowModal(false);
        handleDestroy(immediate);
      }
    },
    []
  );

  const openModal = useCallback(component => {
    setModalComponent(component);
    setShowModal(true);
  }, []);

  /**
   *
   * Confirmation Modals
   *
   */
  const closeConfirmModal = useCallback(() => {
    setShowConfirmModal(false);
    handleDestroy(false, true);
  }, []);

  const openConfirmModal = useCallback(component => {
    setConfirmModalComponent(component);
    setShowConfirmModal(true);
  }, []);

  const value = useMemo(
    () => ({
      openModal,
      closeModal,
      showModal,
      reloadOnClose,
      handleDestroy,
      openConfirmModal,
      closeConfirmModal,
      showConfirmModal
    }),
    [
      closeConfirmModal,
      closeModal,
      openConfirmModal,
      openModal,
      reloadOnClose,
      showConfirmModal,
      showModal
    ]
  );

  return (
    <ModalManagerContext.Provider value={value}>
      {children}
      {modalComponent}
      {confirmModalComponent}
    </ModalManagerContext.Provider>
  );
};
