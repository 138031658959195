export enum AccountStatusEnum {
  'Paid' = 1,
  'Past Due',
  'Canceled',
  'Pending',
  'Suspended',
  'Delinquent',
  'Archived',
  'Trial',
  'Expired'
}

export type AccountStatus = keyof typeof AccountStatusEnum;
