import { GlobalStateContext } from '@context/GlobalContextProvider';
import PRODUCT_CONSTANTS from '@data/productsData';
import getCurrentProductKey from '@utils/defaultPage/getCurrentProductKey';
import { findObjectByProp } from '@utils/general/findObjectByProp';
import Router from 'next/router';
import { useContext, useEffect } from 'react';

/**
 * This hook redirects to the main page of the current product when an account switch is in progress
 * and the user is on a dynamic route. Used in the Global Wrapper.
 *
 * @returns {void}
 */
const useAccountSwitchRedirect = () => {
  const { accountSwitch } = useContext(GlobalStateContext);

  useEffect(() => {
    // Get the current pathname from the router
    const currentPathName = Router.router.pathname;

    // Get the current product from the route
    const product = getCurrentProductKey(currentPathName);

    if (accountSwitch.inProgress) {
      // redirect to the main page for the product
      const { link: productLink } = findObjectByProp(PRODUCT_CONSTANTS, 'key', product) || {};
      Router.push(productLink || '/');
    }
  }, [accountSwitch, accountSwitch.inProgress]);
};

export default useAccountSwitchRedirect;
