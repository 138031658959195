import theme from '@assets/theme';
import ArrowBack from '@mui/icons-material/ArrowBack';

/**
 * NOTE:
 * ErrorPage is the last-resort page if the entire UI is crashing. It's
 * important that this page able to render in as many cases as possible.
 *
 * Thus it intentionally does not use any pre-built React components
 * (aside from the ArrowBack svg icon) and doesn't use translations either.
 * The goal is to keep it as plain HTML as possible, to decrease the surface
 * area of possible errors.
 */

const ErrorPage = () => (
  <div className="error-page">
    <img
      className="logo"
      alt="Marketing 360 Logo"
      src="https://d22if4xzkonim2.cloudfront.net/logo-marketing360.png"
    />
    <img
      className="dog-oops"
      alt="Sad Dog"
      src="https://d22if4xzkonim2.cloudfront.net/mm360_oops.jpg"
    />
    <h1>Something went wrong</h1>
    <p style={{ maxWidth: '450px', width: '90%' }}>
      We've alerted our engineers about the issue you just encountered. For now, you can try{' '}
      <button
        className="u-btn-link"
        type="button"
        onClick={() => {
          window.location.reload(true);
        }}
      >
        reloading the page
      </button>
      .
    </p>
    <p />
    <p>
      <a className="back-button u-btn-link" href="/">
        <ArrowBack className="icon" />
        Back to Dashboard
      </a>
    </p>
    <style jsx>{`
      .error-page {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100vh;
        overflow: auto;
        background: #fafbff;
        text-align: center;
      }
      .logo {
        max-width: 150px;
        margin-top: -3rem;
        margin-bottom: 3rem;
      }
      .dog-oops {
        width: 100%;
        max-width: 600px;
      }
      .error-page h1 {
        font-weight: bold;
        color: ${theme.colors.text};
        margin-bottom: 0.25em;
        margin-top: 0.25em;
      }
      .error-page button {
        display: inline;
        border: none;
        padding: 0;
        margin: 0;
        color: #1890ff;
        cursor: pointer;
        background: none;
      }
      .back-button {
        display: flex;
        align-items: center;
      }
      .back-button :global(.icon) {
        margin-right: 0.25rem;
      }
    `}</style>
  </div>
);

export default ErrorPage;
