/**
 * Select desired keys from provided object, returning a new object.
 * @param  {Object} target
 * @param  {String[]} paths
 * @return {Object}
 */
const pick = (target, paths) =>
  Object.fromEntries(Object.entries(target).filter(([key]) => paths.includes(key)));

export default pick;
