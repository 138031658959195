import { getLocale } from './getLocale';

// Get the 'decimal' or 'group' separator for a given locale;
// defaults to decimal
const SEPARATOR_TYPES = ['decimal', 'group'];
const DEFAULT_SEPARATOR = SEPARATOR_TYPES[0];
export const getSeparator = (locale = getLocale(), separatorType = DEFAULT_SEPARATOR) => {
  const smallSeparatorType = separatorType.toLowerCase();

  if (!SEPARATOR_TYPES.includes(smallSeparatorType)) {
    throw new Error('separatorType must be either "decimal" or "group"');
  }
  const numberWithGroupAndDecimalSeparator = 1000.1;
  return Intl.NumberFormat(locale)
    .formatToParts(numberWithGroupAndDecimalSeparator)
    .find(part => part.type === smallSeparatorType).value;
};
