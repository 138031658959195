// These functions are for testing purposes.
// They set the progress of the setup screens.
// TODO: Remove or update these functions accordingly.

const getSetupData = product => {
  const key = `mm360_ProductSetup_${product}`;
  let data = localStorage.getItem(key);
  data = JSON.parse(data);
  return data;
};

// This will be used across platforms with only 1 step
// or on their final step.
export const setupComplete = JSON.stringify({
  isComplete: true
});

const setSetupData = (product, data = {}) => {
  if (typeof data === 'object') {
    const key = `mm360_ProductSetup_${product}`;
    try {
      const strData = JSON.stringify(data);
      localStorage.setItem(key, strData);
    } catch (error) {
      console.log(error);
    }
  } else {
    console.log('ERROR: Object not provided.');
  }
};

const incrementStep = (stepNumber, data) => {
  // add to completed steps array and increment currentStep
  if (data && !data.completedSteps.includes(stepNumber)) {
    const newData = {
      ...data,
      currentStep: stepNumber + 1,
      completedSteps: [...data.completedSteps, stepNumber]
    };
    // check if the setup wizard is completed
    if (newData.completedSteps.length === newData.totalSteps) {
      newData.isComplete = true;
    }
    return newData;
  }
};

const isSetupComplete = setup => {
  if (!setup || setup === 'null') {
    return false;
  }
  if (typeof setup === 'string') {
    const setupObject = JSON.parse(setup);
    return setupObject.isComplete;
  }
};

// Update subProducts recursively, breadth-first. Stop if found.
const updateSetup = (products, action) => {
  let found = false;
  const productsUpdate = products.map(product => {
    if (product.id === action.productId) {
      found = true;
      return { ...product, setup: action.setup };
    }
    return product;
  });

  if (!found) {
    productsUpdate.forEach((product, i) => {
      if (product.subProducts && product.subProducts.length > 0) {
        productsUpdate[i] = {
          ...product,
          subProducts: updateSetup(product.subProducts, action)
        };
      }
    });
  }

  return productsUpdate;
};

export { getSetupData, setSetupData, incrementStep, isSetupComplete, updateSetup };
