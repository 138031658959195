import css from 'styled-jsx/css';
import theme from './theme';

export const utilities = css.global`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
  }

  .u-h1,
  .u-h2,
  .u-h3,
  .u-h4,
  .u-h5,
  .u-h6 {
    font-weight: 500 !important;
  }

  h1 {
    font-size: 2.286rem;
  }
  .u-h1 {
    font-size: 2.286rem !important;
  }
  h2 {
    font-size: 1.715rem;
  }
  .u-h2 {
    font-size: 1.715rem !important;
  }
  h3 {
    font-size: 1.358rem;
  }
  .u-h3 {
    font-size: 1.358rem !important;
  }
  h4 {
    font-size: 1.143rem;
  }
  .u-h4 {
    font-size: 1.143rem !important;
  }
  h5 {
    font-size: 1.143rem;
  }
  .u-h5 {
    font-size: 1.143rem !important;
  }
  h6 {
    font-size: 1rem;
  }
  .u-h6 {
    font-size: 1rem !important;
  }

  @media (min-width: 768px) {
    h1 {
      font-size: 3rem;
    }
    .u-h1 {
      font-size: 3rem !important;
    }
    h2 {
      font-size: 2.286rem;
    }
    .u-h2 {
      font-size: 2.286rem !important;
    }
    h3 {
      font-size: 2rem;
    }
    .u-h3 {
      font-size: 2rem !important;
    }
    h4 {
      font-size: 1.715rem;
    }
    .u-h4 {
      font-size: 1.715rem !important;
    }
    h5 {
      font-size: 1.429rem;
    }
    .u-h5 {
      font-size: 1.429rem !important;
    }
    h6 {
      font-size: 1.143rem;
    }
    .u-h6 {
      font-size: 1.143rem !important;
    }
  }

  .u-font-size-sm {
    font-size: 0.86rem !important;
  }
  .u-font-size-md {
    font-size: 1.143rem !important;
  }
  .u-font-size-lg {
    font-size: 1.286rem !important;
  }
  .u-font-size-xl {
    font-size: 1.572rem !important;
  }
  .u-font-size-normal {
    font-size: 1rem !important;
  }

  .u-underline {
    padding-bottom: 5px;
    border-bottom: 1px solid ${theme.borderColor};
  }

  .u-text-underline {
    text-decoration: underline;
  }

  .u-color-white {
    color: #ffffff;
  }
  .u-color-error {
    color: #de1207;
  }
  .u-color-gray {
    color: ${theme.colors.gray};
  }

  .u-link-text,
  .u-color-text {
    color: ${theme.colors.text};
  }

  .u-list-style-none {
    padding-left: 0;
    list-style: none;
  }

  .u-hover-fade:hover {
    opacity: 0.5;
  }

  /* Hide only visually, but have it available for screen readers */
  .u-sr-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap; /* 1 */
  }

  .u-btn-link,
  .u-btn-text {
    padding: 0;
    background: none;
    border: none;
    box-shadow: none;
    color: ${theme.colors.primary};
    transition: color ${theme.transitionEffect};
    text-align: inherit;
  }
  .u-btn-link:hover,
  .u-btn-link:focus {
    background: none;
    color: ${theme.colors.primaryDarker};
  }
  .u-btn-link:focus,
  .u-btn-text:focus {
    text-decoration: underline;
    outline: 3px solid rgba(51, 113, 183, 0.5);
  }
  .u-btn-link:active {
    color: ${theme.colors.primaryDarker};
    text-decoration: none;
  }
  .u-btn-link:not(:disabled):not(.disabled),
  .u-btn-text:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  .u-btn-link[disabled],
  .u-btn-text[disabled] {
    background: none;
  }

  .u-btn-text {
    color: ${theme.colors.text};
  }
  .u-btn-text:hover,
  .u-btn-text:focus {
    color: ${theme.colors.primary};
  }
  .u-btn-link:active {
    color: #333333;
    text-decoration: none;
  }

  .u-btn-icon,
  .u-flex-center {
    display: flex;
    align-items: center;
  }

  .u-flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .u-flex-end {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }

  .u-flex-start {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  /* Forms */

  .u-form-description {
    font-size: 1.05rem;
    font-weight: 500;
  }

  .u-req {
    color: #da0505;
  }

  .u-form-label {
    font-weight: 500;
  }

  /* Bootstrap 4 Utilities */

  .u-text-justify {
    text-align: justify !important;
  }

  .u-text-wrap {
    white-space: normal !important;
  }

  .u-text-nowrap {
    white-space: nowrap !important;
  }

  .u-text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .u-text-left {
    text-align: left !important;
  }

  .u-text-right {
    text-align: right !important;
  }

  .u-text-center {
    text-align: center !important;
  }

  @media (min-width: 576px) {
    .u-text-sm-left {
      text-align: left !important;
    }
    .u-text-sm-right {
      text-align: right !important;
    }
    .u-text-sm-center {
      text-align: center !important;
    }
  }

  @media (min-width: 768px) {
    .u-text-md-left {
      text-align: left !important;
    }
    .u-text-md-right {
      text-align: right !important;
    }
    .u-text-md-center {
      text-align: center !important;
    }
  }

  @media (min-width: 992px) {
    .u-text-lg-left {
      text-align: left !important;
    }
    .u-text-lg-right {
      text-align: right !important;
    }
    .u-text-lg-center {
      text-align: center !important;
    }
  }

  @media (min-width: 1200px) {
    .u-text-xl-left {
      text-align: left !important;
    }
    .u-text-xl-right {
      text-align: right !important;
    }
    .u-text-xl-center {
      text-align: center !important;
    }
  }

  .u-text-lowercase {
    text-transform: lowercase !important;
  }
  .u-text-uppercase {
    text-transform: uppercase !important;
  }
  .u-text-capitalize {
    text-transform: capitalize !important;
  }

  .u-font-weight-light {
    font-weight: 300 !important;
  }
  .u-font-weight-normal {
    font-weight: 400 !important;
  }
  .u-font-weight-semi {
    font-weight: 500 !important;
  }
  .u-font-weight-bold {
    font-weight: 700 !important;
  }
  .u-font-italic {
    font-style: italic !important;
  }

  .u-embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
  }

  .u-embed-responsive::before {
    display: block;
    content: '';
  }

  .u-embed-responsive .u-embed-responsive-item,
  .u-embed-responsive iframe,
  .u-embed-responsive embed,
  .u-embed-responsive object,
  .u-embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  .u-embed-responsive-21by9::before {
    padding-top: 42.857143%;
  }

  .u-embed-responsive-16by9::before {
    padding-top: 56.25%;
  }

  .u-embed-responsive-3by4::before {
    padding-top: 133.333333%;
  }

  .u-embed-responsive-1by1::before {
    padding-top: 100%;
  }

  .u-m-0 {
    margin: 0 !important;
  }

  .u-mt-0,
  .u-my-0 {
    margin-top: 0 !important;
  }

  .u-mr-0,
  .u-mx-0 {
    margin-right: 0 !important;
  }

  .u-mb-0,
  .u-my-0 {
    margin-bottom: 0 !important;
  }

  .u-ml-0,
  .u-mx-0 {
    margin-left: 0 !important;
  }

  .u-m-1 {
    margin: 0.25rem !important;
  }

  .u-mt-1,
  .u-my-1 {
    margin-top: 0.25rem !important;
  }

  .u-mr-1,
  .u-mx-1 {
    margin-right: 0.25rem !important;
  }

  .u-mb-1,
  .u-my-1 {
    margin-bottom: 0.25rem !important;
  }

  .u-ml-1,
  .u-mx-1 {
    margin-left: 0.25rem !important;
  }

  .u-m-2 {
    margin: 0.5rem !important;
  }

  .u-mt-2,
  .u-my-2 {
    margin-top: 0.5rem !important;
  }

  .u-mr-2,
  .u-mx-2 {
    margin-right: 0.5rem !important;
  }

  .u-mb-2,
  .u-my-2 {
    margin-bottom: 0.5rem !important;
  }

  .u-ml-2,
  .u-mx-2 {
    margin-left: 0.5rem !important;
  }

  .u-m-3 {
    margin: 1rem !important;
  }

  .u-mt-3,
  .u-my-3 {
    margin-top: 1rem !important;
  }

  .u-mr-3,
  .u-mx-3 {
    margin-right: 1rem !important;
  }

  .u-mb-3,
  .u-my-3 {
    margin-bottom: 1rem !important;
  }

  .u-ml-3,
  .u-mx-3 {
    margin-left: 1rem !important;
  }

  .u-m-4 {
    margin: 1.5rem !important;
  }

  .u-mt-4,
  .u-my-4 {
    margin-top: 1.5rem !important;
  }

  .u-mr-4,
  .u-mx-4 {
    margin-right: 1.5rem !important;
  }

  .u-mb-4,
  .u-my-4 {
    margin-bottom: 1.5rem !important;
  }

  .u-ml-4,
  .u-mx-4 {
    margin-left: 1.5rem !important;
  }

  .u-m-5 {
    margin: 3rem !important;
  }

  .u-mt-5,
  .u-my-5 {
    margin-top: 3rem !important;
  }

  .u-mr-5,
  .u-mx-5 {
    margin-right: 3rem !important;
  }

  .u-mb-5,
  .u-my-5 {
    margin-bottom: 3rem !important;
  }

  .u-ml-5,
  .u-mx-5 {
    margin-left: 3rem !important;
  }

  .u-m-auto {
    margin: auto !important;
  }

  .u-mt-auto,
  .u-my-auto {
    margin-top: auto !important;
  }

  .u-mr-auto,
  .u-mx-auto {
    margin-right: auto !important;
  }

  .u-mb-auto,
  .u-my-auto {
    margin-bottom: auto !important;
  }

  .u-ml-auto,
  .u-mx-auto {
    margin-left: auto !important;
  }

  .u-p-0 {
    padding: 0 !important;
  }

  .u-pt-0,
  .u-py-0 {
    padding-top: 0 !important;
  }

  .u-pr-0,
  .u-px-0 {
    padding-right: 0 !important;
  }

  .u-pb-0,
  .u-py-0 {
    padding-bottom: 0 !important;
  }

  .u-pl-0,
  .u-px-0 {
    padding-left: 0 !important;
  }

  .u-p-1 {
    padding: 0.25rem !important;
  }

  .u-pt-1,
  .u-py-1 {
    padding-top: 0.25rem !important;
  }

  .u-pr-1,
  .u-px-1 {
    padding-right: 0.25rem !important;
  }

  .u-pb-1,
  .u-py-1 {
    padding-bottom: 0.25rem !important;
  }

  .u-pl-1,
  .u-px-1 {
    padding-left: 0.25rem !important;
  }

  .u-p-2 {
    padding: 0.5rem !important;
  }

  .u-pt-2,
  .u-py-2 {
    padding-top: 0.5rem !important;
  }

  .u-pr-2,
  .u-px-2 {
    padding-right: 0.5rem !important;
  }

  .u-pb-2,
  .u-py-2 {
    padding-bottom: 0.5rem !important;
  }

  .u-pl-2,
  .u-px-2 {
    padding-left: 0.5rem !important;
  }

  .u-p-3 {
    padding: 1rem !important;
  }

  .u-pt-3,
  .u-py-3 {
    padding-top: 1rem !important;
  }

  .u-pr-3,
  .u-px-3 {
    padding-right: 1rem !important;
  }

  .u-pb-3,
  .u-py-3 {
    padding-bottom: 1rem !important;
  }

  .u-pl-3,
  .u-px-3 {
    padding-left: 1rem !important;
  }

  .u-p-4 {
    padding: 1.5rem !important;
  }

  .u-pt-4,
  .u-py-4 {
    padding-top: 1.5rem !important;
  }

  .u-pr-4,
  .u-px-4 {
    padding-right: 1.5rem !important;
  }

  .u-pb-4,
  .u-py-4 {
    padding-bottom: 1.5rem !important;
  }

  .u-pl-4,
  .u-px-4 {
    padding-left: 1.5rem !important;
  }

  .u-p-5 {
    padding: 3rem !important;
  }

  .u-pt-5,
  .u-py-5 {
    padding-top: 3rem !important;
  }

  .u-pr-5,
  .u-px-5 {
    padding-right: 3rem !important;
  }

  .u-pb-5,
  .u-py-5 {
    padding-bottom: 3rem !important;
  }

  .u-pl-5,
  .u-px-5 {
    padding-left: 3rem !important;
  }

  .u-d-none {
    display: none !important;
  }
  .u-d-inline {
    display: inline !important;
  }
  .u-d-inline-block {
    display: inline-block !important;
  }
  .u-d-block {
    display: block !important;
  }
  .u-d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  @media (min-width: 576px) {
    .u-d-sm-none {
      display: none !important;
    }
    .u-d-sm-inline {
      display: inline !important;
    }
    .u-d-sm-inline-block {
      display: inline-block !important;
    }
    .u-d-sm-block {
      display: block !important;
    }
    .u-d-sm-flex {
      display: -ms-flexbox !important;
      display: flex !important;
    }
  }

  @media (min-width: 768px) {
    .u-d-md-none {
      display: none !important;
    }
    .u-d-md-inline {
      display: inline !important;
    }
    .u-d-md-inline-block {
      display: inline-block !important;
    }
    .u-d-md-block {
      display: block !important;
    }
    .u-d-md-flex {
      display: -ms-flexbox !important;
      display: flex !important;
    }
  }

  @media (min-width: 992px) {
    .u-d-lg-none {
      display: none !important;
    }
    .u-d-lg-inline {
      display: inline !important;
    }
    .u-d-lg-inline-block {
      display: inline-block !important;
    }
    .u-d-lg-block {
      display: block !important;
    }
    .u-d-lg-flex {
      display: -ms-flexbox !important;
      display: flex !important;
    }
  }

  @media (min-width: 1200px) {
    .u-d-xl-none {
      display: none !important;
    }
    .u-d-xl-inline {
      display: inline !important;
    }
    .u-d-xl-inline-block {
      display: inline-block !important;
    }
    .u-d-xl-block {
      display: block !important;
    }
    .u-d-xl-flex {
      display: -ms-flexbox !important;
      display: flex !important;
    }
  }

  .u-justify-content-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
`;
