import { getApiEnvironment } from '@api/apiConfig';
import { apiNotifications } from '@api/notifications';
import moment from 'moment';

const sinceTimeLastMonth = encodeURI(moment().subtract(1, 'month').toISOString());

export const loadExistingNotifications = async (dispatch, sinceTime = sinceTimeLastMonth) => {
  try {
    const { data } = await apiNotifications({
      url: '',
      method: 'get',
      payload: {
        since: sinceTime,
        acknowledged: false
      }
    });

    const toasts = [];
    const modals = [];
    const banners = [];

    data.response.forEach(datum => {
      if (datum.type === 'dashboard') {
        toasts.push(datum);
      }
      if (datum.type === 'modal') {
        modals.push(datum);
      }
      if (datum.type === 'scoreboard') {
        banners.push(datum);
      }
    });

    dispatch({
      type: 'LOADED',
      toasts,
      modals,
      banners
    });
  } catch (e) {
    // console.log(e);
    dispatch({ type: 'ERROR' });
  }
};

const sinceTimeNow = encodeURI(moment().toISOString());
const connectToStream = (dispatch, accountNumber, sinceTime, connectionError) => {
  const streamBaseURL = `https://notifications.${getApiEnvironment()}.marketing360.com`;

  if (!window.EventSource) {
    return false;
  }

  const evtSource = new EventSource(
    `${streamBaseURL}/api/notifications/${accountNumber}/inapp/stream?since=${sinceTime}`,
    {
      withCredentials: true
    }
  );

  evtSource.onopen = function (err) {
    dispatch({ type: 'CONNECTED' });
  };

  evtSource.addEventListener('info', event => {
    dispatch({ type: 'ADD', data: JSON.parse(event.data) });
  });

  evtSource.addEventListener('warning', event => {
    dispatch({ type: 'ADD', data: JSON.parse(event.data) });
  });

  evtSource.addEventListener('promo', event => {
    dispatch({ type: 'ADD', data: JSON.parse(event.data) });
  });

  evtSource.addEventListener('legal', event => {
    dispatch({ type: 'ADD', data: JSON.parse(event.data) });
  });

  evtSource.onerror = function (err) {
    dispatch({ type: 'CONNECTION_LOST' });
    connectionError();
  };

  return evtSource;
};

export const subscribeNotifications = (dispatch, accountNumber, sinceTime = sinceTimeNow) => {
  let evtSource = null;
  let reconnectCount = 0;
  let cancelSubscription = false;
  let reconnectTimeout = null;

  const connectionError = () => {
    if (!cancelSubscription && evtSource) {
      evtSource.close();

      // Attempt to reconnect
      // If reconnect fails code will loop through this 'onerror' forever,
      // continuously trying to reconnect with the set time delay.
      reconnectTimeout = setTimeout(() => {
        reconnectCount += 1;
        evtSource = connectToStream(dispatch, accountNumber, sinceTime, connectionError);
      }, 8000 + 1000 * 2 ** reconnectCount);
    }
  };

  evtSource = connectToStream(dispatch, accountNumber, sinceTime, connectionError);

  return {
    stream: evtSource, // Needed for unit tests
    cancelSubscription: () => {
      cancelSubscription = true;
      if (reconnectTimeout) clearTimeout(reconnectTimeout);
      if (evtSource) evtSource.close();
    }
  };
};

export const isNewNotification = (currentSet, newNotification) => {
  let existingFound = false;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < currentSet.length && !existingFound; i++) {
    if (currentSet[i].id === newNotification.id) {
      existingFound = true;
    }
  }

  if (existingFound) {
    return false;
  }
  return true;
};

// This is _not_ wrapped in a try/catch, make sure to do so in the
// UI component using the helper.
export const acknowledgeNotification = async (dispatch, notification) => {
  await apiNotifications({
    url: notification.id.toString(),
    method: 'patch',
    payload: {
      acknowledged: true
    }
  });
  dispatch({ type: 'DISMISS', data: notification });
};
