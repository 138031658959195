import theme from '@assets/theme';
import PropTypes from 'prop-types';
import React from 'react';

const Loading = ({ size, color, opacity, center }) => {
  const centerCssRules = center
    ? {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginLeft: `-${size / 2}px`,
        marginTop: `-${size / 2}px`
      }
    : {};
  return (
    <>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 50 50"
        style={{
          enableBackground: 'new 0 0 50 50',
          width: size,
          height: size,
          opacity,
          ...centerCssRules
        }}
        xmlSpace="preserve"
      >
        <path
          fill={color}
          d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"
        >
          <animateTransform
            attributeType="xml"
            attributeName="transform"
            type="rotate"
            from="0 25 25"
            to="360 25 25"
            dur="0.6s"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </>
  );
};

Loading.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  opacity: PropTypes.number
};
Loading.defaultProps = {
  size: 30,
  color: theme.colors.primary,
  opacity: 1
};

export default React.memo(Loading);
