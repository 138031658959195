/**
 * Searches for an object in a given object or its nested objects based on the value of a given property.
 *
 * @param {Object} [obj={}] - The object to search.
 * @param {string} [prop='key'] - The property to search for. Defaults to 'key'.
 * @param {*} [value=''] - The value to match.
 * @returns {Object|null} - The first object found with the given property and value, or null if not found.
 */
export function findObjectByProp(obj = {}, prop = 'key', value = '') {
  if (!obj) {
    return null;
  }

  if (Object.prototype.hasOwnProperty.call(obj, prop) && obj[prop] === value) {
    return obj;
  }

  const keys = Object.keys(obj);
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    if (typeof obj[key] === 'object') {
      const result = findObjectByProp(obj[key], prop, value);
      if (result) {
        return result;
      }
    }
  }

  return null;
}
