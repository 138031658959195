import {
  isNewNotification,
  loadExistingNotifications,
  subscribeNotifications
} from '@utils/notifications';
import PropTypes from 'prop-types';
import React, { useEffect, useReducer } from 'react';

export const NotificationsStateContext = React.createContext();
export const NotificationsDispatchContext = React.createContext();

const getTypeKey = type => {
  switch (type) {
    case 'dashboard':
    case 'toast':
      return 'toasts';
    case 'modal':
      return 'modals';
    case 'scoreboard':
    case 'banner':
      return 'banners';
    default:
      console.log('Unknown notification type. Using default value');
      return 'toasts';
  }
};

const initialState = {
  initialized: false,
  loaded: false,
  connected: true,
  error: false,
  drawerVisible: false,
  toasts: [],
  toastsQueue: [],
  modals: [],
  banners: []
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'RESET':
      return { ...initialState };
    case 'INITIALIZED':
      return {
        ...state,
        initialized: true
      };
    case 'LOADED':
      return {
        ...state,
        loaded: true,
        toasts: action.toasts || [],
        modals: action.modals || [],
        banners: action.banners || []
      };
    case 'CONNECTED':
      return {
        ...state,
        connected: true
      };
    case 'CONNECTION_LOST':
      return {
        ...state,
        connected: false
      };
    case 'ERROR':
      return {
        ...state,
        error: true
      };
    case 'ADD': {
      const { data: notification } = action;
      const typeKey = getTypeKey(notification.type);

      if (!isNewNotification(state[typeKey], notification)) {
        return state;
      }

      // If type is 'toasts' we push to live queue as well
      if (typeKey === 'toasts') {
        return {
          ...state,
          toasts: notification.__hideToastFromDrawer
            ? state.toasts
            : [...state.toasts, action.data],
          toastsQueue: [...state.toastsQueue, action.data]
        };
      }

      return {
        ...state,
        [typeKey]: [...state[typeKey], action.data]
      };
    }
    case 'DISMISS': {
      const { data: notification } = action;
      const typeKey = getTypeKey(notification.type);
      return {
        ...state,
        [typeKey]: state[typeKey].filter(datum => datum.id !== notification.id)
      };
    }
    case 'CLEAR_TOASTS_QUEUE':
      return {
        ...state,
        toastsQueue: []
      };
    case 'DRAWER': {
      return {
        ...state,
        drawerVisible: action.visible
      };
    }
    default:
      throw new Error();
  }
};

const NotificationsContextProvider = ({ accountNumber, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    let eventSubscription = null;
    if (typeof window !== 'undefined' && accountNumber) {
      // TODO: Enable once notifications service urls fixed
      loadExistingNotifications(dispatch);
      eventSubscription = subscribeNotifications(dispatch, accountNumber);
      dispatch({ type: 'INITIALIZED' });

      // REMOVE ME WHEN ABOVE CODE IS ENABLED
      // dispatch({ type: 'ERROR' });
    }

    const closeConnection = () => eventSubscription.cancelSubscription();
    if (eventSubscription) {
      window.addEventListener('beforeunload', closeConnection);
    }

    return () => {
      if (eventSubscription) {
        window.removeEventListener('beforeunload', closeConnection);
        dispatch({ type: 'RESET' });
        closeConnection();
      }
    };
  }, [accountNumber]);

  return (
    <NotificationsStateContext.Provider value={state}>
      <NotificationsDispatchContext.Provider value={dispatch}>
        {children}
      </NotificationsDispatchContext.Provider>
    </NotificationsStateContext.Provider>
  );
};

NotificationsContextProvider.propTypes = {
  children: PropTypes.element,
  accountNumber: PropTypes.string
};

export default NotificationsContextProvider;
