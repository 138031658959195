var f = function FormFieldGroup_TagList(data) {
  this.update(data);
};

f.prototype.update = function(data) {
  this.parentUpdate(data);
};

f.prototype.payloadValue = function() {
  return this.options;
};

f.prototype.addValue = function(value) {
  this.options.push(value);
};

f.prototype.setValue = function(value) {
  this.defaultValues.splice(0);
  Array.prototype.push.apply(this.defaultValues, value);
};

export default f;
