import { getLocale } from '@utils/general';

/**
 * Constants
 */
const STRIPE_UNITS = 100;

/**
 *
 * Helper
 *
 * Formats currency amounts, defaulting to handling as cents
 * (per Sripe convention)
 *
 */
export const filterBillingCurrency = (amount, currency = 'usd', unit = STRIPE_UNITS) => {
  try {
    if (typeof amount !== 'number') {
      return '-';
    }
    const amountFormat = new Intl.NumberFormat(getLocale(), {
      style: 'currency',
      currency,
      minimumFractionDigits: 2
    });
    return amountFormat.format(amount / unit);
  } catch (err) {
    return '-';
  }
};
