import { apiAccounts } from '@api/core';
import { apiCrm } from '@api/crm';
import validateUrl from '@utils/defaultPage/validateUrl';
import { handleErrorMessage } from '@utils/global';

/**
 * Skip to bottom to see documentation...
 */

/**---------------------------
 *            CRM
 *---------------------------*/

// Get the status of the CRM product from the accounts service.
export const getCrmProductStatus = async (url, dispatch) => {
  try {
    const { data } = await apiAccounts({
      url: `/products/2`,
      method: 'get',
      withAccountNumber: true
    });

    const crmProduct = data?.response?.product;

    // Update the CRM product in the Global State.
    dispatch({ type: 'UPDATE_SINGLE_PRODUCT', product: crmProduct });

    // Redirect based on the product's new status.
    validateUrl(url, [crmProduct]);
  } catch (error) {
    // This should be a silent error, since this api call is also silent to the user.
    console.log(error);
  }
};

export const getCrmTranslation = async (url, dispatch) => {
  try {
    // We switched to adding these custom keys to the i18n store in useCrmTranslation,
    // but are leaving this request here to preserve the provisioning error handling outlined in the catch block
    await apiCrm({
      url: 'translation/bulk?words[]=Project&words[]=Projects&words[]=Deal&words[]=Deals',
      method: 'get'
    });
  } catch (error) {
    const errorMessage = handleErrorMessage(error);
    // The CRM will sometimes fail to provision properly. When we see a certain error
    // message, the CRM will update its "status" with the accounts service.
    // We check this status and redirect to the proper status page.
    // https://app.clubhouse.io/madwire/story/2902/when-the-crm-main-page-throws-a-404-display-the-product-status-page
    if (errorMessage.includes('CRM Database not found for')) {
      getCrmProductStatus(url, dispatch);
    }
  }
};

/**--------------------
 * productRenderActions
 *
 * This file allows product-specific methods to be called only for those
 * specific products, while on a global scope. Useful for loading configs
 * shared across an entire product, but only within that product.
 *
 * The product slug is pulled from the current url, then will be called
 * inside the productInits object.
 *
 * !! These initializations are only run _once_, upon initial visit to
 * the product. !!
 * To force them to be re-run, set the initialized boolean for
 * each product to false.
 */
const productRenderActions = (url, state, dispatch) => {
  const urlSegments = url.split('/');
  let product = urlSegments[1] && urlSegments[1] !== '' ? urlSegments[1] : 'global';

  // If we're in the start section of pages the product name is nested one layer.
  if (product === 'start') {
    product = urlSegments[2];
  }

  if (
    state.products &&
    state.products[product] &&
    state.products[product].global_render_action_complete
  ) {
    return false;
  }

  const productInits = {
    crm: () => getCrmTranslation(url, dispatch),
    default: () => null
  };

  dispatch({
    type: 'SET_PRODUCT_VALUE',
    productKey: product,
    itemKey: 'global_render_action_complete',
    value: true
  });

  return (productInits[product] || productInits.default)(state);
};

export default productRenderActions;
