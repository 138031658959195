/* eslint-disable @typescript-eslint/ban-ts-comment */
import ModalOptionsWrapper from '@components/ModalOptionsWrapper';
import useEmailVerify from '@hooks/useEmailVerify';
import useModalManager from '@hooks/useModalManager';
import { Box, ButtonLink, Flex, Heading, Text } from '@madwire/m360ui';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';

interface Props {
  email: string;
  resendEmail: () => void;
}

const VerifyEmailModal: React.FC<Props> = ({ email, resendEmail }) => {
  const { t } = useTranslation('common');
  const { closeModal } = useModalManager();
  const { isVerified } = useEmailVerify();

  useEffect(() => {
    if (isVerified) {
      closeModal();
    }
  }, [closeModal, isVerified]);

  return (
    <ModalOptionsWrapper
      width={550}
      title={
        // @ts-ignore
        <Flex mx={{ alignItems: 'center' }}>
          {/* @ts-ignore */}
          <Box mx={{ color: 'state.warning', mr: 3, display: 'inline-flex' }}>
            <ErrorOutline />
          </Box>
          <Heading type="h6" as="h2" mx={{ mb: 0 }}>
            {t('common:verify-email.title')}
          </Heading>
        </Flex>
      }
      closable={false}
      keyboard={false}
      maskClosable={false}
      centered
    >
      {/* @ts-ignore */}
      <Text mx={{ mb: 0, wordBreak: 'break-word' }}>
        {t('common:verify-email.description', {
          email: email || t('common:verify-email.yourEmail')
        })}{' '}
        {/* @ts-ignore */}
        <ButtonLink mx={{ p: 0, height: 'auto', fontWeight: 'normal' }} onClick={resendEmail}>
          {t('common:verify-email.resend')}
        </ButtonLink>
      </Text>
    </ModalOptionsWrapper>
  );
};

export default VerifyEmailModal;
