import { acknowledgeNotification } from '@utils/notifications';
import { message } from 'antd';
import PropTypes from 'prop-types';
import NotificationModal from './NotificationModal';

const NotificationModals = ({ modals, dispatch, t }) => {
  // We only acknowledge legal notifications one at a time
  const acknowledge = async notification => {
    try {
      await acknowledgeNotification(dispatch, notification);
    } catch (e) {
      // console.log(e);
      message.error(t('common:messages.error.plain'));
    }
  };

  return modals.map(notification => (
    <NotificationModal
      key={notification.id}
      acknowledge={acknowledge}
      data={notification}
      dismissButtonType="primary"
      dismissButtonLabel={t('i-accept')}
    />
  ));
};

NotificationModals.propTypes = {
  modals: PropTypes.array,
  dispatch: PropTypes.func,
  t: PropTypes.func
};

export default NotificationModals;
