var f = function FormFieldGroup_Password(data) {
  this.update(data);
};

f.prototype.update = function(data) {
  this.parentUpdate(data);
  if (data.defaultValues) {
    this.value = data.defaultValues[0];
  } else {
    this.value = '';
  }
};

f.prototype.payloadValue = function() {
  return this.value || '';
};

export default f;
