import { Button, notification } from 'antd';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import ToastBody from './ToastBody';

const dismissButton = (notificationKey, t) => (
  <Button
    className="u-btn-text u-font-size-sm"
    size="small"
    style={{
      position: 'absolute',
      bottom: '1.25rem',
      right: '22px',
      height: 'auto'
    }}
    onClick={() => notification.close(notificationKey)}
  >
    {t('dismiss')}
  </Button>
);

const actionButton = (action, title) => (
  <Button
    className="primary u-font-size-sm"
    size="small"
    style={{
      height: 'auto',
      float: 'right'
    }}
    onClick={action}
  >
    {title}
  </Button>
);

const pushNewNotifications = (notifications, dispatch, t) => {
  for (let i = 0; i < notifications.length; i++) {
    setTimeout(() => {
      const key = `notification${Date.now()}`;

      notification.open({
        key,
        message: (
          <div className="toast-body">
            <ToastBody notification={notifications[i]} t={t} noHover />
          </div>
        ),
        duration: Object.keys(notifications[i]).includes('duration')
          ? notifications[i].duration
          : 4,
        top: 75,
        style: {},
        btn:
          notifications[i].action && notifications[i].actionTitle
            ? actionButton(notifications[i].action, notifications[i].actionTitle)
            : dismissButton(key, t),
        closeIcon: null
      });
    }, 100 * i);
  }

  dispatch({ type: 'CLEAR_TOASTS_QUEUE' });
};
const pushNewNotificationsDebounce = debounce(pushNewNotifications, 200);

const NotificationToasts = ({ toastsQueue, dispatch, t }) => {
  // Watch for and push any new toast notifications
  useEffect(() => {
    if (toastsQueue.length > 0) {
      pushNewNotificationsDebounce(toastsQueue, dispatch, t);
    }
  }, [dispatch, toastsQueue, t]);

  return null;
};

NotificationToasts.propTypes = {
  toastsQueue: PropTypes.array,
  dispatch: PropTypes.func,
  t: PropTypes.func
};

export default NotificationToasts;
