import {
  NotificationsDispatchContext,
  NotificationsStateContext
} from '@context/NotificationsContextProvider';
import { IconButton, useM360UI } from '@madwire/m360ui';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import SettingsOutlined from '@mui/icons-material/SettingsOutlined';
import { Drawer } from 'antd';
import { useTranslation } from 'next-i18next';
import NextLink from 'next/link';
import { useContext } from 'react';
import DropdownContent from './dropdown/DropdownContent';

const NotificationDrawer = () => {
  const { drawerVisible } = useContext(NotificationsStateContext);
  const dispatch = useContext(NotificationsDispatchContext);
  const closeDrawer = () => dispatch({ type: 'DRAWER', visible: false });
  const { t } = useTranslation(['notifications', 'settings']);
  const { theme } = useM360UI();

  // icon button customization requests for this instance in
  // https://app.shortcut.com/madwire/story/25674/add-settings-link-to-notifications-drawer
  // Old, non-M360UI color used here as iconBaseColor match the color of nav bar icons,
  // since it's a related interaction and the nav bar isn't updated to full M360UI standards yet.
  // Can remove after https://app.shortcut.com/madwire/story/25961/update-nav-bar-to-use-m360ui-or-at-least-full-compliance-with-theme-colors-sizes-etc gets done.
  const iconBaseColor = '#484F54';
  const iconButtonMx = {
    width: '20px',
    height: '20px',
    'svg.MuiSvgIcon-root': {
      width: '20px',
      height: '20px'
    },
    // desktop/mobile Safari was getting weird with these,
    // probably because of the "as" prop; this solves it
    WebkitAppearance: 'none !important',
    color: iconBaseColor,
    // also required when using the "as" prop to match M360UI IconButton behavior
    '&:hover': {
      color: iconBaseColor
    },
    '&:active, &:active:hover': {
      color: theme.colors.cta.primary
    }
  };

  return (
    <div>
      <Drawer
        className="notification-drawer"
        closeIcon={
          <IconButton as="span" mx={iconButtonMx}>
            <CloseOutlined />
          </IconButton>
        }
        title={t('title_plural')}
        extra={
          <NextLink href="/settings/profile/notifications" passHref>
            <IconButton
              as="a"
              label={t('settings:notifications.title')}
              onClick={closeDrawer}
              mx={iconButtonMx}
            >
              <SettingsOutlined />
            </IconButton>
          </NextLink>
        }
        placement="right"
        onClose={closeDrawer}
        open={drawerVisible}
        width={350}
      >
        <DropdownContent t={t} />
      </Drawer>
      <style jsx global>{`
        .notification-drawer .ant-drawer-body {
          padding: 0;
        }
        .notification-drawer .ant-drawer-close {
          padding: 0;
        }
        .notification-drawer .ant-drawer-extra {
          display: flex;
          align-items: center;
        }
      `}</style>
    </div>
  );
};

export default NotificationDrawer;
