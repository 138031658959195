var f = function FormFieldGroup_ColorTag(data) {
  this.update(data);
};

f.prototype.update = function(data) {
  this.parentUpdate(data);
  this.value = this.defaultValues;
  if (this.disabled == null || this.disabled == undefined) {
    this.disabled = 0;
  }
};

f.prototype.payloadValue = function() {
  return this.value;
};

export default f;
