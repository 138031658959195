import * as PRODUCT_CONSTANTS from '@data/productsData';
import { findObjectByProp } from '@utils/general/findObjectByProp';
import Router from 'next/router';
/**
 * Ensure the user can visit the specified url.
 *
 * Current used to automatically redirect to/from product
 * setup pages, based on their initialization status.
 */

// We may need to remove billing from this list later.
// TODO: brand-profile is temporary, remove after the product definition is returned by the backend properly

const alwaysActivePaths = [
  '',
  'calendar',
  'files',
  'help',
  'settings',
  'billing',
  'start',
  'forms',
  'accounts',
  'plans-pricing',
  'activity-detail',
  'brand-profile'
];

// These have sub products we need to check
const parentProducts = ['nurture', 'creative', 'reputation'];

// Map the subProduct paths to their subProduct keys
const subProductMappings = {
  text: 'smsMarketing',
  email: 'emailMarketing',
  'banner-builder': 'bannerBuilder',
  'creative-team': 'todos'
};

/**
 * Checks if a given path includes the words 'upgrade' or 'setup'.
 * Used to determine if a user is trying to access a product's setup or upgrade page
 * which should only be accessible if the product is not setup.
 * @param {string} path - The path to check.
 * @returns {boolean} - True if the path includes 'upgrade' or 'setup', false otherwise.
 */
export const pathIncludesUpgradeOrSetup = path =>
  path.includes('upgrade') || path.includes('setup');

/**
 * Determines whether a user can view a product and handles redirects accordingly.
 *
 * @param {Object} product - The product to check visibility for.
 * @param {string} productPath - The path to the product.
 * @param {Object} [constants={}] - Constants object containing additional link paths.
 * @param {string} [constants.inactiveLink] - Optional path to redirect if product is inactive.
 *
 * @returns {boolean} - Returns true if the user can view the product, false otherwise.
 */
export const canViewProduct = (product, productPath, constants = {}) => {
  // If product is not found, redirect to home page
  if (!product) {
    Router.push(`/`);
    return false;
  }

  // If product is inactive, redirect to start page
  if (product.status === 'inactive') {
    // redirect to product's inactiveLink if it exists, otherwise redirect to generic start page
    const inactiveLink = constants.inactiveLink || `/start/${productPath}`;
    Router.push(inactiveLink);
    return false;
  }
  // If product is provisioning, redirect to status page
  if (product.status === 'provisioning') {
    Router.push(`/start/preparing/${productPath}`);
    return false;
  }

  // If product is maintenance, redirect to status page
  if (product.phase === 'maintenance') {
    Router.push(`/start/maintenance/${productPath}`);
    return false;
  }
  // If product is not visible, redirect to home page
  if (product.visible === false || product.visibleSetting === false) {
    Router.push(`/`);
    return false;
  }
  return true;
};

const VALID = 'valid';
const REDIRECTING = 'redirecting';

/**
 * Validates destination/current url against permissions,
 * product visibility settings, and more. Currently if a url is not
 * valid, validateUrl will always push a redirect automatically.
 *
 * Returns two possible strings:
 * "valid" - url is allowed, proceed
 * "redirecting" - url is not allowed, auto-redirecting, no action
 *                 needed by consumer
 *
 * @param {string} url
 * @param {array} products
 */
const validateUrl = (url, products) => {
  if (!url || url === '') {
    return VALID;
  }

  const urlSegments = url.split('/');
  const productPath = urlSegments[1].toLowerCase();
  // There are certain paths that are always allowed
  if (alwaysActivePaths.includes(productPath)) return VALID;

  // Always allow product settings pages, format: /PRODUCT/settings/...
  if (urlSegments[2] && urlSegments[2].toLowerCase() === 'settings') return VALID;

  const productDetails = products.find(product => product.name === productPath);

  // Get constants object from local product data file
  const productConstants = findObjectByProp(PRODUCT_CONSTANTS, 'key', productDetails?.name);

  if (!canViewProduct(productDetails, productPath, productConstants)) return REDIRECTING;

  // Check into the subProducts
  const subProductPaths = Object.keys(subProductMappings);
  if (productDetails.subProducts && urlSegments[2] && subProductPaths.includes(urlSegments[2])) {
    const subProductPath = urlSegments[2];
    if (subProductPath === 'setup') {
      return VALID;
    }
    const subProductKey = subProductMappings[subProductPath];
    const subProduct = productDetails.subProducts.find(product => product.name === subProductKey);
    const subProductConstants = findObjectByProp(PRODUCT_CONSTANTS, 'key', subProduct?.name);

    if (!canViewProduct(subProduct, subProductPath, subProductConstants)) return REDIRECTING;

    // Check subProduct Setup
    const subProductSetup =
      subProduct.setup && subProduct.setup.length > 0 && JSON.parse(subProduct.setup);
    // Product IS setup.
    if (subProductSetup && subProductSetup.isComplete) {
      // Redirect away from setup page if already setup
      if (urlSegments.at(-1) === 'setup' || urlSegments.at(-1) === 'upgrade') {
        urlSegments.pop();
        Router.push(urlSegments.join('/'));
        return REDIRECTING;
      }
      return VALID;
    }

    // Sub Product is NOT setup.
    if (!pathIncludesUpgradeOrSetup(url)) {
      // redirect to sub-product's setupLink if it exists, otherwise redirect to generic start page
      const setupLink = subProductConstants?.setupLink || `/${productPath}/${subProductPath}/setup`;
      Router.push(setupLink);
      return REDIRECTING;
    }
  }

  // Get setup details for product.
  const productSetup =
    productDetails.setup && productDetails.setup.length > 0 && JSON.parse(productDetails.setup);

  // Product IS setup.
  if (productSetup && productSetup.isComplete) {
    // Redirect away from setup page if already setup
    // Only for non Parent products
    if (!parentProducts.includes(productPath) && urlSegments.at(-1) === 'setup') {
      urlSegments.pop();
      Router.push(urlSegments.join('/'));
      return REDIRECTING;
    }
    return VALID;
  }

  // Product is NOT setup.
  if (!pathIncludesUpgradeOrSetup(url)) {
    // redirect to product's setupLink if it exists, otherwise redirect to generic start page
    const setupLink = productConstants?.setupLink || `/${productPath}/setup`;
    Router.push(setupLink);
    return REDIRECTING;
  }

  return VALID;
};

export default validateUrl;
