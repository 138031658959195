import theme from '@assets/theme';
import Head from 'next/head';
import PropTypes from 'prop-types';
import MinVisibleFadeOut from './MinVisibleFadeOut';
import SplashLogo from './SplashLogo';

const totalAnimateDuration = 900;

const SplashScreen = ({ visible }) => (
  <MinVisibleFadeOut
    visible={visible}
    minVisibleSeconds={totalAnimateDuration}
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 9999,
      width: '100%',
      height: '100vh'
    }}
  >
    <div
      id="initial-splash-screen"
      className="initial-splash-screen hide-me"
      style={{
        display: 'flex',
        width: '100%',
        height: '100vh',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#F9FBFC'
      }}
    >
      <Head>
        <title>Marketing 360&reg;</title>
      </Head>
      <style>{`
        body {
          margin: 0;
          padding: 0;
          background: #F9FBFC;
          overflow: hidden;
        }
        #simple-splash-logo {
          width: 350px !important;
          height: 68px !important;
          max-width: 100%;
        }
        .loader {
          display: block;
          width: 30px;
          height: 30px;
          border-top: 1px solid ${theme.colors.primary};
          border-radius: 30px;
          transform: rotate(0deg);
          opacity: 1;
          transition: all ${theme.transitionEffect};
          animation: spin 0.6s linear infinite;
        }
        .loader.hidden {
          opacity: 0;
          animation: spin 0.6s linear infinite, hideTemporarily 0.2s 1s linear forwards;
        }
        .too-slow {
          opacity: 0;
          margin-top: 10rem;
          margin-bottom: -10rem;
          animation: hideTemporarily 0.2s 15s linear forwards;
        }
        .too-slow button {
          border: 1px solid ${theme.borderColor};
          background: #fff;
          color: ${theme.colors.text};
          padding: 6px 15px;
          border-radius: ${theme.borderRadius};
          transition: all ${theme.transitionEffect};
          margin-left: 1rem;
          cursor: pointer;
        }
        .too-slow button:hover {
          background-color: #f5f5f5;
          border-color: #aaaaaa;
        }
        @keyframes spin {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(359deg);
          }
        }
        @keyframes hideTemporarily {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
      `}</style>
      <div
        className="initial-splash-logo-container"
        style={{
          width: '80%',
          maxWidth: '350px',
          marginBottom: '24px',
          lineHeight: '1',
          height: '68px'
        }}
      >
        <SplashLogo id="simple-splash-logo" />
      </div>
      <div className={`loader hidden`} />
      {/**
       * This english text is hard-coded (and not in i18n files) to help
       * decrease amount of JS needed for first rendering this initial
       * splash screen.
       */}
      <div className="too-slow">
        Taking longer than usual...
        <button type="button" onClick={() => window.location.reload()}>
          Retry
        </button>
      </div>
    </div>
  </MinVisibleFadeOut>
);

SplashScreen.propTypes = {
  visible: PropTypes.bool
};

export default SplashScreen;
