import productData from '@data/productsData';

const getCurrentProductKey = (path: string, useParentProductKey = false) => {
  if (!path) {
    return 'dashboard';
  }

  const segments = path.split('/');
  let product = segments[1];
  let subProduct: string | null | undefined = segments[2];

  // If segment missing, we're probably on the dashboard/homepage
  if (segments.length <= 1 || segments[1] === '') {
    product = 'dashboard';
  }

  // Start pages nest the product segment one level in the url
  if (segments[1] === 'start') {
    product = segments[segments.length - 1];
  }

  // Check if the subProduct exists inside the subProducts of the current product
  // @ts-expect-error product is based on the path, it's not really guarantted to be a "keyof typeof productData" — this is fine because we use optional chaining to check for subProducts
  const currentProduct = productData[product];
  if (currentProduct?.subProducts && !useParentProductKey) {
    subProduct = Object.keys(currentProduct.subProducts).find(
      key =>
        // subProduct is not reliably the same as the key but it will be found inside the link
        currentProduct.subProducts[key]?.link?.includes(subProduct)
    );
  } else {
    // If the current product does not have subProducts, or if useParentProductKey is true, set subProduct to null
    subProduct = null;
  }

  return subProduct || product;
};

export default getCurrentProductKey;
