import { isProduction } from '@utils/environment';
import { cdnUrl } from '@utils/global';

export const SIGNUP_FORM_EXTENTION = `/signup/form`;
export const STORAGE_KEY = 'm360signupToken';
export const TOP_RATED_LOCAL_URLS = [
  'https://www.topratedlocal.com/partners/sign-up-now/',
  'https://www.topratedlocal.com/partners/sign-up-today/'
];

export const BASE_M360_URL = isProduction
  ? 'https://app.marketing360.com'
  : 'https://stage.marketing360.com';

export const getAuthHeaders = accessToken => ({
  Authorization: `Bearer ${accessToken}`,
  Accept: 'application/json',
  'Content-Type': 'application/json'
});

export const buttonData = [
  {
    iconUrl: `${cdnUrl}/product-payments.svg`,
    translationKey: 'payments',
    value: 'Process Payments'
  },
  {
    iconUrl: `${cdnUrl}/product-website.svg`,
    translationKey: 'website',
    value: 'Help with designing a website'
  },
  {
    iconUrl: `${cdnUrl}/product-nurture.svg`,
    translationKey: 'content',
    value: 'Help with content marketing & SEO'
  },
  {
    iconUrl: `${cdnUrl}/product-social.svg`,
    translationKey: 'social',
    value: 'Help with social media marketing'
  },
  {
    iconUrl: `${cdnUrl}/product-ads.svg`,
    translationKey: 'ads',
    value: 'Help with digital advertising'
  },
  {
    iconUrl: `${cdnUrl}/shared-illustrations/icon-SupportRep-trimmed.svg`,
    translationKey: 'google',
    value: 'Help with Google Guaranteed'
  }
];
