import { CONFIG as envConfig } from '@utils/environment';
import { cdnUrl } from '@utils/global';

const VIDEO_SRC_URL = 'https://player.vimeo.com/video';
const { paymentsUrl, websitesUrl, shopAppUrl } = envConfig;

/*
  general note: apps/sections that appear in the third tier of the global navbar's apps dropdown
  are general-utility apps and not intended to be displayed in other product lists (e.g. Dashboard)
  so they are not included here: Files, Brand Profile, and Settings
*/

export const crm = {
  id: 2,
  key: 'crm',
  icon: `${cdnUrl}/product-crm.svg`,
  title: 'crm.title',
  description: 'crm.description',
  link: '/crm',
  inactiveLink: '/start/crm',
  setupLink: '/crm/overview',
  videoSrc: `${VIDEO_SRC_URL}/465536680`
};

export const scheduling = {
  id: 3,
  key: 'scheduling',
  icon: `${cdnUrl}/product-scheduling.svg`,
  title: 'scheduling.title',
  description: 'scheduling.description',
  link: '/scheduling',
  inactiveLink: '/start/scheduling',
  setupLink: '/scheduling/setup',
  videoSrc: `${VIDEO_SRC_URL}/465536647`
};

export const listings = {
  id: 6,
  key: 'listings',
  icon: `${cdnUrl}/product-listings.svg`,
  title: 'listings.title',
  description: 'listings.description',
  link: '/reputation/listings',
  inactiveLink: '/reputation/listings',
  setupLink: '/reputation/listings',
  videoSrc: `${VIDEO_SRC_URL}/465536615`
};

export const payments = {
  id: 20,
  key: 'payments',
  icon: `${cdnUrl}/product-payments.svg`,
  title: 'payments.title',
  description: 'payments.description',
  link: paymentsUrl,
  inactiveLink: paymentsUrl,
  shouldRouteExternally: true,
  setupLink: `${paymentsUrl}/setup`,
  videoSrc: `${VIDEO_SRC_URL}/465536724`
};

export const reputation = {
  id: 8,
  key: 'reputation',
  icon: `${cdnUrl}/product-reputation.svg`,
  title: 'reputation.title',
  description: 'reputation.description',
  link: '/reputation',
  inactiveLink: '/start/reputation',
  setupLink: '/reputation/setup',
  videoSrc: `${VIDEO_SRC_URL}/465536630`,
  resourceVideoSrc: 'https://www.youtube.com/watch?v=DAHugk40yyQ',
  resourceVideoImage:
    'https://static.mywebsites360.com/c7cc6c7a55d9438c8f49111c9b6bf109/i/cc55240c19b44f729d54b605867521ec/20/4SoifmQp45JMgBnHndfLg/reputation-video.png',
  subProducts: {
    // Reputation Plus: legacy service for businesses with Yext listings.
    // Never really shown in the UI but needs a permissionsKey here to allow for showing
    // Reputation on the dashboard, because of the way useCanViewProduct handles subProducts.
    // Per Nam Truong, this permission is the correct one to associate with this subProduct:
    reputationPlus: {
      key: 'reputationPlus',
      permissionsKey: 'reputation_updateLocationPriorities'
    }
  }
};

export const social = {
  id: 9,
  key: 'social',
  icon: `${cdnUrl}/product-social.svg`,
  title: 'social.title',
  description: 'social.description',
  link: '/social',
  inactiveLink: '/start/social',
  setupLink: '/social/setup',
  videoSrc: `${VIDEO_SRC_URL}/465536709`
};
export const content = {
  id: 10,
  key: 'content',
  icon: `${cdnUrl}/product-content.svg`,
  title: 'content.title',
  description: 'content.description',
  link: '/content',
  inactiveLink: '/start/content',
  setupLink: '/content/setup',
  videoSrc: `${VIDEO_SRC_URL}/465536534`
};

export const ads = {
  id: 11,
  key: 'ads',
  icon: `${cdnUrl}/product-ads.svg`,
  title: 'ads.title',
  description: 'ads.description',
  link: '/ads',
  inactiveLink: '/start/ads',
  setupLink: '/ads/setup',
  videoSrc: `${VIDEO_SRC_URL}/465536509`,
  permissionsKey: 'ui_ads_view',
  subProducts: {
    lsa: {
      id: 36,
      key: 'lsa',
      icon: `${cdnUrl}/product-ads.svg`,
      title: 'lsa.title',
      description: 'lsa.description',
      link: '/ads/local-service-ads',
      /**
       * NOTE: For MVP, the inactiveLink will never be called because the LSA product
       * will always be `status: 'active'` on the full-platform plan.
       * Once LSA moves to the new billing system, it will be defaulted to
       * `status: 'inactive'` and the inactiveLink will be used.
       */
      inactiveLink: '/ads/local-service-ads/upgrade',
      /**
       * TODO: For MVP, we are using the upgrade path as the setupLink. This is
       * because even though the LSA product is technically always "active",
       * PM wants users to see the "Upgrade Page" as a pseudo Step 1 of the setup flow.
       * Once LSA moves to the new billing system, we will need to update setupLink to
       * point to the actual setup flow.
       *
       * Note: The Upgrade Page's "Get Started" button will point users to /ads/local-service-ads/verification
       */
      setupLink: '/ads/local-service-ads/upgrade',
      videoSrc: null,
      permissionsKey: 'ui_lsaVerification_view'
    }
  }
};

export const intelligence = {
  id: 12,
  key: 'intelligence',
  icon: `${cdnUrl}/product-intelligence.svg`,
  title: 'intelligence.title',
  description: 'intelligence.description',
  link: '/intelligence',
  inactiveLink: '/start/intelligence',
  setupLink: '/intelligence/setup',
  videoSrc: `${VIDEO_SRC_URL}/465536676`
};

export const nurture = {
  id: 13,
  key: 'nurture',
  icon: `${cdnUrl}/product-nurture.svg`,
  title: 'nurture.title',
  description: 'nurture.description',
  link: '/nurture',
  inactiveLink: '/start/nurture',
  setupLink: '/nurture/setup',
  videoSrc: `${VIDEO_SRC_URL}/465536619`,
  subProducts: {
    smsMarketing: {
      id: 4,
      key: 'smsMarketing',
      permissionsKey: 'ui_sms_view',
      icon: `${cdnUrl}/product-nurture.svg`,
      title: 'smsMarketing.title',
      description: 'smsMarketing.description',
      link: '/nurture/text',
      inactiveLink: '/start/nurture',
      setupLink: '/nurture/text/setup'
    },
    emailMarketing: {
      id: 5,
      key: 'emailMarketing',
      permissionsKey: 'ui_em3_view',
      icon: `${cdnUrl}/product-nurture.svg`,
      title: 'emailMarketing.title',
      description: 'emailMarketing.description',
      link: '/nurture/email',
      inactiveLink: '/start/nurture',
      setupLink: '/nurture/email/setup'
    }
  }
};

export const website = {
  id: 16,
  key: 'website',
  icon: `${cdnUrl}/product-website.svg`,
  title: 'website.title',
  description: 'website.description',
  link: websitesUrl,
  inactiveLink: '/start/websites',
  shouldRouteExternally: (productStatus: string) => productStatus === 'active',
  setupLink: websitesUrl,
  videoSrc: `${VIDEO_SRC_URL}/465536706`
};

export const forms = {
  id: 17,
  key: 'forms',
  icon: `${cdnUrl}/product-forms.svg`,
  title: 'forms.title',
  description: 'forms.description',
  link: '/forms',
  inactiveLink: '/', // We probably need a sales page for Forms
  setupLink: '/forms/setup',
  videoSrc: `${VIDEO_SRC_URL}/465536580`
};

export const shopApp = {
  id: 22,
  key: 'shopApp',
  permissionsKey: 'ui_shop_view',
  icon: `${cdnUrl}/product-shopApp.svg`,
  title: 'shopApp.title',
  description: 'shopApp.description',
  link: shopAppUrl,
  inactiveLink: shopAppUrl,
  shouldRouteExternally: true,
  setupLink: `${shopAppUrl}/setup`,
  videoSrc: `${VIDEO_SRC_URL}/467840764`
};

export const pos = {
  id: 24,
  key: 'pos',
  icon: `${cdnUrl}/product-pos.svg`,
  title: 'pos.title',
  description: 'pos.description',
  link: '/pos',
  inactiveLink: '/start/pos',
  setupLink: '/pos',
  videoSrc: `${VIDEO_SRC_URL}/673762165`
};

export const productsSortOrder = [
  'reputation',
  'listings',
  'website',
  'forms',
  'crm',
  'nurture',
  'social',
  'shopApp',
  'scheduling',
  'payments',
  'pos',
  'content',
  'ads',
  'intelligence'
];

export default {
  payments,
  social,
  website,
  content,
  crm,
  ads,
  nurture,
  intelligence,
  listings,
  reputation,
  forms,
  pos,
  scheduling,
  shopApp
};
