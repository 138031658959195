import { apiRequest, generateDataOrParams, getApiEnvironment } from './apiConfig';

export const baseURL = isV2 =>
  `https://${getApiEnvironment()}.marketing360.com/api/users/${isV2 ? 'v2' : ''}`;

const coreMigrationBaseURL = (version = '1') =>
  `https://${getApiEnvironment()}.marketing360.com/api/core/v${version}`;

export const apiUsers = ({ url, method, payload, showEndpoint = false, isV2 = false }) => {
  const baseRequest = apiRequest(baseURL(isV2));
  const dataOrParams = generateDataOrParams(method);
  const options = {
    url,
    method,
    [dataOrParams]: payload
  };

  if (showEndpoint) {
    return {
      endpoint: `${baseURL(isV2)}/${baseRequest?.getUri(options)}`,
      makeRequest: () => baseRequest(options)
    };
  }

  return baseRequest(options);
};

export const apiUsersV2 = params => apiUsers({ ...params, isV2: true });

export const apiUsersDeleteRole = ({ url, payload }) => {
  const baseRequest = apiRequest(baseURL(false));
  const options = {
    url,
    method: 'DELETE',
    data: payload
  };
  return baseRequest(options);
};

// get role info

export const apiRolesRequest = ({ url, roleID, method, payload }) => {
  const baseRequest = apiRequest(baseURL(false));
  const dataOrParams = generateDataOrParams(method);
  let finalUrl = `/${url}`;
  if (roleID && url) {
    finalUrl = `users/${roleID}/${url}`;
  } else if (roleID && !url) {
    finalUrl = `/roles/${roleID}/permissions`;
  }
  const options = {
    url: finalUrl,
    method,
    [dataOrParams]: payload
  };

  return baseRequest(options);
};

export const apiTeams = ({ url, method, payload }) => {
  const baseRequest = apiRequest(baseURL(false));
  const dataOrParams = generateDataOrParams(method);
  const options = {
    url,
    method,
    [dataOrParams]: payload
  };
  return baseRequest(options);
};

export const apiCoreMigrationUsers = ({ url, method = 'get', payload = undefined }) => {
  const baseRequest = apiRequest(coreMigrationBaseURL());
  const dataOrParams = generateDataOrParams(method);
  const options = {
    url,
    method,
    [dataOrParams]: payload
  };
  return baseRequest(options);
};
