import get from './get';

/**
 * Create a Map from an array of objects. This can provide a regular object too.
 * @param {Any[]} items
 * @param {String|Number} keyPath see the documentation for key argument of get
 * @param {String|Number} valuePath see the documentation for key argument of get
 * @param {Boolean} [asObject = false] convert the instance of Map to a regular
 * object before returning it?
 * @return {Object}
 */
const toMap = (items, keyPath, valuePath, asObject = false) => {
  const map = items.reduce(
    (acc, item) => acc.set(get(item, keyPath, item), get(item, valuePath, item)),
    new Map()
  );
  if (asObject) {
    return Object.fromEntries(map.entries());
  }
  return map;
};

export default toMap;
