var f = function FormFieldGroup_FieldGroup(data) {
  this.update(data);
};

f.prototype.update = function(data) {
  this.parentUpdate(data);

  // FormFieldGroups of type "fieldGroup" can contains
  // child FormFieldGroups
  this.fields = this.fields.map(this.createNew);
};

f.prototype.payloadValue = function() {
  return _.reduce(
    this.fields,
    function(acc, field) {
      _.extend(acc, field.payloadStructure());
      return acc;
    },
    {}
  );
};

export default f;
