import useModalManager from '@hooks/useModalManager';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const ModalOptionsWrapper = ({
  title,
  width = 800,
  afterClose,
  closable = true,
  keyboard = true,
  maskClosable = true,
  destroyOnClose = true,
  promptOnClose,
  centered,
  className,
  onCloseQuery,
  children
}) => {
  const { closeModal, showModal, reloadOnClose, handleDestroy } = useModalManager();

  const handleCloseModal = () => {
    closeModal({ promptOnClose });
  };

  const handleAfterClose = () => {
    if (afterClose) return afterClose();
    if (reloadOnClose && onCloseQuery) {
      onCloseQuery();
    }
    // cleanup to ensure modal component is destroyed in the context
    handleDestroy(true);
  };

  return (
    <Modal
      className={className}
      title={title}
      style={!centered && { top: 20 }}
      centered={centered}
      open={showModal}
      onOk={handleCloseModal}
      onCancel={handleCloseModal}
      destroyOnClose={destroyOnClose}
      footer={null}
      width={width}
      afterClose={handleAfterClose}
      closable={closable}
      keyboard={keyboard}
      maskClosable={maskClosable}
    >
      {children}
    </Modal>
  );
};

ModalOptionsWrapper.propTypes = {
  afterClose: PropTypes.func,
  centered: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  closable: PropTypes.bool,
  destroyOnClose: PropTypes.bool,
  keyboard: PropTypes.bool,
  maskClosable: PropTypes.bool,
  onCloseQuery: PropTypes.func,
  promptOnClose: PropTypes.bool,
  title: PropTypes.node,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default React.memo(ModalOptionsWrapper);
