// eslint-disable-next-line import/no-cycle
import {
  ModalManagerContext,
  ModalManagerContextProps
} from '@context/ModalManagerContextProvider';
import { useContext } from 'react';

/**
 * Types
 */
type UseConfirmModalType = Pick<
  ModalManagerContextProps,
  'openConfirmModal' | 'closeConfirmModal' | 'showConfirmModal'
>;

/**
 *
 * Hook
 *
 */
const useConfirmModal = (): UseConfirmModalType => {
  const context = useContext(ModalManagerContext);

  if (!context) {
    throw new Error('useConfirmModal must be used within a ModalProvider');
  }

  // Destructure to only expose options allowed for confirmation modals
  const { openConfirmModal, closeConfirmModal, showConfirmModal } = context;
  return {
    openConfirmModal,
    closeConfirmModal,
    showConfirmModal
  };
};

export default useConfirmModal;
