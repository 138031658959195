const allContacts = new RegExp('^contact(/index/page/[0-9]+)?/?$');
const singleContact = new RegExp('^contact/view/contact/[0-9]+/?$');
const contactCreate = new RegExp('^contact/create/?$');
const editContactField = new RegExp('^contact/values/form/contact/[0-9]+/field/[a-zA-Z0-9_-]+/?$');
const editContactFieldGrid = new RegExp(
  '^contact/values/form/contact/[0-9]+/field/[a-zA-Z0-9]+/gridedit/1+/?$'
);
const editAllContactFields = new RegExp('^contact/values/form/contact/[0-9]+/?$');
const assignAllContacts = new RegExp('^contact/values/assign/field/[a-zA-Z0-9_-]+/?$');
const assignAllContactsForm = new RegExp('^contact/values/assign/form/field/[a-zA-Z0-9_-]+/?$');

const deleteContactField = new RegExp('^contact/fields/delete/field/[0-9]+/?$');
const contactFields = new RegExp('^contact/fields/?$');
const mergeFields = new RegExp('^contact/mergeForm/id1/[0-9]+/id2/[0-9]+/?$');

const settingsById = new RegExp('^contact/fields/settings/field/[0-9]+/?$');
const settingsByType = new RegExp('^contact/fields/settings/fieldtype/[a-zA-Z0-9_-]+/?$');
const settingsByIdAndType = new RegExp(
  '^contact/fields/settings/field/[0-9]+/fieldtype/[a-zA-Z0-9_-]+/?$'
);

const filters = new RegExp('^contact/filters/?$');

const filtersForm = new RegExp('^contact/filters/form/?$');
const existingFilterForm = new RegExp('^contact/filters/form/filter(/[0-9a-zA-Z]+)?/?$');

const filtersFormField = new RegExp(
  '^contact/filters/form/field/[a-zA-Z0-9_-]+/?(/filter/[0-9]+/?)?$'
);
const createContactForm = new RegExp('^contact/form/?$');

const contactSources = new RegExp('^contact/source/?$');
const contactSourcesCreate = new RegExp('^contact/source/create/?$');
const contactSourcesUpdate = new RegExp('^contact/source/update/id/[0-9]+/?$');
const contactSourcesDelete = new RegExp('^contact/source/delete/id/[0-9]+/?$');

const columnsSaveState = new RegExp('^contact/columns/saveState/?$');
const columns = new RegExp('^contact/columns/?$');

const associatedContacts = new RegExp('^contact/attach/view/contact/[0-9]+/?$');
const associatedContactsForm = new RegExp('^contact/attach/form/contact/[0-9]+/?$');

const sourceFieldForm = new RegExp('^contact/sourceFieldMapping/sourceform/id/[0-9]+/?$');
const sourceFieldFormByMapping = new RegExp('^contact/sourceFieldMapping/form/id/[0-9]+/?$');
const deleteSourceMapping = new RegExp('^contact/sourceFieldMapping/delete/id/[0-9]+/?$');
const deleteContact = new RegExp('^contact/delete/contact/[0-9]+/?$');
const deleteMultipleContacts = new RegExp('^contact/delete/?$');

const exportContact = new RegExp('^contact/export(/index/contact/[0-9]+)?/?$');

const importContactForm = new RegExp('^contact/import/file/?$');

const ftpImportForm = new RegExp('^contact/import/ftp/?$');
const ftpImportConfig = new RegExp('^contact/import/ftp/config/?$');
const ftpImportMap = new RegExp('^contact/import/ftp/map/id/[0-9]+?$');

const ftpImportFormSingle = new RegExp('^contact/import/ftp/id/[0-9]+?$');
const ftpImportConfigSingle = new RegExp('^contact/import/ftp/config/id/[0-9]+?$');

const ftpImportSettings = new RegExp('^contact/import/ftp/settings/id/[0-9]+?$');
const ftpImportCancel = new RegExp('^contact/import/ftp/cancel/id/[0-9]+?$');
const ftpTestImport = new RegExp('^contact/import/ftp/test/id/[0-9]+?$');
const ftpListImports = new RegExp('^contact/import/ftp/list/?$');

const crmRegex = {
  allContacts,
  singleContact,
  contactCreate,
  editContactField,
  editContactFieldGrid,
  editAllContactFields,
  deleteContactField,
  contactFields,
  settingsById,
  settingsByIdAndType,
  settingsByType,
  filters,
  filtersForm,
  filtersFormField,
  existingFilterForm,
  createContactForm,
  contactSources,
  contactSourcesCreate,
  contactSourcesUpdate,
  contactSourcesDelete,
  columnsSaveState,
  columns,
  associatedContacts,
  associatedContactsForm,
  sourceFieldForm,
  sourceFieldFormByMapping,
  deleteSourceMapping,
  deleteContact,
  deleteMultipleContacts,
  exportContact,
  importContactForm,
  mergeFields,
  ftpImportForm,
  ftpImportConfig,
  ftpImportMap,
  ftpImportSettings,
  ftpImportCancel,
  ftpTestImport,
  ftpListImports,
  ftpImportFormSingle,
  ftpImportConfigSingle,
  assignAllContactsForm,
  assignAllContacts
};

export default crmRegex;
