/**
 * Get the value at the specified key.
 */
const get = <T>(
  obj: Record<string, unknown> | unknown[],
  key?: string | number | (string | number)[] | null,
  fallback: T = undefined as T
): T => {
  if (key === undefined || key === null) return fallback;

  const path = Array.isArray(key) ? key : key.toString().split('.');
  let result: unknown = obj;

  for (let i = 0; i < path.length; i += 1) {
    const currentKey = path[i];

    if (result === undefined) return fallback;

    if (typeof result === 'object' && result !== null) {
      result = (result as Record<string | number, unknown>)[currentKey];
    } else {
      return fallback;
    }
  }

  return result === undefined ? fallback : (result as T);
};

export default get;
