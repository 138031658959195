import { NotificationsDispatchContext } from '@context/NotificationsContextProvider';
import { setGlobalVariable } from '@utils/globalVariables';
import { useTranslation } from 'next-i18next';
import { useCallback, useContext, useEffect, useState } from 'react';

const disableClientSideRouting = () => {
  setGlobalVariable('clientSideRoutingDisabled', true);
};

// Milliseconds x Seconds x Minutes = Minutes
const updateCheckInterval = 1000 * 60 * 15;

/**
 * Periodically checks server-side `meta.json` to verify server-version
 * and client-version match. Otherwise we'll make all links refresh the
 * page and put up a notification asking the user to reload.
 */
const useVersionCheck = () => {
  const { t } = useTranslation('common');
  const notificationDispatch = useContext(NotificationsDispatchContext);

  // Next v12 + webpack restrictions dictate that destructuring can't be done here
  const clientVersion = process.env.VERSION;
  const clientBuildId = process.env.BUILD_ID;

  const [serverVersion, setServerVersion] = useState(clientVersion);
  const [serverBuildId, setServerBuildId] = useState(clientBuildId);
  const [error, setError] = useState(false);

  const [userNotified, setUserNotified] = useState(false);

  const sendToastNotification = useCallback(() => {
    if (!userNotified) {
      // This notification is not dismissible. Only reloading the
      // page will clear it.
      notificationDispatch({
        type: 'ADD',
        data: {
          id: +new Date(), // current time in milliseconds
          type: 'toast',
          message: t('version-check.update-available-message'),
          context: 'info',
          action: () => window.location.reload(true),
          actionTitle: t('version-check.reload-button'),
          duration: 0, // Value of zero disables notification timeout
          __hideToastFromDrawer: true
        }
      });

      // Only send notification once.
      setUserNotified(true);
    }
  }, [notificationDispatch, t, userNotified]);

  // const sendModal = () => {};

  // Periodically fetch `meta.json`, then stop fetching once an
  // available update is detected.
  useEffect(() => {
    let intervalCleared = false;

    const fetchServerVersion = setInterval(async () => {
      // Stop checking if user already notified.
      if (userNotified) {
        clearInterval(fetchServerVersion);
        intervalCleared = true;
      } else {
        // Get latest version and build id from server.
        try {
          const response = await fetch('/meta.json', { cache: 'no-store' });
          const json = await response.json();
          setServerVersion(json.version);
          setServerBuildId(json.build_id);
        } catch (error) {
          setError(error);
        }
      }
    }, updateCheckInterval);

    return () => {
      if (!intervalCleared) {
        clearInterval(fetchServerVersion);
      }
    };
  }, [userNotified]);

  // Rerun comparisons anytime data from server updated
  useEffect(() => {
    if (clientBuildId !== serverBuildId) {
      disableClientSideRouting();
      sendToastNotification();
    }

    // if (clientVersion !== serverVersion) {
    /**
     * TODO: Add more granular checks based on version number.
     *  - If PATCH/PRERELEASE, disable client-side routing
     *  - If MINOR, disable routing, put up notification asking for refresh
     *  - If MAJOR, disable routing, put up non-closable modal with countdown
     *    for refresh
     *
     * Versioning: 3.0.4-5 === MAJOR.MINOR.PATCH-PRERELEASE
     *
     * V3 Front doesn't currently follow semantic versioning though,
     * so we prompt the MINOR type release anytime the Build Id changes.
     */
    // }
  }, [clientBuildId, sendToastNotification, serverBuildId]);

  return { clientVersion, clientBuildId, serverVersion, serverBuildId, error };
};

export default useVersionCheck;
