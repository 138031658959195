/* eslint-disable @typescript-eslint/ban-ts-comment */
// eslint-disable-next-line import/no-cycle
import useConfirmModal from '@hooks/useConfirmModal';
import useModalManager from '@hooks/useModalManager';
import { Button, ButtonPrimary, ConfirmationModal } from '@madwire/m360ui';
import { useTranslation } from 'next-i18next';

/**
 * Wrapper for m360ui Confirmation Modal for confirming closing a modal.
 * Are you sure you want to close?
 * All changes will be lost.
 * Cancel Btn, Close Btn
 */
const ConfirmCloseConfirmationModal = (): JSX.Element => {
  const { t } = useTranslation();
  const { closeModal } = useModalManager();
  const { showConfirmModal, closeConfirmModal } = useConfirmModal();

  const handleConfirmAndClose = () => {
    closeConfirmModal();
    closeModal();
  };

  return (
    <ConfirmationModal
      onClose={closeConfirmModal}
      show={showConfirmModal}
      title={t('messages.confirm.close')}
      footer={
        <>
          {/* @ts-ignore */}
          <Button mx={{ mr: 5 }} onClick={closeConfirmModal}>
            {t('actions.cancel')}
          </Button>
          {/* @ts-ignore */}
          <ButtonPrimary onClick={handleConfirmAndClose}>{t('actions.close')}</ButtonPrimary>
        </>
      }
    >
      {t('messages.confirm.lost-changes')}
    </ConfirmationModal>
  );
};

export default ConfirmCloseConfirmationModal;
