/* eslint-disable @typescript-eslint/ban-ts-comment */
import { resetApiCache } from '@api/apiConfig';
import { apiUsersV2 } from '@api/users';
import NavBarWrapper from '@components/layout/NavBarWrapper';
import Notifications from '@components/layout/notifications/Notifications';
import VerifyEmailModal from '@components/layout/VerifyEmailModal';
import AnimatedAccountSwitch from '@components/transitions/AnimatedAccountSwitch';
import { GlobalDispatchContext, GlobalStateContext } from '@context/GlobalContextProvider';
import {
  NotificationsDispatchContext,
  NotificationsStateContext
} from '@context/NotificationsContextProvider';
import useAccountSwitchRedirect from '@hooks/useAccountSwitchRedirect';
import useEmailVerify from '@hooks/useEmailVerify';
import useMobileHideOnScroll from '@hooks/useMobileHideOnScroll';
import useModalManager from '@hooks/useModalManager';
import useVersionCheck from '@hooks/useVersionCheck';
import M360NavigationBar, {
  AuthProps,
  NavBarActionTypes,
  NavBarDispatchAction
} from '@madwire/m360-navigation-bar';
import { AlertError } from '@madwire/m360ui';
import { useSegment } from '@madwire/segment-js';
import { captureException } from '@sentry/nextjs';
import { Account } from '@typedefs/Account';
import { ErrorMessage } from '@typedefs/Api';
import { setAccountNumber } from '@utils/auth';
import getCurrentProductKey from '@utils/defaultPage/getCurrentProductKey';
import validateUrl from '@utils/defaultPage/validateUrl';
import { CONFIG, ENV } from '@utils/environment';
import { recordUserDetails } from '@utils/externalScripts';
import { getSegmentWriteKey } from '@utils/global';
import { setGlobalVariable } from '@utils/globalVariables';
import { useTranslation } from 'next-i18next';
import Router from 'next/router';
import { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';

interface Props {
  hideLoader: () => void;
  authProps: AuthProps;
}

/**
 * GlobalWrapper
 * Handles app init after taking authProps from the AuthWrapper
 */
const GlobalWrapper: React.FC<PropsWithChildren<Props>> = ({ children, hideLoader, authProps }) => {
  const { openModal } = useModalManager();
  const notificationsDispatch = useContext(NotificationsDispatchContext);
  const notificationsState = useContext(NotificationsStateContext);
  const globalDispatch = useContext(GlobalDispatchContext);
  const globalState = useContext(GlobalStateContext);

  // Checks server build/version for updated versions, will push a notification
  // and disabled client-side routing if new version detected.
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const versions = useVersionCheck();
  const { hide: scrollingHideNav } = useMobileHideOnScroll();

  const { account: globalAccount, user, permissions, accountSwitch } = globalState;
  const { inProgress: switchingAccounts, oldAccount, newAccount } = accountSwitch;
  useAccountSwitchRedirect();

  const { t } = useTranslation('common');

  const [error, setError] = useState<ErrorMessage | string | null>(null);

  const { loaded: notificationsLoaded, toasts, drawerVisible } = notificationsState;
  const notificationCount = notificationsLoaded && toasts.length > 0 ? toasts.length : 0;

  const { pathname } = Router;

  // Generate name for displaying current active product in app bar
  const getCurrentProduct = useCallback(
    path => {
      // pass true as 2nd argument because the nav bar product name
      // should reflect top-level products, not subProducts (sc-63104)
      const product = getCurrentProductKey(path, true);
      return t(`products.${product}.title`);
    },
    [t]
  );

  const getLegacyPermissions = async (userId: string, accountNumber: string) =>
    /**
     * This can/should be removed once all frontend products have been refactored
     * to use the new permissions keys. This is only for backwards compatibility.
     */
    apiUsersV2({
      url: '/permissions/forContext',
      method: 'get',
      payload: {
        userId,
        accountNumber,
        contextType: 'account',
        display: false
      }
    });

  // The events dispatched from the navbar fall into two categories,
  // data and event contexts. Some need to be pushed into state and
  // the others relate to programmatic functionality to be executed.
  const navBarDispatch = async (action: NavBarDispatchAction) => {
    switch (action.type) {
      case NavBarActionTypes.ProductsReloadSuccess: {
        globalDispatch(action);
        break;
      }
      case NavBarActionTypes.AccountChange: {
        // Don't change accounts if the selected account matches the current account
        if (globalAccount.accountNumber === action.payload.account.accountNumber) {
          break;
        }
        setAccountNumber(action.payload.account.accountNumber);
        globalDispatch({
          type: 'INITIAL_ACCOUNT_SWITCH',
          newAccount: action.payload.account
        });
        break;
      }
      case NavBarActionTypes.AccountRefetchSuccess: {
        resetApiCache();
        try {
          const legacyPermissions = await getLegacyPermissions(
            authProps.profile.user_id,
            action.payload.account.accountNumber
          );
          // Since account updated, make another recordUserDetails call to update 3rd-party services:
          recordUserDetails(
            authProps.profile.user_id,
            authProps.profile,
            action.payload.account as Account
          );
          globalDispatch({
            type: 'FINALIZE_ACCOUNT_SWITCH',
            payload: {
              ...action.payload,
              permissions: {
                newPermissions: action.payload.permissions,
                // @ts-ignore
                legacyPermissions: legacyPermissions?.data?.response
              }
            }
          });
        } catch (err) {
          setError(err as ErrorMessage);
        }
        break;
      }
      case NavBarActionTypes.RouteChange: {
        const { route } = action.payload;
        const urlValidStatus = validateUrl(route, globalAccount.products);
        if (urlValidStatus === 'valid') {
          // If pushing to notifications URL, open drawer instead.
          if (route.includes('/notifications')) {
            notificationsDispatch({ type: 'DRAWER', visible: !drawerVisible });
          } else {
            let url = route;
            if (url[0] !== '/') {
              url = new URL(route).pathname;
            }
            Router.push(url);
            globalDispatch({ type: 'SET_CURRENT_PRODUCT', payload: getCurrentProduct(url) });
          }
        }
        break;
      }
      case NavBarActionTypes.HamburgerClick: {
        globalDispatch({ type: 'TOGGLE_MOBILE_DRAWER', open: true });
        break;
      }
      case NavBarActionTypes.DismissNagBar: {
        globalDispatch({ type: 'DISMISS_NAG' });
        break;
      }
      case NavBarActionTypes.Error: {
        captureException(`navBarDispatch error: ${JSON.stringify(action)}`);
        break;
      }
      default: {
        captureException(`unknown navBarDispatch: ${JSON.stringify(action)}`);
        break;
      }
    }
  };

  // MAIN APP INIT
  useEffect(() => {
    const init = async () => {
      try {
        // bail out if redirecting to Backoffice
        // (profile & some other authProps data won't be available)
        if (
          !authProps.profile ||
          !authProps.currentAccount ||
          !authProps.accounts ||
          !authProps.permissions
        ) {
          return;
        }
        // Send data and helpers to global scope
        setAccountNumber(authProps.currentAccount.accountNumber);
        setGlobalVariable('api', authProps.session.api); // This gives us: clearCache, extend, request
        setGlobalVariable('logout', authProps.session.logout); // Allow any action to log out user
        setGlobalVariable('getAccessToken', authProps.session.getAccessToken); // Allows access to the token with automatic refreshing as-needed
        // Push info to various third-party services:
        recordUserDetails(
          authProps.profile.user_id,
          authProps.profile,
          // safe cast; authProps should always send a complete Account:
          authProps.currentAccount as Account
        );
        const legacyPermissions = await getLegacyPermissions(
          authProps.profile.user_id,
          authProps.currentAccount.accountNumber
        );
        globalDispatch({
          type: 'INIT',
          user: { ...authProps.profile, id: authProps.profile.user_id },
          env: CONFIG,
          accounts: authProps.accounts,
          currentAccount: authProps.currentAccount,
          permissions: {
            newPermissions: authProps.permissions,
            // @ts-ignore
            legacyPermissions: legacyPermissions?.data?.response
          },
          currentProduct: getCurrentProduct(Router.pathname)
        });
        // Hide loader
        hideLoader();
      } catch (err) {
        setError(err as ErrorMessage);
      }
    };
    init();
    // initial mount only, deliberate empty dependency array:
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Hide hamburger on dashboard/pages that don't have sub-nav
  // Needs improvement. Render is based on keys in locales/en/common.json
  const showHamburger = useMemo(() => {
    // Hide hamburger on all start/sales pages
    if (pathname.includes('start/')) {
      return false;
    }
    // ONLY show hamburger if `mobileNavTitle` key present in i18n
    if (
      t([`products.${getCurrentProductKey(pathname)}.mobileNavTitle`, 'no-mobile-nav']) !==
      'no-mobile-nav'
    ) {
      return true;
    }
    // In all other edge-cases, hide the icon
    return false;
  }, [t, pathname]);

  // If (1) we're switching accounts OR (2) triggering an account refetch
  // via global context, add values into account object to trigger NavBar
  // to refresh its account data from the API.
  const navBarAccount = useMemo(() => {
    let acct = null;
    if (newAccount.accountNumber && newAccount.accountNumber !== globalAccount.accountNumber) {
      acct = {
        ...newAccount
      };
    } else if (globalAccount && globalAccount.refetch) {
      acct = { ...globalAccount, productListDirty: true };
    } else if (globalAccount) {
      acct = globalAccount;
    } else {
      acct = authProps.currentAccount;
    }
    return acct;
  }, [globalAccount, newAccount, authProps.currentAccount]);

  // If user hasn't verified email and account should be locked, show modal
  const { nonVerifiedEmail, shouldShowEmailVerifyModal, resendEmail } = useEmailVerify();
  useEffect(() => {
    if (shouldShowEmailVerifyModal) {
      openModal(<VerifyEmailModal email={nonVerifiedEmail?.email} resendEmail={resendEmail} />);
    }
  }, [nonVerifiedEmail?.email, openModal, resendEmail, shouldShowEmailVerifyModal]);

  // Load Segment
  useSegment(getSegmentWriteKey(ENV));

  if (error) {
    return (
      <div className="u-flex-center" style={{ justifyContent: 'center', height: '100vh' }}>
        <AlertError
          title={t('messages.error.login.title')}
          message={t('messages.error.login.description')}
        />
      </div>
    );
  }

  // Needed for the navbar to avoid issues on pages that use dynamic routing.
  const currentLiveUrl = new URL(window.location.href);

  return (
    <div className={scrollingHideNav ? 'header-hidden' : 'header-visible'}>
      <AnimatedAccountSwitch
        visible={switchingAccounts}
        account={oldAccount}
        newAccount={newAccount}
      />
      <Notifications />
      <NavBarWrapper>
        <M360NavigationBar
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...authProps}
          env={CONFIG}
          dispatch={navBarDispatch}
          currentAccount={navBarAccount}
          currentApp={getCurrentProduct(currentLiveUrl.pathname)}
          notificationsCount={notificationCount}
          pathname={pathname}
          permissions={permissions}
          profile={user}
          showHamburger={showHamburger}
        />
      </NavBarWrapper>
      {!switchingAccounts && children}
    </div>
  );
};

export default GlobalWrapper;
