import theme from '@assets/theme';
import { motion } from 'framer-motion';
import Head from 'next/head';
import PropTypes from 'prop-types';
import React from 'react';
import MinVisibleFadeOut from './MinVisibleFadeOut';

const totalAnimateDuration = 1000;

const businessIcon = (
  <svg
    height="125"
    viewBox="0 0 24 24"
    width="125"
    xmlns="http://www.w3.org/2000/svg"
    style={{ zIndex: 1 }}
  >
    <path d="m0 0h24v24h-24z" fill="none" />
    <g fill="currentColor">
      <path d="m18.36 9 .6 3h-13.92l.6-3zm1.64-2h-16l-1 5v2h1v6h10v-6h4v6h2v-6h1v-2zm-14 11v-4h6v4z" />
      <path d="m4 4h16v2h-16z" />
    </g>
  </svg>
);

const xMoveLength = 250;
const yBrandSquish = 35;
const brandTiming = { delay: 0.3, ease: [0.77, 0, 0.175, 1], duration: 0.5 };

// True === entering
const busIconAnim = {
  container: {
    initial: {
      true: { color: theme.colors.primary, y: yBrandSquish },
      false: { color: '#fff', y: 0 }
    },
    animate: {
      true: {
        color: [theme.colors.primary, theme.colors.primary, '#fff'],
        y: [yBrandSquish, yBrandSquish, 0]
      },
      false: {
        color: ['#fff', theme.colors.primary, theme.colors.primary],
        y: [0, yBrandSquish, yBrandSquish]
      }
    }
  },
  background: {
    initial: {
      true: { opacity: 0 },
      false: { opacity: 1 }
    },
    animate: {
      true: { opacity: [0, 0, 1] },
      false: { opacity: [1, 0, 0] }
    }
  }
};
const businessIconContainer = (key, entering = false) => (
  <motion.div
    key={`${key}-container`}
    initial={busIconAnim.container.initial[entering]}
    animate={busIconAnim.container.animate[entering]}
    transition={brandTiming}
    className="brand-logo"
  >
    <motion.div
      key={`${key}-background`}
      className="brand-logo-background"
      initial={busIconAnim.background.initial[entering]}
      animate={busIconAnim.background.animate[entering]}
      transition={brandTiming}
    />
    {businessIcon}
  </motion.div>
);

// True === entering
const busLabelAnim = {
  label: {
    initial: {
      true: { y: -yBrandSquish },
      false: { y: 0 }
    },
    animate: {
      true: { y: [-yBrandSquish, -yBrandSquish, 0] },
      false: { y: [0, -yBrandSquish, -yBrandSquish] }
    }
  },
  name: {
    initial: {
      true: { color: theme.colors.text },
      false: { color: theme.colors.primary }
    },
    animate: {
      true: { color: [theme.colors.text, theme.colors.text, theme.colors.primary] },
      false: { color: [theme.colors.primary, theme.colors.primary, theme.colors.text] }
    }
  }
};
const businessLabelsContainer = (key, accountNumber, accountName, entering = false) => (
  <motion.div
    key={`${key}-brand-labels`}
    initial={busLabelAnim.label.initial[entering]}
    animate={busLabelAnim.label.animate[entering]}
    transition={brandTiming}
  >
    <div className="u-mt-4 u-text-center u-font-size-md brand-number">{accountNumber}</div>
    <motion.div
      key={`${key}-brand-name`}
      className="u-font-weight-semi u-font-size-xl u-font-weight-semi brand-name"
      initial={busLabelAnim.name.initial[entering]}
      animate={busLabelAnim.name.animate[entering]}
      transition={brandTiming}
    >
      {accountName}
    </motion.div>
  </motion.div>
);

const AnimatedAccountSwitch = ({ visible, account, newAccount }) => (
  <MinVisibleFadeOut
    visible={visible}
    minVisibleSeconds={totalAnimateDuration}
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 9999,
      width: '100%',
      height: '100vh'
    }}
  >
    <div key="animation-exit-overlay" className="animation-exit-overlay u-text-center">
      <Head>
        <title>Marketing 360&reg;</title>
      </Head>

      {/* --- Swiping background --- */}
      <motion.div
        key="le-overlay"
        className="animation-exit"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ ease: 'easeOut', duration: 0.3 }}
      />

      {/* --- Main Content --- */}
      <motion.div
        key="le-overlay-content"
        className="animation-exit-content"
        initial={{ opacity: 0, y: -25 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ ease: 'easeOut', duration: 0.3 }}
      >
        {/* --- Old account to hide --- */}
        <motion.div
          key="old-account"
          className="brand"
          initial={{ opacity: 1, x: 0, scale: 1 }}
          animate={{
            opacity: [1, 0.75, 0.3],
            x: [0, 0, xMoveLength],
            scale: [1, 0.65, 0.65]
          }}
          transition={brandTiming}
        >
          {businessIconContainer('old-account')}
          {businessLabelsContainer(
            'old-account',
            account.externalAccountNumber,
            account.displayName
          )}
        </motion.div>

        {/* --- New account to show --- */}
        <motion.div
          key="new-account"
          className="brand"
          initial={{ opacity: 0.3, x: -xMoveLength, scale: 0.65 }}
          animate={{
            opacity: 1,
            x: [-xMoveLength, -xMoveLength, 0],
            scale: [0.65, 0.65, 1]
          }}
          transition={brandTiming}
        >
          {businessIconContainer('new-account', true)}
          {businessLabelsContainer(
            'new-account',
            newAccount.externalAccountNumber,
            newAccount.displayName,
            true
          )}
        </motion.div>
      </motion.div>
      <style jsx>
        {`
          .animation-exit-overlay {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 9999;
            width: 100%;
            height: 100%;
            pointer-events: none;
            overflow: hidden;
          }
          .animation-exit-overlay :global(.animation-exit) {
            display: block;
            width: 100vw;
            height: 100vh;
            background: #f9fbfc;
          }
          .animation-exit-overlay :global(.animation-exit-content) {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 0;
            height: 0;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .animation-exit-overlay :global(.brand) {
            position: absolute;
            z-index: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: ${theme.colors.text};
          }
          .animation-exit-overlay :global(.brand-logo) {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            width: 190px;
            height: 190px;
            padding: 6px;
            color: #fff;
            margin-bottom: 18px;
          }
          .animation-exit-overlay :global(.brand-logo .brand-logo-background) {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: ${theme.colors.primary};
            background: linear-gradient(
              45deg,
              ${theme.colors.primary},
              ${theme.colors.primaryLighter}
            );
            box-shadow: 0 0 0 18px #e5f0fa;
          }
          .brand-logo img {
            border-radius: 50%;
            object-fit: cover;
            height: 100%;
            width: 100%;
          }
          .animation-exit-overlay :global(.brand-name) {
            line-height: 1.5rem;
          }
          .brand-number {
          }
          .animation-exit-overlay :global(.new-account-wave) {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 0;
            width: 190px;
            height: 190px;
            margin-left: -95px;
            margin-top: calc(-95px - 3rem);
            background: #e5f0fa;
            border-radius: 100%;
          }
        `}
      </style>
    </div>
  </MinVisibleFadeOut>
);

AnimatedAccountSwitch.propTypes = {
  visible: PropTypes.bool,
  account: PropTypes.object,
  newAccount: PropTypes.object
};

export default React.memo(AnimatedAccountSwitch);
