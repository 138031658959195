var f = function FormFieldGroup_Checkboxes(data) {
  this.update(data);
};

f.prototype.update = function(data) {
  this.parentUpdate(data);
};

f.prototype.payloadValue = function() {
  // Dropdowns with selected value un-truthy
  return this.options.reduce(function(acc, option) {
    if (option.selected) {
      acc.push(option.value);
    }

    return acc;
  }, []);
};

f.prototype.singleOptionChecked = function() {
  return this.options[0].selected;
};

export default f;
