import moment from 'moment';

// Stripe uses date timestamps in seconds, not JS milliseconds.
// The Payments project's global date-handling standards were to format
// as MMM D with year only displayed if it's not the current year

export const filterStripeDate = (timestamp, withTime = false) => {
  const jsTicks = timestamp * 1000;
  const date = moment(jsTicks);
  const thisYear = new Date().getFullYear();

  if (!timestamp || !date.isValid()) {
    return '';
  }

  let format = date.year() === thisYear ? 'MMM D' : 'MMM D, YYYY';
  if (withTime) format += ', hh:mm A';

  return date.format(format);
};
