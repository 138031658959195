// radio buttons will have the selected value put on the
// field group, e.g. fieldGroup.selected = value
//
// checkbox will have the selected value put on themseleves, e.g.
// checkbox.selected = true|false

// This object is name in an unfortunate way.  It would have probably been
// better named FormFieldElement.  So then we could have FormFieldElements inside
// of FormField's - But that's not how it works.  So instead, we have FormFields
// inside of FormFieldGroups.
function FormField(option) {
  // The parent "FormFieldGroup"
  var group = option.getGroup();

  if (!group) {
    throw new Error('FormFields must have function "getGroup" that returns type "FormFieldGroup"!');
  }

  Object.assign(this, option);

  if (!('disabled' in this)) {
    this.disabled = false;
  }

  var that = this;

  if (group.isCheckbox()) {
    // Checkbox FormFieldGroups will have the "selected" property set to
    // true on each of their options that are selected.
    this.inputType = 'checkbox';

    this.selected = group.defaultValues.some(function(value) {
      return value == that.value;
    });
  } else if (group.isColorCheckbox()) {
    this.inputType = 'colorCheckbox';

    this.selected = group.defaultValues.some(function(value) {
      return value == that.value;
    });
  } else if (group.isCheckboxDropdown()) {
    this.inputType = 'checkboxDropdown';
    this.selected = group.defaultValues.some(function(value) {
      return value == that.value;
    });
  } else if (group.isRadio()) {
    this.inputType = 'radio';

    // Radio FormFieldGroups with a the "selected" property set on
    // themselves.  The value will be chosen radio option
    if (group.selected == null) {
      if (group.defaultValues[0] == that.value) {
        group.selected = that.value;
      }
    }
  } else if (group.isDropdown()) {
    if (group.selected == null) {
      if (group.defaultValues[0] == that.value) {
        group.selected = that.value;
      }
      // else {
      //     group.selected = "";
      // }
    }
  } else if (group.isInputList()) {
    // Do nothing right now
  } else if (group.isTagList()) {
    // Do nothing right now
  } else if (group.isColorTag()) {
    // Do nothing right now
  } else if (group.isLongText() || group.isText()) {
    // Do nothing right now
  } else if (group.isEmail()) {
  } else if (group.isPhone()) {
  } else if (group.isFilter()) {
    // Do nothing right now
  } else if (group.isListRadio()) {
    this.inputType = 'crmListRadios';

    if (group.selected == null) {
      if (group.defaultValues.value == that.value.value) {
        group.selected = that.value.value;
      }
    }
    if (group.child == null) {
      if (group.defaultValues.child) {
        group.child = group.defaultValues.child;
      }
    }
    if (group.defaultValues.dateRange[0]) {
      group.dateRange = [];
      group.dateRange[0] = group.defaultValues.dateRange[0];
      group.dateRange[1] = group.defaultValues.dateRange[1];
    }
    if (group.defaultValues.dayRange[0]) {
      group.dayRange = [];
      group.dayRange[0] = group.defaultValues.dayRange[0];
      group.dayRange[1] = group.defaultValues.dayRange[1];
    }
  } else if (group.isListCheckbox()) {
    this.inputType = 'crmListCheckboxes';

    if (!Array.isArray(group.defaultValues.value)) {
      group.defaultValues.value = [group.defaultValues.value];
    }
    this.selected = group.defaultValues.value.some(function(value) {
      return value == that.value.value;
    });

    if (group.child == null) {
      if (group.defaultValues.child) {
        group.child = group.defaultValues.child;
      }
    }
    if (group.defaultValues.dateRange[0]) {
      group.dateRange = [];
      group.dateRange[0] = group.defaultValues.dateRange[0];
      group.dateRange[1] = group.defaultValues.dateRange[1];
    }
    if (group.defaultValues.dayRange[0]) {
      group.dayRange = [];
      group.dayRange[0] = group.defaultValues.dayRange[0];
      group.dayRange[1] = group.defaultValues.dayRange[1];
    }
  } else if (group.isFtpMap()) {
    // Do nothing for now
  } else {
    var message =
      'API data crmRoleFields.type must be "checkboxes", "crmListRadios", "radios", "dropdown", ';
    message +=
      '"longText", "colorTag", "inputList", or "text"! crmRoleFields.type set to ' +
      group.type +
      '!';
    throw new Error(message);
  }
}

FormField.prototype.getType = function() {
  return this.type;
};

FormField.prototype.isMarkup = function() {
  return this.getGroup.isMarkup();
};

FormField.prototype.isRadio = function() {
  return this.getGroup.isRadio();
};

FormField.prototype.isDropdown = function() {
  return this.getGroup.isDropdown();
};

FormField.prototype.isLongText = function() {
  return this.getGroup.isLongText();
};

FormField.prototype.isShortText = function() {
  return this.getGroup.isText();
};

FormField.prototype.isText = function() {
  return this.getGroup.isText();
};

FormField.prototype.isEmail = function() {
  return this.getGroup.isEmail();
};

FormField.prototype.isPhone = function() {
  return this.getGroup.isPhone();
};

FormField.prototype.isCheckbox = function() {
  return this.getGroup.isCheckbox();
};

FormField.prototype.isColorCheckbox = function() {
  return this.getGroup.isColorCheckbox();
};

FormField.prototype.isCheckboxDropdown = function() {
  return this.getGroup.isCheckboxDropdown();
};

FormField.prototype.isInputList = function() {
  return this.getGroup.isInputList();
};

FormField.prototype.isTagList = function() {
  return this.getGroup.isTagList();
};

FormField.prototype.isFilter = function() {
  return this.getGroup.isFilter();
};

FormField.prototype.isListRadio = function() {
  return this.getGroup.isListRadio();
};

FormField.prototype.isListCheckbox = function() {
  return this.getGroup.isListCheckbox();
};

FormField.prototype.isFtpMap = function() {
  return this.getGroup.isFtpMap();
};

FormField.createNew = function(data) {
  return new FormField(data);
};

export default FormField;
