import { getLocale } from './getLocale';
import { getSeparator } from './getSeparator';

// Get number of decimal places in a given number
export const getDecimalPlaces = (num = 0, locale = getLocale()) => {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(num) || num === null) {
    throw new Error('The first argument in getDecimalPlaces should be a number');
  }
  const separator = getSeparator(locale, 'decimal');
  const localNumString = num.toLocaleString(locale);
  const splitNum = localNumString.split(separator);
  if (splitNum.length === 2) {
    return splitNum[1].length;
  }
  return 0;
};
