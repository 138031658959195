import moment from 'moment';

export const filterDate = (date: string, format = 'MM/DD/YYYY'): string => {
  const thisDate = moment(date);
  return thisDate.isValid() ? thisDate.format(format) : '';
};

export const filterDateRangeWithYear = (startDate: string, endDate: string): string => {
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);

  const sameYear = startMoment.year() === endMoment.year();

  const startFormat = sameYear ? 'MMM. DD' : 'MMM. DD, YYYY';
  const endFormat = 'MMM. DD, YYYY';

  const startDateFormatted = filterDate(startDate, startFormat);
  const endDateFormatted = filterDate(endDate, endFormat);

  return `${startDateFormatted} - ${endDateFormatted}`;
};
