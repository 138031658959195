// Returns client locale or default (en-US).
export const DEFAULT_LOCALE = 'en-US';
/**
 * If the browser has a language preference, return the first one, otherwise return
 * the default locale
 * @note: Must invoke this function inside of a useEffect hook, otherwise `navigator` may be undefined.
 */
export const getLocale = () =>
  navigator && navigator?.languages && navigator?.languages.length
    ? navigator.languages[0]
    : DEFAULT_LOCALE;
