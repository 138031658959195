var f = function FormFieldGroup_Filter(data) {
  this.update(data);
};

f.prototype.update = function(data) {
  this.parentUpdate(data);
};

f.prototype.payloadValue = function() {
  var values = {};

  this.filters.forEach(function(filter) {
    values[filter.name] = filter.payloadValue();
  });

  // angular.forEach(this.filters, function(filter) {
  //   values[filter.name] = filter.payloadValue();
  // });

  return values;
};

export default f;
