import moment from 'moment';
// Takes seconds and returns a readable string
export const filterDurationToMinutes = (seconds, showSecondsRemainder) => {
  const duration = moment.duration(seconds, 'seconds');
  // use asMinutes() to convert days or hours into minutes
  // and Math.floor() to cut off the remaining seconds
  const durationMinutes = Math.floor(duration.asMinutes());
  const durationSecondsRemainder = duration.seconds();

  return `${durationMinutes}m${showSecondsRemainder ? ` ${durationSecondsRemainder}s` : ''}`;
};
