import { getAccountNumber } from '@utils/auth';
import { apiRequest, generateDataOrParams, getApiEnvironment } from './apiConfig';

const baseURL = `https://${getApiEnvironment()}.marketing360.com/api/notifications/`;

export const apiNotifications = ({ url, method, payload, cache = false }) => {
  const baseRequest = apiRequest(baseURL);
  const dataOrParams = generateDataOrParams(method);
  return baseRequest({
    url: `${getAccountNumber()}/inapp/${url}`,
    method,
    [dataOrParams]: payload,
    cache
  });
};
