var f = function FormFieldGroup_InputList(data) {
  this.update(data);
};

f.prototype.update = function(data) {
  this.parentUpdate(data);
};

f.prototype.payloadValue = function() {
  return this.options.map(function(option) {
    return _.defaults(option, { value: '' });
  });
};

export default f;
