export const globalNamespace = 'M360FV3';

export const setGlobalVariable = (key, value) => {
  if (typeof window !== 'undefined') {
    if (!window[globalNamespace]) {
      window[globalNamespace] = {};
    }
    window[globalNamespace][key] = value;
  }
};

export const getGlobalVariable = key => {
  if (typeof window !== 'undefined') {
    if (!window[globalNamespace]) {
      return null;
    }
    return window[globalNamespace][key];
  }
};
