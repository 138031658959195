import { Image } from '@madwire/m360ui';
import { cdnUrl } from '@utils/global';
import PropTypes from 'prop-types';

const IconCdn = ({ iconCode, width, mx }) => (
  <Image width={width} src={`${cdnUrl}/product-${iconCode}.svg`} alt="" mx={mx} />
);

IconCdn.propTypes = {
  iconCode: PropTypes.string,
  width: PropTypes.number,
  mx: PropTypes.object
};

export default IconCdn;
