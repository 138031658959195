import NotificationBannerContents from '@components/layout/notifications/NotificationBannerContents';
import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useRef, useState } from 'react';

/**
 * Ad block detection inspired by https://html-online.com/articles/detect-adblock-javascript/
 * If we detect any ad blocker, present a NotificationBannerContents with a customBanner object
 */

function AdBlockDetect() {
  const [adblock, setAdblock] = useState(false);
  const adblockRef = useRef();
  const { t } = useTranslation('notifications');

  // Detect the 'adblockRef' and get width
  useEffect(() => {
    if (adblockRef && adblockRef.current && adblockRef.current.offsetWidth === 0) {
      setAdblock(true);
    }
  }, [setAdblock]);

  return (
    <>
      <AnimatePresence>
        {adblock && (
          <NotificationBannerContents
            banner={{
              id: 'adblock',
              message: t('adblock-notification')
            }}
            onDismiss={() => {
              setAdblock(false);
            }}
          />
        )}
      </AnimatePresence>

      {/**
       * We need the 'adBanner' className because an ad blocker will detect that and block it.
       * If it becomes blocked, the width of the div will be 0. We detect this via useRef.
       */}
      <div
        ref={adblockRef}
        className="adBanner"
        style={{ height: 0, width: 1, background: 'none' }}
      />
    </>
  );
}

export default React.memo(AdBlockDetect);
