import FileItem from '../../models/crm/FileItem';

var f = function FormFieldGroup_File(data) {
  this.update(data);
};

f.prototype.update = function(data) {
  this.parentUpdate(data);
  this.defaultValues = data.defaultValues.map(FileItem.createNew);
  this.selectedFiles = Object.assign({}, this.defaultValues);
  // this.selectedFiles = angular.copy(this.defaultValues);
};

f.prototype.payloadValue = function() {
  return this.selectedFiles.map(function(file) {
    return file;
  });
};

f.prototype.setValue = function(value) {
  this.selectedFiles = value;
};

export default f;
