// filterCamelCase('costPerClick') will filter to 'Cost Per Click'
export const filterCamelCase = value =>
  value.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
    return str.toUpperCase();
  });

// filterWordsToCamelCase('Overall conversions') filters to 'overallConversion'
export const filterWordsToCamelCase = string =>
  string.toLowerCase().replace(/\s+(.)/g, function (match, group) {
    return group.toUpperCase();
  });
