import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

const easeInOutQuart = [0.77, 0, 0.175, 1];

const numbersMotion = {
  initial: { x: -270 },
  animate: { x: 0 },
  transition: { ease: easeInOutQuart, duration: 0.6, delay: 0.2 }
};

const marketingMotion = {
  initial: { x: 156, opacity: 0 },
  animate: { x: 0, opacity: 1 },
  transition: { ease: easeInOutQuart, duration: 0.6, delay: 0.2 }
};

const fuelyourbrandMotion = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { ease: 'linear', duration: 0.3, delay: 0.6 }
};

const SplashLogo = ({ id = 'splash-logo-svg' }) => (
  <svg
    id={id}
    className="splash-logo"
    viewBox="0 0 830.55 161.04"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    style={{ width: 0, height: 0 }}
    width="0"
    height="0"
  >
    <linearGradient
      id="a"
      gradientTransform="matrix(1 0 0 -1 0 202)"
      gradientUnits="userSpaceOnUse"
      x1="777.63"
      x2="777.63"
      y1="26.67"
      y2="249.29"
    >
      <stop offset="0" stopColor="#326db4" />
      <stop offset="1" stopColor="#14c2f3" />
    </linearGradient>
    <linearGradient id="b" x1="591.14" x2="591.14" xlinkHref="#a" y1="26.67" y2="249.29" />
    <linearGradient id="c" x1="696.43" x2="696.43" xlinkHref="#a" y1="26.67" y2="249.29" />
    <motion.g
      className="numbers-group"
      initial={numbersMotion.initial}
      animate={numbersMotion.animate}
      transition={numbersMotion.transition}
      // Force initial value for rendering before JS is ready
      style={{ transform: 'translateX(-270px)' }}
    >
      <path
        className="zero-bottom-segment"
        d="m836.53 85.18-6.86 14.14-13.8-10.42c0 .94.14 1.88.14 2.84a50.75 50.75 0 0 1 -90.47 31.6 59.17 59.17 0 0 1 -3 12.82c-1 2.69-2.5 6.39-4.08 9.69a71.54 71.54 0 0 0 118.38-54.11 64.63 64.63 0 0 0 -.31-6.56z"
        fill="url(#a)"
        transform="translate(-9.71 -19.72)"
      />
      <path
        className="three-bottom-segment"
        d="m623.21 131.11a28.67 28.67 0 0 1 -4.25 5.46 30.93 30.93 0 0 1 -9.49 6.24 29.08 29.08 0 0 1 -11.55 2.34 29.87 29.87 0 0 1 -16.25-4.7 30.37 30.37 0 0 1 -7.15-6.66 28.84 28.84 0 0 1 -4.6-8.61 15 15 0 0 1 -.58-4.31h-19a48.38 48.38 0 0 0 22.51 35.55 48.08 48.08 0 0 0 43.74 3.41 47.78 47.78 0 0 0 15.17-10.18l.16-.18a29.77 29.77 0 0 1 -2.92-3.81 49.2 49.2 0 0 1 -5.79-14.55z"
        fill="url(#b)"
        transform="translate(-9.71 -19.72)"
      />
      <path
        className="m360-numbers-upper-segment"
        d="m718.06 110.38a41.2 41.2 0 0 0 -1.77-6.33c-.24-.66-.48-1.31-.75-2a50.75 50.75 0 0 1 99-22.47h.17v.08l13.15 9.22 6.76-14.5h.1a71.56 71.56 0 0 0 -139.42 2.15c-.19.88-.33 1.77-.49 2.66 0 .24-.07.48-.11.72-.17 1-.35 2.08-.48 3.14-.23 2.08-.4 4.18-.46 6.31v.33c0 .69-.1 1.37-.1 2.06a71.39 71.39 0 0 0 1 11.3c.07.46.09.93.16 1.4.12.75.2 1.37.28 2 .06.13.13.24.19.37a72.51 72.51 0 0 0 2.36 8.31 29.42 29.42 0 0 1 .32 3.57 27.3 27.3 0 0 1 -2 10.47 26.67 26.67 0 0 1 -5.28 8.32 23.85 23.85 0 0 1 -7.69 5.51 23.2 23.2 0 0 1 -9.49 2 23.71 23.71 0 0 1 -9.59-2 24.88 24.88 0 0 1 -7.93-5.48 25.74 25.74 0 0 1 -5.39-8.32 28.94 28.94 0 0 1 0-20.94 25.77 25.77 0 0 1 13.32-13.9 22.82 22.82 0 0 1 9.59-2 22.26 22.26 0 0 1 9.49 2 25.14 25.14 0 0 1 5.89 4 54.62 54.62 0 0 1 -.39-12.62 60 60 0 0 1 1.43-8.74 45.71 45.71 0 0 0 -16.85-3.13h-1.57l36-54h-22.7l-26.23 39.29-20.58 30.6a46.93 46.93 0 0 0 -13.66-13.91 51.22 51.22 0 0 0 -11.34-5.48l33.77-50.5h-88.58v17h56.88l-34.91 52.3c4.43-2.09 13.33-3.13 17.77-3.13a29 29 0 0 1 11.55 2.35 29.44 29.44 0 0 1 15.85 15.8 28.76 28.76 0 0 1 2.35 11.51c0 .56 0 1.11-.08 1.66l.16.11c0 .47-.1.92-.1 1.39a42.27 42.27 0 0 0 3.52 17.22 45 45 0 0 0 4.92 8.51c.48.66 1 1.31 1.48 1.94a45.16 45.16 0 0 0 3.29 3.65 45.9 45.9 0 0 0 14.49 9.59 44.9 44.9 0 0 0 17.71 3.52 45.45 45.45 0 0 0 17.93-3.52 44.9 44.9 0 0 0 19.42-15.38 44.68 44.68 0 0 0 2.52-3.85 48 48 0 0 0 2.32-4.45 41.44 41.44 0 0 0 3.62-17.22 44.46 44.46 0 0 0 -.82-8.49z"
        fill="url(#c)"
        transform="translate(-9.71 -19.72)"
      />

      <path
        className="m360-copyright-symbol"
        d="m817.1 31.31a11.47 11.47 0 0 1 1.58-5.85 11.42 11.42 0 0 1 1.81-2.35 11.78 11.78 0 0 1 2.35-1.81 12.06 12.06 0 0 1 2.76-1.17 11.74 11.74 0 0 1 6.15 0 12 12 0 0 1 2.77 1.17 11.78 11.78 0 0 1 2.35 1.81 11.42 11.42 0 0 1 1.81 2.35 12 12 0 0 1 1.17 2.77 11.27 11.27 0 0 1 .41 3.08 11.6 11.6 0 0 1 -.41 3.07 11.29 11.29 0 0 1 -1.17 2.76 11.63 11.63 0 0 1 -1.81 2.34 11.78 11.78 0 0 1 -2.35 1.81 12 12 0 0 1 -2.77 1.17 11.67 11.67 0 0 1 -3.08.41 11.49 11.49 0 0 1 -3.07-.41 11.71 11.71 0 0 1 -5.11-3 11.46 11.46 0 0 1 -3.39-8.17zm2.12 0a10.11 10.11 0 0 0 .73 3.82 9.66 9.66 0 0 0 2 3.08 9.54 9.54 0 0 0 3 2.06 9.23 9.23 0 0 0 3.72.75 9.36 9.36 0 0 0 3.74-.75 9.52 9.52 0 0 0 3-2.06 9.74 9.74 0 0 0 2-3.08 9.89 9.89 0 0 0 .74-3.82 10.28 10.28 0 0 0 -.34-2.62 9.89 9.89 0 0 0 -1-2.35 9.56 9.56 0 0 0 -1.48-2 9.66 9.66 0 0 0 -1.92-1.52 9.2 9.2 0 0 0 -4.83-1.32 9.69 9.69 0 0 0 -2.55.34 9.31 9.31 0 0 0 -4.18 2.5 9.6 9.6 0 0 0 -1.47 2 9.87 9.87 0 0 0 -.94 2.35 9.75 9.75 0 0 0 -.22 2.62zm7.71 1.9v5.1h-3.36v-14.09h5a7.06 7.06 0 0 1 4.3 1.05 3.58 3.58 0 0 1 1.36 3 4.2 4.2 0 0 1 -.72 2.44 4.15 4.15 0 0 1 -2.25 1.53 2.14 2.14 0 0 1 .6.51 7.29 7.29 0 0 1 .47.71l2.84 4.82h-3.17a1 1 0 0 1 -1-.52l-2.26-4.13a1.25 1.25 0 0 0 -.35-.34 1.18 1.18 0 0 0 -.59-.12h-.86zm0-2.41h1.4a5.39 5.39 0 0 0 1.32-.14 1.92 1.92 0 0 0 .81-.4 1.33 1.33 0 0 0 .4-.66 3.44 3.44 0 0 0 .11-.89 3.66 3.66 0 0 0 -.09-.86 1.28 1.28 0 0 0 -.35-.61 1.62 1.62 0 0 0 -.72-.36 4.85 4.85 0 0 0 -1.19-.12h-1.68v4z"
        fill="#326db4"
        transform="translate(-9.71 -19.72)"
      />
    </motion.g>
    <motion.g
      className="marketing-group"
      fill="#333"
      initial={marketingMotion.initial}
      animate={marketingMotion.animate}
      transition={marketingMotion.transition}
    >
      <path
        className="marketing-letter-m"
        d="m48.94 87.18q0-10.79-2.7-15.6t-10.07-4.8a45.61 45.61 0 0 0 -5.79.4q-3.16.39-4.74.66v57h-15.93v-68.06a105.7 105.7 0 0 1 12.05-2.5 100 100 0 0 1 15.73-1.19 31.28 31.28 0 0 1 11.65 1.85 22.5 22.5 0 0 1 7.57 4.87 31.16 31.16 0 0 1 3.69-2.24 38.29 38.29 0 0 1 5-2.17 45.56 45.56 0 0 1 5.86-1.65 29.89 29.89 0 0 1 6.25-.66 33 33 0 0 1 13.23 2.3 19.45 19.45 0 0 1 8.16 6.45 25.17 25.17 0 0 1 4.08 10 68.29 68.29 0 0 1 1.12 12.84v40.15h-15.93v-37.65q0-10.79-2.63-15.6t-10.13-4.8a21 21 0 0 0 -7.24 1.22 23.59 23.59 0 0 0 -5.17 2.47 35.67 35.67 0 0 1 1.45 7 75.28 75.28 0 0 1 .39 7.9v39.49h-15.9z"
        transform="translate(-9.71 -19.72)"
      />
      <path
        className="marketing-letter-a"
        d="m137.14 52.83a38.82 38.82 0 0 1 13.29 2 21.24 21.24 0 0 1 8.62 5.53 20.69 20.69 0 0 1 4.61 8.64 42.28 42.28 0 0 1 1.34 11.08v41.73q-3.69.8-11.12 1.91a114.38 114.38 0 0 1 -16.78 1.12 50.91 50.91 0 0 1 -11.32-1.18 23.7 23.7 0 0 1 -8.75-3.82 17.91 17.91 0 0 1 -5.66-6.85 23.74 23.74 0 0 1 -2-10.4 20.19 20.19 0 0 1 2.3-10 19 19 0 0 1 6.25-6.65 26.92 26.92 0 0 1 9.15-3.68 51.94 51.94 0 0 1 10.86-1.12 49 49 0 0 1 5.53.33 51.3 51.3 0 0 1 6.19 1.12v-2.59a20.55 20.55 0 0 0 -.66-5.26 11 11 0 0 0 -2.3-4.41 10.85 10.85 0 0 0 -4.34-3 18.86 18.86 0 0 0 -6.78-1 59.81 59.81 0 0 0 -10.14.79 31.27 31.27 0 0 0 -4.37.9l-7.59-11.32a104.66 104.66 0 0 1 11.3-2.75 68.43 68.43 0 0 1 12.37-1.12zm1.32 59.36a59.05 59.05 0 0 0 11.19-.79v-17.63a31 31 0 0 0 -3.82-.79 37 37 0 0 0 -10.83.02 16.58 16.58 0 0 0 -4.87 1.45 8.84 8.84 0 0 0 -3.49 3 8.28 8.28 0 0 0 -1.32 4.8c0 3.78 1.19 6.38 3.55 7.83s5.55 2.11 9.59 2.11z"
        transform="translate(-9.71 -19.72)"
      />
      <path
        className="marketing-letter-r"
        d="m207.46 67.76c-1.32-.44 1.12.23-1.2-.26a40.29 40.29 0 0 0 -8.1-.73 32 32 0 0 0 -5.59.53 34.45 34.45 0 0 0 -4.15.92v56.6h-15.92v-67a87.73 87.73 0 0 1 11.5-3.22 71.77 71.77 0 0 1 15.34-1.52c1 0 2.28.07 3.69.2s2.81.31 4.21.52 2.77.49 4.08.79 3.35 1 4.14 1.22z"
        transform="translate(-9.71 -19.72)"
      />
      <path
        className="marketing-letter-k"
        d="m234.74 81.39c2-2.11 4.17-4.36 6.45-6.78s4.52-4.83 6.71-7.24 4.28-4.74 6.26-7 4.79-5.64 6.2-7.31h19c-4.36 4.94-10.31 11.47-15.16 16.74s-9.74 10.4-14.64 15.4a93.53 93.53 0 0 1 8.28 8q4.34 4.66 8.4 9.93t7.62 10.53c2.36 3.51 5.28 8.47 6.86 11.45h-18.42c-1.58-2.72-4.37-7.28-6.43-10.26s-4.28-5.88-6.65-8.69-4.78-5.46-7.24-8a61.66 61.66 0 0 0 -7.24-6.38v33.3h-15.93v-105h15.93z"
        transform="translate(-9.71 -19.72)"
      />
      <path
        className="marketing-letter-e"
        d="m276.89 89.29a43.26 43.26 0 0 1 2.7-15.93 33.45 33.45 0 0 1 7.18-11.36 29.56 29.56 0 0 1 10.23-6.87 31.9 31.9 0 0 1 11.85-2.3q14.22 0 22.18 8.82t7.97 26.35c0 .88 0 1.86-.07 3s-.11 2.08-.2 3h-45.39q.66 8.3 5.86 12.84t15.07 4.54a49.62 49.62 0 0 0 10.59-1 22.34 22.34 0 0 0 5.56-1.78l7.22 11.4c-.88.44-5.19 2-6.73 2.45a53 53 0 0 1 -5.26 1.32c-2 .39-4.1.72-6.38 1a61.61 61.61 0 0 1 -7 .39 42.25 42.25 0 0 1 -15.8-2.7 29.36 29.36 0 0 1 -11.06-7.51 30.64 30.64 0 0 1 -6.41-11.38 46.92 46.92 0 0 1 -2.11-14.28zm46.21-7.11a21 21 0 0 0 -.92-6.25 14.78 14.78 0 0 0 -2.7-5.13 12.64 12.64 0 0 0 -4.34-3.42 13.75 13.75 0 0 0 -6.14-1.25 14.28 14.28 0 0 0 -6.45 1.38 14.7 14.7 0 0 0 -4.67 3.62 16.52 16.52 0 0 0 -3 5.13 28.44 28.44 0 0 0 -1.45 5.92z"
        transform="translate(-9.71 -19.72)"
      />
      <g className="letter-t-group">
        <path
          className="marketing-letter-t"
          d="m346.47 33.74h15.93v19.47h24.48v13.29h-24.48v29.37q0 8.3 2.63 11.85t9 3.55a29.4 29.4 0 0 0 7.7-.92 51.89 51.89 0 0 0 5.33-1.71l2.63 12.64a60.79 60.79 0 0 1 -7.24 2.44 39.59 39.59 0 0 1 -10.58 1.28 33.23 33.23 0 0 1 -12.31-2 18.19 18.19 0 0 1 -7.83-5.73 22.69 22.69 0 0 1 -4.08-9.09 56.37 56.37 0 0 1 -1.18-12.18z"
          transform="translate(-9.71 -19.72)"
        />
        <path
          className="marketing-letter-t-arrow"
          d="m393.2 90.79-6.96 9.68-6.97 9.68-4.31-13.54-4.32-13.55 11.28 3.87z"
        />
      </g>
      <g className="letter-i-group">
        <path
          className="marketing-letter-i-dot"
          d="m404.78 32.94a6.34 6.34 0 0 1 -1.84 4.8 6.24 6.24 0 0 1 -4.48 1.78 6.16 6.16 0 0 1 -4.46-1.78 6.37 6.37 0 0 1 -1.85-4.8 6.32 6.32 0 0 1 1.85-4.8 6.52 6.52 0 0 1 8.95 0 6.34 6.34 0 0 1 1.83 4.8z"
          transform="translate(-9.71 -19.72)"
        />
        <path
          className="marketing-letter-i-bottom-segment"
          d="m402.81 105.13-8.56-3v-49h8.56z"
          transform="translate(-9.71 -19.72)"
        />
      </g>
      <path
        className="marketing-letter-n"
        d="m410.41 56.9q3.81-1 10.27-2.37a80.33 80.33 0 0 1 15.8-1.32 32.89 32.89 0 0 1 13 2.24 19.88 19.88 0 0 1 8.29 6.38 26.17 26.17 0 0 1 4.41 9.94 57.52 57.52 0 0 1 1.32 12.77v40.46h-8.6v-37.69a64.34 64.34 0 0 0 -1-12.24 20.51 20.51 0 0 0 -3.29-8.16 12.72 12.72 0 0 0 -6.05-4.54 27.54 27.54 0 0 0 -9.4-1.37 71.6 71.6 0 0 0 -10.46.66 33.54 33.54 0 0 0 -5.7 1.17v62.17h-8.56z"
        transform="translate(-9.71 -19.72)"
      />
      <path
        className="marketing-letter-g"
        d="m519.35 114.69a15.25 15.25 0 0 1 -2.35 1.51 39 39 0 0 1 -3.9 1.8 29.85 29.85 0 0 1 -5.27 1.51 33.59 33.59 0 0 1 -6.65.6 35.67 35.67 0 0 1 -11.45-1.85 25.61 25.61 0 0 1 -9.67-5.79 28.32 28.32 0 0 1 -6.65-10.2 41.18 41.18 0 0 1 -2.5-15.27 39.31 39.31 0 0 1 2.24-13.43 31.81 31.81 0 0 1 6.38-10.73 29 29 0 0 1 10.13-7.11 34 34 0 0 1 13.49-2.57 74.19 74.19 0 0 1 15.2 1.25c3.82.83 6.95 1.64 9.41 2.43v62.16q0 15.27-7.9 21.79t-23.86 6.52a65.44 65.44 0 0 1 -11.91-1 35.71 35.71 0 0 1 -10.09-3.38l4.29-6.71a23.76 23.76 0 0 0 7 2.54 58.12 58.12 0 0 0 11 1.05q12.1 0 17.57-4.68t5.46-16v-4.45zm-.13-51.86a52 52 0 0 0 -5.79-1.25 65.16 65.16 0 0 0 -10.13-.58 23.88 23.88 0 0 0 -9.81 1.91 20.71 20.71 0 0 0 -7.31 5.4 23.81 23.81 0 0 0 -4.6 8.29 33.88 33.88 0 0 0 -1.58 10.59 32.14 32.14 0 0 0 1.84 11.52 22.71 22.71 0 0 0 4.8 7.83 17.92 17.92 0 0 0 6.78 4.41 22.64 22.64 0 0 0 7.76 1.38 31.47 31.47 0 0 0 10.6-1.71 24 24 0 0 0 7.44-4z"
        transform="translate(-9.71 -19.72)"
      />
    </motion.g>
    <motion.g
      className="fuelyourbrand-group"
      fill="#333"
      initial={fuelyourbrandMotion.initial}
      animate={fuelyourbrandMotion.animate}
      transition={fuelyourbrandMotion.transition}
    >
      <path
        className="fuel-letter-f"
        d="m9.72 146.91h19.15v3.55h-14.94v11.25h12.67v3.55h-12.67v14.9h-4.21z"
        transform="translate(-9.71 -19.72)"
      />
      <path
        className="fuel-letter-u"
        d="m37 155.52h4.21v15c0 4.61 1.37 6.59 4.61 6.59 2.53 0 4.31-1.27 6.64-4.16v-17.43h4.15v24.62h-3.47l-.35-3.85h-.15c-2.28 2.69-4.71 4.46-8.1 4.46-5.22 0-7.55-3.34-7.55-9.63v-15.6z"
        transform="translate(-9.71 -19.72)"
      />
      <path
        className="fuel-letter-e"
        d="m77.93 154.92c6.28 0 9.83 4.51 9.83 11.55a14.9 14.9 0 0 1 -.15 2.28h-16.61c.3 5.32 3.5 8.67 8.21 8.67a11.08 11.08 0 0 0 6.13-1.93l1.47 2.73a14.64 14.64 0 0 1 -8.1 2.53c-6.59 0-11.81-4.81-11.81-12.87s5.4-12.96 11.03-12.96zm6.17 11.08c0-5-2.23-7.81-6.08-7.81-3.44 0-6.58 2.84-7.09 7.81z"
        transform="translate(-9.71 -19.72)"
      />
      <path
        className="fuel-letter-l"
        d="m97.64 144.07h4.16v31.41c0 1.32.56 1.82 1.16 1.82a3.51 3.51 0 0 0 .91-.1l.56 3.14a5.62 5.62 0 0 1 -2.38.41c-3.14 0-4.41-2-4.41-5.57z"
        transform="translate(-9.71 -19.72)"
      />
      <path
        className="your-letter-y"
        d="m134.17 167.28-10-20.37h4.51l4.3 9.38c1.07 2.43 2.08 4.76 3.19 7.24h.2c1.16-2.48 2.33-4.81 3.35-7.24l4.26-9.38h4.41l-10 20.37v12.87h-4.26v-12.87z"
        transform="translate(-9.71 -19.72)"
      />
      <path
        className="your-letter-o"
        d="m163.82 154.92c6 0 11.4 4.71 11.4 13s-5.37 12.87-11.4 12.87-11.4-4.71-11.4-12.87 5.37-13 11.4-13zm0 22.39c4.26 0 7.09-3.8 7.09-9.42s-2.84-9.53-7.09-9.53-7.09 3.85-7.09 9.53 2.88 9.42 7.09 9.42z"
        transform="translate(-9.71 -19.72)"
      />
      <path
        className="your-letter-u"
        d="m185.15 155.52h4.21v15c0 4.61 1.37 6.59 4.61 6.59 2.53 0 4.31-1.27 6.64-4.16v-17.43h4.15v24.62h-3.44l-.36-3.85h-.15c-2.28 2.69-4.71 4.46-8.1 4.46-5.22 0-7.55-3.34-7.55-9.63v-15.6z"
        transform="translate(-9.71 -19.72)"
      />
      <path
        className="your-letter-r"
        d="m216.87 155.52h3.45l.35 4.46h.15c1.67-3.09 4.21-5.07 7-5.07a6.07 6.07 0 0 1 2.64.51l-.81 3.64a7 7 0 0 0 -2.38-.4c-2.08 0-4.56 1.52-6.23 5.67v15.81h-4.16z"
        transform="translate(-9.71 -19.72)"
      />
      <path
        className="brand-letter-b"
        d="m252.69 146.91h9.88c6.74 0 11.4 2.28 11.4 8.11a7.21 7.21 0 0 1 -5.78 7.24v.2c4.87.76 7.7 3.39 7.7 7.95 0 6.53-5.17 9.73-12.62 9.73h-10.59v-33.23zm9.12 14.19c5.67 0 8-2 8-5.73s-2.64-5.12-7.81-5.12h-5.12v10.84h4.92zm.87 15.7c5.73 0 9.07-2.07 9.07-6.58 0-4.11-3.24-6-9.07-6h-5.78v12.58z"
        transform="translate(-9.71 -19.72)"
      />
      <path
        className="brand-letter-r"
        d="m285.88 155.52h3.45l.35 4.46h.16c1.67-3.09 4.2-5.07 7-5.07a6.07 6.07 0 0 1 2.64.51l-.81 3.64a7 7 0 0 0 -2.38-.4c-2.08 0-4.56 1.52-6.23 5.67v15.81h-4.16v-24.62z"
        transform="translate(-9.71 -19.72)"
      />
      <path
        className="brand-letter-a"
        d="m319.93 164.54c0-3.14-1.06-6.18-5.07-6.18a13.25 13.25 0 0 0 -7.35 2.64l-1.67-2.89a18.29 18.29 0 0 1 9.68-3.19c6 0 8.56 4 8.56 10.13v15.1h-3.45l-.35-2.94h-.1c-2.38 1.93-5.12 3.55-8.16 3.55-4.15 0-7.24-2.58-7.24-7 .01-5.37 4.67-8.05 15.15-9.22zm-6.73 12.87c2.38 0 4.36-1.17 6.74-3.3v-6.84c-8.26 1-11 3-11 6.18-.05 2.79 1.82 3.96 4.26 3.96z"
        transform="translate(-9.71 -19.72)"
      />
      <path
        className="brand-letter-n"
        d="m335.75 155.52h3.45l.35 3.55h.16c2.33-2.33 4.86-4.15 8.26-4.15 5.17 0 7.5 3.35 7.5 9.63v15.61h-4.15v-15.06c0-4.61-1.42-6.58-4.66-6.58-2.53 0-4.26 1.32-6.74 3.8v17.83h-4.16v-24.63z"
        transform="translate(-9.71 -19.72)"
      />
      <path
        className="brand-letter-d"
        d="m376.18 154.92c2.84 0 4.71 1.07 6.89 2.84l-.2-4.21v-9.47h4.21v36.07h-3.44l-.36-2.89h-.15a11.13 11.13 0 0 1 -7.45 3.49c-6.18 0-10.18-4.66-10.18-12.87-.01-8 5.06-12.96 10.68-12.96zm.41 22.34c2.28 0 4.26-1.12 6.28-3.4v-12.86a8.59 8.59 0 0 0 -6-2.58c-3.95 0-7.1 3.8-7.1 9.42.02 5.87 2.51 9.42 6.82 9.42z"
        transform="translate(-9.71 -19.72)"
      />

      <path
        className="fuelyourbrand-copyright-symbol"
        d="m395.87 156.27c0-5.4 3.83-9 8.4-9s8.4 3.6 8.4 9-3.78 8.91-8.4 8.91-8.4-3.55-8.4-8.91zm15.37 0c0-4.44-3.09-7.71-7-7.71s-7 3.28-7 7.71 3.1 7.57 7 7.57 7-3.18 7-7.57zm-10.11-4.94h3.19c1.8 0 3.56.65 3.56 3a2.76 2.76 0 0 1 -1.85 2.59l2.26 4h-1.71l-1.8-3.51h-2.17v3.51h-1.48zm2.82 4.76c1.39 0 2.36-.51 2.36-1.71s-.55-1.75-2.22-1.75h-1.48v3.46z"
        transform="translate(-9.71 -19.72)"
      />
    </motion.g>
  </svg>
);

SplashLogo.propTypes = {
  id: PropTypes.string
};

export default SplashLogo;
