var f = function FormFieldGroup_Radios(data) {
  this.update(data);
};

f.prototype.update = function(data) {
  this.parentUpdate(data);
};

f.prototype.payloadValue = function() {
  return this.selected;
};

export default f;
