import { GlobalStateContext } from '@context/GlobalContextProvider';
import throttle from 'lodash/throttle';
import { useContext, useEffect, useState } from 'react';

/**
 * Watch window scroll events and hide elements if user is scrolling
 * down on their mobile device.
 */

let scrollPos = 0;

const useMobileHideOnScroll = () => {
  const { isMobile } = useContext(GlobalStateContext);
  const [hide, setHide] = useState(false);

  const checkScrollPosition = throttle(() => {
    const windowY = window.scrollY;
    if (windowY < scrollPos || windowY < 100) {
      // Scrolling UP
      setHide(false);
    } else {
      // Scrolling DOWN
      setHide(true);
    }
    scrollPos = windowY;
  }, 100);

  useEffect(() => {
    if (isMobile) {
      window.addEventListener('scroll', checkScrollPosition);
    }

    return () => {
      if (isMobile) {
        window.removeEventListener('scroll', checkScrollPosition);
      }
    };
  }, [checkScrollPosition, isMobile]);

  return { hide };
};

export default useMobileHideOnScroll;
