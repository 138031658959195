import {
  NotificationsDispatchContext,
  NotificationsStateContext
} from '@context/NotificationsContextProvider';
import { useTranslation } from 'next-i18next';
import { useContext } from 'react';
import NotificationBanner from './NotificationBanner';
import NotificationDrawer from './NotificationDrawer';
import NotificationModals from './NotificationModals';
import NotificationToasts from './NotificationToasts';

const Notifications = () => {
  const dispatch = useContext(NotificationsDispatchContext);
  const { modals, toastsQueue } = useContext(NotificationsStateContext);
  const { t } = useTranslation('notifications');

  return (
    <>
      <NotificationToasts toastsQueue={toastsQueue} dispatch={dispatch} t={t} />
      <NotificationModals modals={modals} dispatch={dispatch} t={t} />
      <NotificationBanner />
      <NotificationDrawer />
    </>
  );
};

export default Notifications;
