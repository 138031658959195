import moment from 'moment';

/**
 * The default arguments expects to take in a 24 Hour time such as
 * '23:30:00' and will parse it into '11:30pm'.
 * @param {string} time
 * @param {string} format
 * @param {string} parseFormat
 */

export const filter24HourTime = (time, format = 'h:mma', parseFormat = 'HH:mm:ss') => {
  if (!time) {
    return '';
  }
  // Uses moment parse to parse the time and then format it.
  // The 'parseFormat' must match with the 'time' variable.
  // https://momentjs.com/docs/#/parsing/string-format/
  return moment(time, [parseFormat]).format(format);
};
